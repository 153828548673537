import React from "react";

const useDragMenu = (setFormValues) => {
  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("text/plain", index);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetIndex, formValues) => {
    e.preventDefault();
    const sourceIndex = e.dataTransfer.getData("text/plain");

    if (sourceIndex === targetIndex) {
      return;
    }

    const newItems = [...formValues?.submenu];

    if (newItems) {
      const [removedItem] = newItems.splice(sourceIndex, 1);
      newItems.splice(targetIndex, 0, removedItem);

      newItems.forEach((item, i) => {
        item.order = i + 1;
      });

      formValues.submenu = newItems;
      setFormValues({ ...formValues });
    }
  };

  const sortArray = (data) => {
    data.submenu.sort((a, b) => {
      if (a.order === undefined && b.order === undefined) {
        return 0;
      } else if (a.order === undefined) {
        return 1;
      } else if (b.order === undefined) {
        return -1;
      } else if (a.order === null && b.order === null) {
        return 0;
      } else if (a.order === null) {
        return 1;
      } else if (b.order === null) {
        return -1;
      } else {
        return a.order - b.order;
      }
    });
  };

  return { handleDragStart, handleDragOver, handleDrop, sortArray };
};

export default useDragMenu;
