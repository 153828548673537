/* eslint-disable react-hooks/exhaustive-deps */
import "./index.scss";

import { Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import React from "react";

import { Fetch, catchError } from "../../utils/fetch";
import { loadingAction } from "../../redux/actions";

function Dashboard() {
  const [stats, setStats] = React.useState({
    paginas: 0,
    novedades: 0,
    novedadesPrestadores: 0,
    planes: 0,
  });
  const dispatch = useDispatch();
  const isLoadingAction = (data) => dispatch(loadingAction(data));

  const getStats = async () => {
    isLoadingAction(true);
    try {
      const { data: paginas } = await Fetch("menus/count/porEstado", "get");
      const { data: novedades } = await Fetch(
        "novedades/count/porEstado",
        "get"
      );
      const { data: novedadesPrestadores } = await Fetch(
        "prestadores/count/porEstado",
        "get"
      );
      const { data: planesCount } = await Fetch(
        "planes/count/porEstado",
        "get"
      );
      const { data: promocionesCount } = await Fetch(
        "promociones/count/porEstado",
        "get"
      );
      Promise.all([
        paginas,
        novedades,
        novedadesPrestadores,
        planesCount,
        promocionesCount,
      ]).then((response) => {
        setStats({
          paginas: response[0],
          novedades: response[1],
          novedadesPrestadores: response[2],
          planes: response[3],
          promociones: response[4],
        });
      });

      isLoadingAction(false);
    } catch (error) {
      console.error("mostrando error", error);
      isLoadingAction(false);
      catchError(error);
    }
  };

  React.useEffect(() => {
    getStats();
  }, []);
  return (
    <React.Fragment>
      <Row>
        {/* Páginas */}
        <Col xs={12} md={4}>
          <div className="widget bg-light padding-0">
            <Row className="row-table">
              <div className="col-xs-4 text-center padding-15 bg-primary seccion-1">
                {/* <em className="fad fa-dot-circle fa-2x" /> */}
                PÁGINAS
              </div>
              <div className="col-xs-6 padding-15">
                <div className="card-item">
                  <div className="margin-b-0 text-muted">Aprobados</div>
                  <div className="margin-b-0">{stats.paginas.aprobados}</div>
                </div>
                <div className="card-item">
                  <div className="margin-b-0 text-muted">Por aprobar</div>
                  <div className="margin-b-0">{stats.paginas.poraprobar}</div>
                </div>
                <div className="card-item">
                  <div className="margin-b-0 text-muted">Por revisar</div>
                  <div className="margin-b-0">{stats.paginas.porrevisar}</div>
                </div>
                <div className="card-item">
                  <div className="margin-b-0 text-muted">Por eliminar</div>
                  <div className="margin-b-0">{stats.paginas.poreliminar}</div>
                </div>
              </div>
            </Row>
          </div>
        </Col>
        {/* Fin páginas */}
        {/* Novedades */}
        <Col xs={12} md={4}>
          <div className="widget bg-light padding-0">
            <Row className="row-table">
              <div className="col-xs-4 text-center padding-15 bg-teal seccion-1">
                NOVEDADES
              </div>
              <div className="col-xs-6 padding-15">
                <div className="card-item">
                  <div className="margin-b-0 text-muted">Aprobados</div>
                  <div className="margin-b-0">{stats.novedades.aprobados}</div>
                </div>
                <div className="card-item">
                  <div className="margin-b-0 text-muted">Por aprobar</div>
                  <div className="margin-b-0">{stats.novedades.poraprobar}</div>
                </div>
                <div className="card-item">
                  <div className="margin-b-0 text-muted">Por revisar</div>
                  <div className="margin-b-0">{stats.novedades.porrevisar}</div>
                </div>
                <div className="card-item">
                  <div className="margin-b-0 text-muted">Por eliminar</div>
                  <div className="margin-b-0">
                    {stats.novedades.poreliminar}
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </Col>
        {/* Fin novedades */}
        {/* Novedades Prestadores*/}
        <Col xs={12} md={4}>
          <div className="widget bg-light padding-0">
            <Row className="row-table">
              <div className="col-xs-4 text-center padding-15 bg-teal seccion-1">
                {/* <em className="fad fa-dot-circle fa-2x" /> */}
                NOVEDADES PRESTADORES
              </div>
              <div className="col-xs-6 padding-15">
                <div className="card-item">
                  <div className="margin-b-0 text-muted">Aprobados</div>
                  <div className="margin-b-0">
                    {stats.novedadesPrestadores.aprobados}
                  </div>
                </div>
                <div className="card-item">
                  <div className="margin-b-0 text-muted">Por aprobar</div>
                  <div className="margin-b-0">
                    {stats.novedadesPrestadores.poraprobar}
                  </div>
                </div>
                <div className="card-item">
                  <div className="margin-b-0 text-muted">Por revisar</div>
                  <div className="margin-b-0">
                    {stats.novedadesPrestadores.porrevisar}
                  </div>
                </div>
                <div className="card-item">
                  <div className="margin-b-0 text-muted">Por eliminar</div>
                  <div className="margin-b-0">
                    {stats.novedadesPrestadores.poreliminar}
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </Col>
        {/* Fin novedades PRESTADORES*/}
        {/* Planes */}
        <Col xs={12} md={4}>
          <div className="widget bg-light padding-0">
            <Row className="row-table">
              <div className="col-xs-4 text-center padding-15 bg-success seccion-1">
                PLANES
              </div>
              <div className="col-xs-6 padding-15">
                <div className="card-item">
                  <div className="margin-b-0 text-muted">Aprobados</div>
                  <div className="margin-b-0">{stats.planes.aprobados}</div>
                </div>
                <div className="card-item">
                  <div className="margin-b-0 text-muted">Por aprobar</div>
                  <div className="margin-b-0">{stats.planes.poraprobar}</div>
                </div>
                <div className="card-item">
                  <div className="margin-b-0 text-muted">Por revisar</div>
                  <div className="margin-b-0">{stats.planes.porrevisar}</div>
                </div>
                <div className="card-item">
                  <div className="margin-b-0 text-muted">Por eliminar</div>
                  <div className="margin-b-0">{stats.planes.poreliminar}</div>
                </div>
              </div>
            </Row>
          </div>
        </Col>
        {/* Fin planes */}

        {/* Promociones */}
        <Col xs={12} md={4}>
          <div className="widget padding-0 bg-light">
            <Row className="row-table">
              <div className="col-xs-4 text-center padding-15 bg-custom-blue seccion-1">
                PROMOCIONES
              </div>
              <div className="col-xs-6 padding-15">
                <div className="card-item">
                  <div className="margin-b-0 text-muted">Aprobados</div>
                  <div className="margin-b-0">
                    {stats?.promociones?.aprobados}
                  </div>
                </div>
                <div className="card-item">
                  <div className="margin-b-0 text-muted">Por aprobar</div>
                  <div className="margin-b-0">
                    {stats?.promociones?.poraprobar}
                  </div>
                </div>
                <div className="card-item">
                  <div className="margin-b-0 text-muted">Por revisar</div>
                  <div className="margin-b-0">
                    {stats?.promociones?.porrevisar}
                  </div>
                </div>
                <div className="card-item">
                  <div className="margin-b-0 text-muted">Por eliminar</div>
                  <div className="margin-b-0">
                    {stats?.promociones?.poreliminar}
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </Col>
        {/* Fin promociones */}
        <Col></Col>
        <Col></Col>
      </Row>
    </React.Fragment>
  );
}

export default Dashboard;
