import * as Yup from 'yup';

export const validate = Yup.object().shape({
    nombre: Yup.string().required('campo obligatorio'),
    descripcion: Yup.string().required('campo obligatorio'),
    miniatura: Yup.object({}).required('campo obligatorio').nullable(),
    fondo: Yup.object({}).required('campo obligatorio').nullable(),
    titulo: Yup.string().required('campo obligatorio'),
    informacion: Yup.string().required('campo obligatorio'),
    color: Yup.string().required('campo obligatorio'),
    botones: Yup.array().of(
      Yup.object().shape({
        nombre: Yup.string().required('campo obligatorio'),
        url: Yup.string().required('campo obligatorio'),
        estilo: Yup.string().required('campo obligatorio'),
      })
    )
});