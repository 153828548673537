import { useFormik } from 'formik';
import React from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { AlertInfo, AlertSuccess } from '../../../../components/alert';
import ModalComponent from '../../../../components/modal';
import ModalDegradadosComponent from '../../../../components/modal-degradados';
import { loadingAction } from '../../../../redux/actions';
import { EstadoContenido } from '../../../../utils/constants';
import { catchError, Fetch } from '../../../../utils/fetch';
import { validate } from '../validationSchema';

const VerBorradorHomeBotones = () => {

    const { id } = useParams();
    const dispatch = useDispatch()
    const isLoading = useSelector(state => state.loading)
    const config = {
        readonly: false, // all options from https://xdsoft.net/jodit/doc/
        language: 'es',disablePlugins:['table'],
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        defaultActionOnPaste: "insert_clear_html",
        enableDragAndDropFileToEditor: true,
        uploader: {
            url: process.env.REACT_APP_BASE_URL + '/upload',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt_token')
            },
            data: null,
            filesVariableName: function (i) {
                return "files"
            },
            withCredentials: false,
            pathVariableName: "path",
            format: "json",
            method: "POST",
            prepareData: function (formData) {
                formData.append('id', 1);

            },
            isSuccess: function (resp) {
                return !resp.error;
            },
            getMessage: function (resp) {
                return resp.msgs.join('\n');
            },
            process: function (resp) {
                return resp;
            },
            defaultHandlerSuccess: function (resp) {
                for (var i = 0; i < resp.length; i++) {
                    var img1 = new Image();
                    img1.src = resp[i]['url'];
                    img1.alt = 'imagen';
                    img1.className = "img-fluid"
                    this.s.insertImage(img1);
                    //this.s.insertImage(resp[i]['url'], {width: 'auto', height: 'auto'});
                }
            }
        }
    }
    const [showModal, setShowModal] = React.useState(false)
    const [showModalColores, setShowModalColores] = React.useState(false)
    const permissions = useSelector(state => state.permissions)
    //const canPublish = permissions['botones-home'].publicar.enabled;

    const history = useHistory();
    React.useEffect(() => {
        if (id) {
            getHomeBotones()
        }
    }, [id])

    const getHomeBotones = async (values) => {
        dispatch(loadingAction(true));

        try {
            const { data } = await Fetch("botones-homes/" + id + "?_publicationState=preview", "get");
            setValues(data)
            dispatch(loadingAction(false));
        } catch (error) {
            catchError(error)
            dispatch(loadingAction(false));
        }
    }

    const submit = async (values) => {
        dispatch(loadingAction(true));

        values.published_at = null;
        values.estado = EstadoContenido.EnRevision

        try {

            await Fetch("botones-homes/" + id, "put", values);

            dispatch(loadingAction(false));

            AlertInfo('Sección actualizado con éxito.<br/>Recuerde que falta su confirmación para aprobar.<br/> Nota: Por favor, notificar al responsable de publicar')
            history.push("/configuracion/botones-home/lista-borradores");


        } catch (error) {
            catchError(error)
            dispatch(loadingAction(false));
        }
    }

    const onEnviarAprobar = async () => {
        dispatch(loadingAction(true));

        values.estado = EstadoContenido.EnAprobacion

        try {

            await Fetch("botones-homes/" + id, "put", values);

            dispatch(loadingAction(false));

            AlertSuccess('Contenido enviado con éxito')
            history.push("/configuracion/botones-home/listax");


        } catch (error) {
            dispatch(loadingAction(false));
            catchError(error)
        }
    };

    const { values, errors, handleSubmit, handleChange, handleBlur, setFieldValue, setValues } = useFormik({
        initialValues: {
            botones: {
                nombre: "",
                url: "",
                estilo: "color1"
            },
            locale: 'es'
        },
        enableReinitialize: true,
        validationSchema: validate,
        onSubmit: (values) => submit(values)
    })

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <ModalDegradadosComponent isShow={showModal} setShowModal={setShowModal} />
                        <ModalComponent isShow={showModalColores} setShowModal={setShowModalColores} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <Card.Header>
                                <Row>
                                    <Col>
                                    Información de botones
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <label>Botón</label>
                                            <input type="text" name="botones.nombre" onChange={handleChange} onBlur={handleBlur} className="form-control" value={values.botones.nombre} />
                                            {errors &&
                                                errors.botones &&
                                                errors.botones.nombre &&
                                                (<div className="text-danger">{errors.botones.nombre}</div>)}

                                            <label>Url</label>
                                            <input type="text" name="botones.url" onChange={handleChange} onBlur={handleBlur} className="form-control" value={values.botones.url} />
                                            {errors &&
                                                errors.botones &&
                                                errors.botones.url &&
                                                (<div className="text-danger">{errors.botones.url}</div>)}
                                            <label>Estilo</label>
                                            <button type="button" onClick={() => setShowModalColores(true)} className="btn btn-info btn-xs me-2 btn-rounded">?</button>
                                            <select name="botones.estilo" onChange={handleChange} className="form-control" value={values.botones.estilo}>
                                                <option>Seleccionar</option>
                                                <option value="color1">Color 1</option>
                                                <option value="color2">Color 2</option>
                                                <option value="color3">Color 3</option>
                                                <option value="color4">Color 4</option>
                                                <option value="color5">Color 5</option>
                                                <option value="color6">Color 6</option>
                                                <option value="color7">Color 7</option>
                                            </select>
                                            {errors &&
                                                errors.botones &&
                                                errors.botones.estilo &&
                                                (<div className="text-danger">{errors.botones.estilo}</div>)}
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className="mt-5">
                                    <Col className="col col-lg-2">
                                        <Button type="button" onClick={() => handleSubmit()} disabled={isLoading} className="btn btn-success btn-icon"><i className="fa fa-floppy-o " />Guardar</Button>
                                    </Col>
                                    <Col className="col col-lg-3">
                                        <Button type="button" onClick={() => onEnviarAprobar(values)} disabled={isLoading} className="btn btn-info btn-icon" ><i className="fa fa-share " />Enviar aprobar</Button>
                                    </Col>
                                </Row>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </form>
        </React.Fragment>
    );
}
export default VerBorradorHomeBotones;