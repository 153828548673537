/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import { Row, Col, Button, } from 'react-bootstrap';
import { Fetch, catchError } from "../../../utils/fetch";
import MediaUploader from '../../../utils/media-uploader'
import { FieldArray, Formik } from "formik";
import JoditEditor from "jodit-react";
import { useDispatch, useSelector } from 'react-redux';
import { AlertInfo, AlertSuccess } from '../../alert';
import { loadingAction } from '../../../redux/actions'
import { useHistory } from 'react-router-dom'
import { validate } from './validationSchema';
import { EstadoContenido } from '../../../utils/constants';
import { eliminarImagen, removeSpecial } from '../../../utils/tools';
import LayoutJoditList from '../../customizer/layout-jodit-list';

const Template2Component = ({ dataResult, accion }) => {

  const dispatch = useDispatch()
  const history = useHistory()
  const isLoading = useSelector(state => state.loading)
  const [layoutJoditList, setLayoutJoditList] = React.useState([]);
  const [formValues, setFormValues] = React.useState({})

  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/doc/
      language: 'es', disablePlugins: ['table'],
      disabled: (accion === 'eliminar-publicado' ? true : false),
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      defaultActionOnPaste: "insert_clear_html",
      enableDragAndDropFileToEditor: true,
      uploader: {
        url: process.env.REACT_APP_BASE_URL + '/upload',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('jwt_token')
        },
        data: null,
        filesVariableName: function (i) {
          return "files"
        },
        withCredentials: false,
        pathVariableName: "path",
        format: "json",
        method: "POST",
        prepareData: function (formData) {
          formData.append('id', 1);

        },
        isSuccess: function (resp) {
          return !resp.error;
        },
        getMessage: function (resp) {
          return resp.msgs.join('\n');
        },
        process: function (resp) {
          return resp;
        },
        defaultHandlerSuccess: function (resp) {
          var imagenesPermitidas = ["image/jpeg", "image/png"];

          for (var i = 0; i < resp.length; i++) {
            if (imagenesPermitidas.indexOf(resp[i].mime) !== -1) {
              var img1 = new Image();
              img1.src = resp[i]['url'];
              img1.alt = 'imagen';
              img1.className = "img-fluid"
              this.s.insertImage(img1);
            } else if (resp[i].mime === "application/pdf") {
              const enlace = document.createElement('a');
              enlace.setAttribute('href', resp[i].url);
              const contenido = document.createTextNode(resp[i].name);
              // add the text node to the newly created div
              enlace.appendChild(contenido);
              this.s.insertNode(enlace);
            } else {
              AlertInfo('Tipo de archivo no permitido');
            }
          }
        }
      }
    }), []);

  React.useEffect(() => {

    if (dataResult) {
      setFormValues(dataResult)

      const count = dataResult.template2.sidemenu.length;
      const aux = [];

      for (let i = 0; i < count; i++) {
        aux.push("PC");
      }

      setLayoutJoditList(aux);
    }
  }, [dataResult])



  const onGrabarNuevo = async (values) => {
    dispatch(loadingAction(true));

    values.template = 'template2';
    values.published_at = null;
    values.estado = EstadoContenido.EnRevision

    try {
      const { data } = await Fetch("paginas", "post", values);
      dispatch(loadingAction(false));

      AlertInfo('Página creada con éxito.<br/>Recuerde que falta su confirmación para aprobar.<br/> Nota: Por favor, notificar al responsable de publicar')
      history.push("/pages/lista-borradores");

    } catch (error) {
      catchError(error)
      dispatch(loadingAction(false));
    }
  }

  const onActualizarBorrador = async (values) => {
    dispatch(loadingAction(true));

    values.published_at = null;
    values.estado = EstadoContenido.EnRevision

    try {
      const { data } = await Fetch("paginas/" + dataResult.id, "put", values);

      dispatch(loadingAction(false));

      AlertInfo('Página actualizada con éxito.<br/>Recuerde que falta su confirmación para aprobar.<br/> Nota: Por favor, notificar al responsable de publicar')
      history.push("/pages/lista-borradores");

    } catch (error) {
      catchError(error)
      dispatch(loadingAction(false));
    }
  }

  const onPublicar = async (values) => {

    dispatch(loadingAction(true));

    values.published_at = new Date()
    values.estado = EstadoContenido.Publicado

    delete values.template2.id
    values.template2.sidemenu.forEach(x => delete x.id)
    try {
      const { data } = await Fetch("paginas/aprobar/" + (values.idPadre === null ? dataResult.id : values.idPadre), "put", values);
      dispatch(loadingAction(false));

      AlertSuccess('Página actualizada con éxito')

      history.push("/pages/lista");
    } catch (error) {
      catchError(error)
      dispatch(loadingAction(false));
    }
  }

  const onActualizarPublicado = async (values) => {

    dispatch(loadingAction(true));

    values.published_at = null;
    values.idPadre = values.id;
    values.estado = EstadoContenido.EnRevision

    try {

      const { data } = await Fetch("paginas", "post", values);
      AlertInfo('Página actualizada con éxito.<br/>Recuerde que falta su confirmación para aprobar.<br/> Nota: Por favor, notificar al responsable de publicar')


      dispatch(loadingAction(false));
      history.push("/pages/lista");
    } catch (error) {
      catchError(error)
      dispatch(loadingAction(false));
    }
  }

  const onEliminarPublicado = async (values) => {
    dispatch(loadingAction(true));

    try {
      const { data } = await Fetch("paginas/" + dataResult.id, "delete");

      dispatch(loadingAction(false));

      AlertSuccess('Página eliminada con éxito')
      history.push("/pages/lista-por-eliminar");

    } catch (error) {
      catchError(error)
      dispatch(loadingAction(false));
    }
  }

  const submit = async (values, errors) => {

    if (values?.template2) {
      values.template2.sidemenu = values.template2?.sidemenu.map(item => {
        item.contenido = removeSpecial(item.contenido)
        return item;
      });
    }

    if (errors?.template2) {
      dispatch(loadingAction(true));
      let errorKeys = Object.keys(errors.template2)
      let errorToCatch = { message: "" }
      for (const error of errorKeys) {
        errorToCatch.message += `
        ${errors.template2[error]}: ${error}
        `
      }
      dispatch(loadingAction(false));
      catchError(errorToCatch);
    } else {
      switch (accion) {
        case 'nuevo':
          await onGrabarNuevo(values)
          break;
  
        case 'actualizar-borrador':
          await onActualizarBorrador(values)
          break;
  
        case 'actualizar-publicado':
          await onActualizarPublicado(values)
          break;
  
        case 'publicar':
          await onPublicar(values)
          break;
  
        case 'eliminar-publicado':
          await onEliminarPublicado(values)
          break;
        default:
          break;
      }
    }
    
    
  }

  const onCancelar = async () => {
    dispatch(loadingAction(true));


    if (accion === 'eliminar-publicado') {
      const params = {
        eliminado: false
      }

      if (formValues.estado == EstadoContenido.EnRevision) {
        params.published_at = null;
      }


      const { data } = await Fetch("paginas/" + dataResult.id, "put", params);


      dispatch(loadingAction(false));
      AlertSuccess('Se ha cancelado la eliminación de la página con éxito')



      //history.push("/pages/lista-por-eliminar");        

      if (formValues.estado == EstadoContenido.EnRevision) {

        history.push("/pages/lista-borradores");
      } else {
        history.push("/pages/lista");
      }
    } else {//por aprobación
      const params = {
        published_at: null,
        estado: EstadoContenido.EnRevision
      }

      if (formValues.estado == EstadoContenido.EnRevision) {
        params.published_at = null;
      }


      const { data } = await Fetch("paginas/" + dataResult.id, "put", params);

      dispatch(loadingAction(false));
      AlertSuccess('Se ha cancelado la aprobación de la página con éxito')

      history.push("/pages/lista-borradores");

      // if(formValues.estado==EstadoContenido.EnRevision)
      // {            

      // }else{
      //     history.push("/pages/lista");
      // }

    }
  }

  const handleBlurTitulo = async (event, setFieldValue) => {
    const value = event.target.value;
    const name = event.target.name;
    const dataToApi = {
      contentTypeUID: "application::paginas.paginas",
      field: "slug",
      data: {
        nombre: value,
        slug: ""
      }
    }
    setFieldValue(name, value, false)
    try {
      const { data } = await Fetch("content-manager/uid/generate", "post", dataToApi);
      //fn('slug', data.data, false)
      setFieldValue('slug', data.data, false)
    } catch (error) {
      console.error('mostrando error', error)
    }
  }

  const onEnviarAprobar = async (values, errors) => {
    if (errors?.template2) {
      dispatch(loadingAction(true));
      let errorKeys = Object.keys(errors.template2)
      let errorToCatch = { message: "" }
      for (const error of errorKeys) {
        errorToCatch.message += `
        ${errors.template2[error]}: ${error}
        `
      }
      dispatch(loadingAction(false));
      catchError(errorToCatch);
    } else {
      dispatch(loadingAction(true));


      values.estado = EstadoContenido.EnAprobacion

      try {

        await Fetch("paginas/" + dataResult.id, "put", values);

        dispatch(loadingAction(false));

        AlertSuccess('Contenido enviado con éxito')
        history.push("/pages/listax");


      } catch (error) {
        dispatch(loadingAction(false));
        catchError(error)
      }
    }
  };
  function Botones({ handleSubmit, values, errors }) {


    let botonGrabar = '';
    let estiloBotonGrabar = '';
    let estiloIconoGrabar = ''

    switch (accion) {
      case 'nuevo':
      case 'actualizar-borrador':
      case 'actualizar-publicado':
        botonGrabar = 'Guardar'
        estiloBotonGrabar = 'btn btn-success btn-icon'
        estiloIconoGrabar = 'fa fa-floppy-o'
        break;

      case 'publicar':
        botonGrabar = 'Publicar'
        estiloBotonGrabar = 'btn btn-success btn-icon'
        estiloIconoGrabar = 'fa fa-floppy-o'
        break;

      case 'eliminar-publicado':
        botonGrabar = 'Eliminar'
        estiloBotonGrabar = 'btn btn-danger btn-icon'
        estiloIconoGrabar = 'fa fa-trash'
        break;

      default:
        break;
    }

    return (
      <Row className="mt-5">
        <Col className="col col-lg-2">
          <Button type="button" onClick={() => accion === "eliminar-publicado" ? onEliminarPublicado(formValues) : handleSubmit()}
            disabled={isLoading}
            className={estiloBotonGrabar} >
            <i className={estiloIconoGrabar} />
            {botonGrabar}
          </Button>
        </Col>

        {['publicar', 'eliminar-publicado'].includes(accion) &&
          <Col className="col col-lg-3">
            <Button type="button" onClick={() => onCancelar()} disabled={isLoading} className="btn btn-primary btn-icon" ><i className="fa fa-undo " />{accion === 'publicar' ? 'Por revisar' : 'Cancelar'}</Button>
          </Col>}

        {['actualizar-borrador'].includes(accion) &&
          <Col className="col col-lg-3">
            <Button type="button" onClick={() => onEnviarAprobar(values, errors)} disabled={isLoading} className="btn btn-info btn-icon" ><i className="fa fa-share " />Enviar aprobar</Button>
          </Col>}
      </Row>
    )
  }

  const initialValues = {
    nombre: "",
    slug: "",
    template: "template2",
    template2: {
      fondo: "",
      sidemenu: [],
    },
    locale: "es",
  }

  const handleLayoutJodit = (index, device) => {
    const data = [...layoutJoditList];
    data[index] = device;
    setLayoutJoditList(data);
  }

  const handleAgregarItim = (push) => {
    setLayoutJoditList(val => [...val, "PC"]);
    push({ nombre: "", contenido: "", url: "#" });
  }

  return (
    <React.Fragment>

      <Formik
        initialValues={formValues || initialValues}
        enableReinitialize
        validationSchema={validate}
        onSubmit={(values, errors) => { submit(values, errors) }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          setValues
          /* and other goodies */
        }) => (

          <form handleSubmit={handleSubmit}>
            <Row>
              <Col>
                <h2>Información de la página</h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-group">
                  <label>Nombre de la página</label>
                  <input type="text"
                    name="nombre"
                    disabled={accion === 'eliminar-publicado' ? true : false}
                    onChange={handleChange}
                    onBlur={(e) => handleBlurTitulo(e, setFieldValue)}
                    value={values.nombre} className="form-control " />
                  <div className="text-danger">{errors?.nombre}</div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-group">
                  <label>Url</label>
                  <input type="text" name="slug" className="form-control" value={values.slug} readOnly />
                </div>
              </Col>
            </Row>
            {values?.template2?.fondo && Object.keys(values?.template2?.fondo).length > 0 &&
              <>
                <Row>
                  <Col>
                    <div className="form-group">
                      <img src={values.template2.fondo.url} alt="vista-previa" className="img-fluid w-50" />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      type="button"
                      style={{ marginBottom: "10px" }}
                      onClick={() => {
                        eliminarImagen(values.template2.fondo.id).then(response => {
                          setFieldValue("template2.fondo", "", false)
                        }).catch(error => { });;
                      }
                      }
                      className="btn btn-danger btn-icon mt-2"
                    >
                      <i className="fa fa-trash-o " />
                      Eliminar
                    </Button>
                  </Col>
                </Row>
              </>
            }

            {accion !== 'eliminar-publicado' &&
              <Row>
                <Col>
                  <div className="form-group">
                    <label>Fondo</label>
                    <MediaUploader value={values.template2?.fondo ? 100 : 0} key={values.template2?.fondo} setFieldValue={(name, value) => { setFieldValue(name, value, false); setValues({ ...values, template2: { ...values.template2, fondo: value } }) }} field={'template2.fondo'} tipoMedia='imagen' ancho={2600} alto={200} />
                    <div className="text-danger">{errors?.template2?.fondo}</div>
                    <span className="text-muted">Se recomienda una imagen de 2600px de ancho x 200px de alto. En formato JPG o PNG.
                      El tercio central del banner será el visible en todas las versiones, tanto escritorio como mobile.</span>
                  </div>
                </Col>
              </Row>}

            <hr />
            <Row>
              <Col>
                <h2>Información del contenido</h2>
              </Col>
            </Row>

            <Row>
              <Col>

                <FieldArray name="template2.sidemenu">
                  {({ move, swap, push, remove, insert, unshift, pop, form }) => {
                    return (
                      <>
                        {values?.template2?.sidemenu && values?.template2?.sidemenu?.length > 0 &&
                          values?.template2?.sidemenu?.map((data, index) => {
                            return (
                              <Row key={index} className="border m-3">

                                <Col lg={12} className="p-0 d-flex justify-content-end">
                                  <button type="button" className="btn btn-danger" onClick={() => remove(index)}>x</button>
                                </Col>
                                <Col lg={12}>
                                  <div className="form-group">
                                    <label>Nombre</label>
                                    <input type="text"
                                      disabled={accion === 'eliminar-publicado' ? true : false}
                                      className="form-control"
                                      name={`template2.sidemenu[${index}].nombre`}
                                      onChange={handleChange}
                                      value={values?.template2?.sidemenu[index]?.nombre} />
                                    {errors &&
                                      errors.template2 &&
                                      errors.template2.sidemenu &&
                                      errors.template2.sidemenu[index] &&
                                      errors.template2.sidemenu[index].nombre &&
                                      (
                                        <div className="text-danger">{errors.template2.sidemenu[index].nombre}</div>
                                      )
                                    }
                                  </div>
                                </Col>
                                <Col lg={12}>
                                  <div className="form-group">
                                    <label>Url</label>
                                    <input type="text"
                                      disabled={accion === 'eliminar-publicado' ? true : false}
                                      className="form-control"
                                      name={`template2.sidemenu[${index}].url`}
                                      onChange={handleChange}
                                      value={values?.template2?.sidemenu[index]?.url} />
                                    {errors &&
                                      errors.template2 &&
                                      errors.template2.sidemenu &&
                                      errors.template2.sidemenu[index] &&
                                      errors.template2.sidemenu[index].url &&
                                      (
                                        <div className="text-danger">{errors?.template2?.sidemenu[index]?.url}</div>
                                      )
                                    }
                                  </div>
                                </Col>
                                <Col className={`${layoutJoditList[index] == "MOBILE" && "col-6"}`}>
                                  <div className="form-group">
                                    <label>Contenido</label>
                                    <LayoutJoditList
                                      index={index}
                                      setLayoutJodit={handleLayoutJodit}
                                      layoutJodit={layoutJoditList[index]}
                                    />
                                    <JoditEditor
                                      config={config}
                                      value={values?.template2?.sidemenu[index]?.contenido}
                                      tabIndex={1} // tabIndex of textarea                                      
                                      onChange={handleChange}
                                      onBlur={(v) => setFieldValue(`template2.sidemenu[${index}].contenido`, v, false)}
                                    />
                                    {errors &&
                                      errors.template2 &&
                                      errors.template2.sidemenu &&
                                      errors.template2.sidemenu[index] &&
                                      errors.template2.sidemenu[index].contenido &&
                                      (
                                        <div className="text-danger">{errors.template2.sidemenu[index].contenido}</div>
                                      )
                                    }
                                  </div>
                                </Col>
                              </Row>
                            )
                          }

                          )}
                        <button type="button" className="btn btn-secondary" onClick={() => handleAgregarItim(push)}>Añadir Item</button>
                      </>
                    )
                  }
                  }
                </FieldArray>

                <Botones handleSubmit={handleSubmit} values={values} errors={errors} />

              </Col>
            </Row>

          </form>
        )}
      </Formik>
    </React.Fragment>

  );
}
export default Template2Component;