import * as Yup from 'yup';

export const validate = Yup.object().shape({
    media: Yup.object().required('Campo Obligatorio').nullable(),
    enlace: Yup.string().required('Campo Obligatorio'),
    destacado: Yup.boolean().required('Campo obligatorio'),
    caption: Yup.object().when("destacado", {
        is: true,
        then: Yup.object().shape({
            // h1: Yup.string().required('Campo Obligatorio'),
            // h3: Yup.string().required('Campo Obligatorio'),
            // telefono: Yup.string().required('Campo Obligatorio'),
            botones: Yup.array().of(
                Yup.object().shape({
                    // nombre: Yup.string().required('Campo Obligatorio'),
                    url: Yup.string().required('Campo Obligatorio'),
                    estilo: Yup.string().required('Campo Obligatorio'),
                })
            )
        })
    }),
});