import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Fetch, catchError } from '../../../utils/fetch'
import { AlertConfirm } from '../../../components/alert'
import { connect } from 'react-redux'
import { loadingAction } from '../../../redux/actions';
import { formatDate } from '../../../utils/formatDate';
import DataGridForm from '../../../components/common/DataGridForm';

function ListaMenus({ isLoadingAction }) {

    const [contenido, setContenido] = React.useState(null)

    React.useEffect(() => {
        getContenido()
    }, [])

    const getContenido = async () => {
        isLoadingAction(true)
        try {
            const { data } = await Fetch('menus?_eliminado=false&_sort=orden:ASC', 'get')
            setContenido(data)
            isLoadingAction(false)
        } catch (error) {
            console.error('mostrando error', error)
            isLoadingAction(false)
            catchError(error);
        }
    }    

    const ordenContenido = async (e) => {

        try {
            isLoadingAction(true)

            let request = []
            e.forEach(async (item, indice) => {
                const params = {
                    orden: indice
                }
                request.push(Fetch('menus/' + item.id, 'put', params))
            })

            Promise.all(request).then(response => { isLoadingAction(false) })

        } catch (error) {
            isLoadingAction(false)
            catchError(error);
        }
    }    

    const deleteContenido = async (id) => {
        isLoadingAction(true)
        try {
            const params = {
                eliminado: true
            }
            await Fetch('menus/' + id, 'put', params)
            getContenido()
            isLoadingAction(false)
        } catch (error) {
            console.error('mostrando error', error)
            isLoadingAction(false)
            catchError(error);
        }
    }

    function RenderAlert(title, id) {
        AlertConfirm(title, 'Para eliminarlo definitivamente o recuperarlo deberás ir a la sección "Por eliminar".', 'question', (response) => {
            if (response.isConfirmed) {
                deleteContenido(id)
            }
        })
    }

    const handlerOnOff = async (prmId, value) => {
        isLoadingAction(true)
        try {
            const params = {
                onOff: value
            }
            await Fetch('menus/' + prmId, 'put', params)
            getContenido()
            isLoadingAction(false)
        } catch (error) {
            isLoadingAction(false)
            catchError(error);
        }
    }

    const onReorder = async (e) => {
        const visibleRows = e.component.getVisibleRows();
        const newData = [...contenido];
        const toIndex = newData.indexOf(visibleRows[e.toIndex].data);
        const fromIndex = newData.indexOf(e.itemData);

        newData.splice(fromIndex, 1);
        newData.splice(toIndex, 0, e.itemData);

        setContenido(newData);
        await ordenContenido(newData)
    }

    function FActualizacion(data) {
        return formatDate(data?.updated_at)
    }

    function OnOff(data) {
        return (
            <div class="custom-control custom-switch">
                <input type="checkbox"
                    checked={data.onOff}
                    class="custom-control-input"
                    onChange={(v) => handlerOnOff(data.id, v.target.checked)}
                    id={"csPublicado" + data.id} />
                <label role="button" class="custom-control-label" for={"csPublicado" + data.id}></label>
            </div>
        )
    }

    
    function Accion(data) {
        return (
            <>
                <Link to={"/menus/ver/" + data.id} className="btn btn-sm btn btn-success"><i className="fa fa-pencil" /></Link>{' '}
                <button type="button" className="btn btn-sm btn btn-danger" onClick={() => { RenderAlert("Está apunto de eliminar este contenido", data.id) }} ><i className="fa fa-trash" /></button>{' '}
            </>
        )
    }
    
    const creacion=(data)=>formatDate(data?.created_at);

    return (
        <React.Fragment>
            <Row>
                <Col md="12">
                    <Card>
                        <Card.Header>
                            <div className="float-right mt-10">
                                <Link to="/menus/nuevo" className="btn btn-primary btn-rounded box-shadow btn-icon"><i className="fa fa-plus" /> Nuevo Menú</Link>
                            </div>
                            Listado de menús publicados
                        </Card.Header>
                        <Card.Body>
                            <DataGridForm dataSource={contenido}
                                keyExpr='id'                               
                                allowReordering={true}
                                onReorder={onReorder}
                                columnHidingEnabled={true}
                                columns={[
                                    { caption: 'Id', dataField: 'id' },
                                    { caption: 'Nombre', dataField: 'menu.nombre' },
                                    { caption: 'Creado por', dataField: 'creado_por.username' },
                                    { caption: 'Creación',render:"creacion", cellRender:{creacion}},
                                    { caption: 'Actualizado por', dataField: 'actualizado_por.username' },
                                    { caption: 'Ult. Actualización', render: 'FActualizacion', cellRender: { FActualizacion } },
                                    { caption: 'Inactivo/Activo', alignment: 'center', render: 'OnOff', cellRender: { OnOff } },
                                    { caption: 'Acción', alignment: 'center', render: 'Accion', cellRender: { Accion } }
                                ]} />
                            
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {

    return {
        isLoading: state.loading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        isLoadingAction: (data) => { dispatch(loadingAction(data)) }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ListaMenus);