import * as Yup from 'yup';

export const validate = Yup.object().shape({
    nombre: Yup.string().required('Campo Obligatorio'),
    template2: Yup.object().shape({
        fondo: Yup.object({}).required('Campo Obligatorio').nullable(),
        sidemenu: Yup.array().of(
            Yup.object().shape({
                nombre: Yup.string().required('Campo obligatorio'),
                url: Yup.string().required("Campo obligatorio"),
                contenido: Yup.string().required('Campo Obligatorio'),                
            })
        ).min(1, 'Se requiere mínimo un item')
        
    })
});