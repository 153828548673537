/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import { Form, Row, Col, Button, Card } from 'react-bootstrap';
import JoditEditor, { Jodit } from "jodit-react";
import { useFormik } from 'formik';
import { Fetch, catchError } from '../../../utils/fetch'
import { useDispatch, useSelector } from 'react-redux';
import { AlertSuccess, AlertInfo, AlertConfirm } from '../../../components/alert';
import { loadingAction } from '../../../redux/actions'
import { useHistory, useParams } from 'react-router-dom';
import { validate } from '../validationSchema'
import { EstadoContenido } from '../../../utils/constants';
import { eliminarImagen, removeSpecial } from '../../../utils/tools';

const VerEliminarNovedad = () => {

    const { id, eliminar } = useParams()
    const dispatch = useDispatch()
    const history = useHistory()
    const isLoading = useSelector(state => state.loading)
    const permissions = useSelector(state => state.permissions)
    //const canPublish = permissions.prestadores.publicar.enabled;

    // function preparePaste(jodit) {
    //     jodit.e.on(
    //         'paste',
    //         e => {

    //             jodit.e.stopPropagation('paste');
    //             let plainText = Jodit.modules.Helpers.getDataTransfer(e).getData(Jodit.constants.TEXT_PLAIN);plainText=removeSpecial(plainText);
    //             // plainText = plainText.replace(/(^|;)\s*font-[^;]+/g, "");
    //             // let regex = /<(?!(\/\s*)?(div|b|i|em|strong|u|p)[>,\s])([^>])*>/g;
    //             // plainText = plainText.replace(regex, '');
    //             plainText = plainText.normalize('NFKC').replaceAll("\n", "<br/>");

    //             jodit.s.insertHTML(
    //                 plainText
    //             );
    //             return false;

    //         },
    //         { top: true }
    //     );
    // }
    // Jodit.plugins.add('preparePaste', preparePaste);

    const config = useMemo(
        () => ({
            readonly: false, // all options from https://xdsoft.net/jodit/doc/
            language: 'es', disablePlugins: ['table'],
            askBeforePasteHTML: false,
            askBeforePasteFromWord: false,
            defaultActionOnPaste: "insert_clear_html",
            disabled: true
        }), []);

    React.useEffect(() => {
        if (id) {
            getNovedadesById()
        }
    }, [id])

    const getNovedadesById = async (values) => {
        dispatch(loadingAction(true));

        try {

            const { data } = await Fetch(`prestadores/${id}`, "get");

            setValues(data)
            dispatch(loadingAction(false));
        } catch (error) {
            catchError(error)
            dispatch(loadingAction(false));
        }
    }


    const submit = async (values) => {
        dispatch(loadingAction(true));

        try {

            const { data } = await Fetch("prestadores/" + id, "delete", values);

            dispatch(loadingAction(false));
            AlertSuccess('Novedad eliminada con éxito')
            

            if (values.estado == EstadoContenido.EnRevision) {
                history.push("/prestadores/lista-borradores");
            } else {
                history.push("/prestadores/lista");
            }

        } catch (error) {
            catchError(error)
            dispatch(loadingAction(false));
        }
    }

    const onCancelar = async (values2, type) => {

        dispatch(loadingAction(true));


        try {
            const params = {
                eliminado: false
            }

            if (values.estado == EstadoContenido.EnRevision) {
                params.published_at = null;
            }

            const { data } = await Fetch("prestadores/" + id, "put", params);

            dispatch(loadingAction(false));;
            AlertSuccess('Se ha cancelado la eliminación de la novedad con éxito')


            if (values.estado == EstadoContenido.EnRevision) {
                history.push("/prestadores/lista-borradores");
            } else {
                history.push("/prestadores/lista");
            }

        } catch (error) {
            dispatch(loadingAction(false));
            catchError(error)
        }
    };

    const { values, errors, handleSubmit, handleChange, setFieldValue, setValues } = useFormik({
        initialValues: {
            titulo: "",
            descripcionCorta: "",
            descripcion: "",
            imagen: {},
            destacado: null,
            slug: "",
            orden: 0,
            habilitado: true,
            locale: "es",
        },
        enableReinitialize: true,
        validationSchema: validate,
        onSubmit: (values) => submit(values)
    })

    const handleBlurTitulo = async (event) => {
        const value = event.target.value;
        const name = event.target.name;
        const dataToApi = {
            contentTypeUID: "application::prestadores.prestadores",
            field: "slug",
            data: {
                titulo: value,
                slug: ""
            }
        }
        setFieldValue(name, value, false)
        try {
            const { data } = await Fetch("content-manager/uid/generate", "post", dataToApi);
            setFieldValue('slug', data.data, false)
        } catch (error) {
            console.error('mostrando error', error)
        }
    }

    const handleChangeJoiEditor = (data, field) => {
        setFieldValue(field, data, false);
    }

    const deleteContenido = async () => {
        dispatch(loadingAction(true));
        try {
            await Fetch('prestadores/' + id, 'delete')
            history.push('/')
            dispatch(loadingAction(false));
        } catch (error) {
            console.error('mostrando error', error)
            dispatch(loadingAction(false));
            catchError(error);
        }
    }

    function RenderAlert() {
        AlertConfirm('', 'Para eliminarlo definitivamente o recuperarlo deberás ir a la sección "Por eliminar".', 'question',(response) => {
            if (response.isConfirmed) {
                deleteContenido()
            }
        })
    }

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <Card>
                            <Card.Header>
                                Información del menú


                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <label>Título de la novedad</label>
                                            <input type="text" disabled name="titulo" onChange={handleChange} onBlur={(e) => handleBlurTitulo(e)} value={values.titulo} className="form-control " />
                                            {errors && errors?.titulo && <div className="text-danger">{errors?.titulo} </div>}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <label>Url</label>
                                            <input type="text" name="slug" className="form-control" value={values.slug} readOnly />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <label>Imagen</label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {values?.imagen && Object.keys(values.imagen).length > 0 &&
                                    <>
                                    <Row>
                                      <Col>
                                        <Form.Group>
                                          <img
                                            src={values.imagen.url}
                                            alt="vista-previa"
                                            className="img-fluid w-50"
                                          />
                                        </Form.Group>
                                      </Col>
                                    </Row>                                    
                                  </>
                                }


                                <hr />
                                <Row>
                                    <Col>
                                        <h3>Información del contenido</h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <label>Destacado</label>
                                            <select className="form-control" disabled name="destacado" value={values.destacado} onChange={handleChange}>
                                                <option>Seleccionar</option>
                                                <option value={true}>Si</option>
                                                <option value={false}>No</option>
                                            </select>
                                            {errors && errors?.destacado && <div className="text-danger">{errors?.destacado} </div>}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <label>Descripción Corta</label>
                                            <textarea name="descripcionCorta" disabled onChange={handleChange} className="form-control" value={values.descripcionCorta}></textarea>
                                            {errors && errors?.descripcionCorta && <div className="text-danger">{errors?.descripcionCorta} </div>}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <label>Descripción</label>
                                            <JoditEditor
                                                config={config}
                                                value={values.descripcion}
                                                tabIndex={1} // tabIndex of textarea
                                                onBlur={newContent => handleChangeJoiEditor(newContent, 'descripcion')}
                                            />
                                            {errors && errors?.descripcion && <div className="text-danger">{errors?.descripcion} </div>}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {/* <Button type="button" onClick={() => handleSubmit()} disabled={isLoading} className="btn btn-success btn-icon mt-2" ><i className="fa fa-floppy-o " />Guardar</Button> */}

                                <Row className="mt-5">
                                    <Col className="col col-lg-2">
                                        <Button
                                            type="button"
                                            onClick={() => handleSubmit()}
                                            disabled={isLoading}
                                            className="btn btn-danger btn-icon"
                                        >
                                            <i className="fas fa-trash" />
                                            Eliminar
                                        </Button>
                                    </Col>
                                    <Col className="col col-lg-3">
                                        <Button
                                            type="button"
                                            onClick={() => onCancelar()}
                                            disabled={isLoading}
                                            className="btn btn-primary btn-icon"
                                        >
                                            <i className="fas fa-undo" />
                                            Cancelar
                                        </Button>
                                    </Col>
                                </Row>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>


            </form>
        </React.Fragment>
    );
}
export default VerEliminarNovedad;