import { Row, Col, Card, Button, Form } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFormik } from "formik";

import { loadingAction } from "../../redux/actions";
import { validateModel } from "./validationSchema";
import { AlertInfo } from "../../components/alert";
import { Fetch } from "../../utils/fetch";

const initialValues = {
  planSalud: "",
  urgencias: "",
  tramites: "",
};

function DatosContacto({ isLoadingAction }) {
  const [flujo] = useState(window.location.pathname.split("/").pop());
  const [formValues, setFormValues] = useState({ ...initialValues });

  const [disableBtn, setDisableBtn] = useState(true);
  const user = useSelector((state) => state.user);

  const history = useHistory();

  const formik = useFormik({
    initialValues: formValues,
    enableReinitialize: true,
    validationSchema: validateModel,
    onSubmit: (values) => submit(values),
  });

  const editar = async (estado) => {
    isLoadingAction(true);

    const values = formik.values;
    values.published_at = null;
    values.estado = estado ?? values.estado;

    const endpoint = "datos-contactos";
    await Fetch(`${endpoint}/${values.id}`, "PUT", values);

    isLoadingAction(false);
  };

  const porRevisar = async () => {
    await editar("ER");
    history.push("/configuracion/datos-contacto/en-revision");
  };

  const submit = async (values) => {
    isLoadingAction(true);
    let endpoint = "";

    if (flujo == "publicado") {
      endpoint = "datos-contactos/editar-publicado";
      await Fetch(endpoint, "POST", values);

      history.push("/configuracion/datos-contacto/en-revision");
    } else if (flujo == "en-revision") {
      endpoint = "datos-contactos/enviar-aprobar";
      await Fetch(endpoint, "POST", values);

      user.data.role.type == "creador"
        ? history.push("/configuracion/datos-contacto/publicado")
        : history.push("/configuracion/datos-contacto/por-aprobar");

    } else if (flujo == "por-aprobar") {
      endpoint = "datos-contactos/publicar";
      await Fetch(endpoint, "POST", values);

      history.push("/configuracion/datos-contacto/publicado");
    }

    isLoadingAction(false);
    AlertInfo("Contenido actualizado con éxito.");
  };

  const getData = async () => {
    isLoadingAction(true);
    let url = "";

    switch (flujo) {
      case "publicado":
        url = "datos-contactos";
        break;

      case "en-revision":
        url = "datos-contactos?_publicationState=preview&estado=ER";
        break;

      case "por-aprobar":
        url = "datos-contactos?_publicationState=preview&estado=EA";
        break;
    }

    const { data } = await Fetch(url, "get");
    const [contacto] = data;

    setFormValues(contacto || initialValues);
    isLoadingAction(false);

    if (contacto) setDisableBtn(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const MyButtons = () => {
    if (flujo == "publicado") {
      return (
        <Col className="col col-lg-2">
          <Button
            type="button"
            onClick={formik.submitForm}
            className="btn btn-success btn-icon no-hover"
            disabled={disableBtn}
          >
            <i className="fa fa-floppy-o " />
            Guardar
          </Button>
        </Col>
      );
    } else if (flujo == "en-revision") {
      return (
        <>
          <Col className="col col-lg-2">
            <Button
              type="button"
              onClick={editar}
              className="btn btn-success btn-icon no-hover"
              disabled={disableBtn}
            >
              <i className="fa fa-floppy-o " />
              Guardar
            </Button>
          </Col>

          <Col className="col col-lg-4">
            <Button
              type="button"
              onClick={formik.submitForm}
              className="btn btn-info btn-icon no-hover"
              disabled={disableBtn}
            >
              <i className="fa fa-share " />
              Enviar aprobar
            </Button>
          </Col>
        </>
      );
    } else if ("por-aprobar") {
      return (
        <>
          <Col className="col col-lg-2">
            <Button
              type="button"
              onClick={formik.submitForm}
              className="btn btn-success btn-icon no-hover"
              disabled={disableBtn}
            >
              <i className="fa fa-floppy-o " />
              Publicar
            </Button>
          </Col>

          <Col className="col col-lg-4">
            <Button
              type="button"
              onClick={porRevisar}
              className="btn btn-primary btn-icon no-hover3 btn btn-primary"
              disabled={disableBtn}
            >
              <i className="fa fa-undo " />
              Por revisar
            </Button>
          </Col>
        </>
      );
    }
  };
  const MyTile = () => {
    switch (flujo) {
      case "en-revision":
        return <>En revisión</>;

      case "por-aprobar":
        return <>Por aprobar</>;

      case "publicado":
        return <>Publicado</>;
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Row>
                <Col>
                  Datos de contacto - <MyTile />
                </Col>
              </Row>
              <hr />
            </Card.Header>
            <Card.Body style={{ marginTop: "-36px" }}>
              <Row>
                <Col>
                  <h3>Información general</h3>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <label>Plan de Salud</label>
                    <input
                      type="text"
                      name="planSalud"
                      onChange={formik.handleChange}
                      value={formik.values.planSalud}
                      className="form-control "
                    />
                    <div className="text-danger">
                      {formik.errors?.planSalud}
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group>
                    <label>Urgencias y Emergencias</label>
                    <input
                      type="text"
                      name="urgencias"
                      onChange={formik.handleChange}
                      value={formik.values.urgencias}
                      className="form-control "
                    />
                    <div className="text-danger">
                      {formik.errors?.urgencias}
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group>
                    <label>Gestioná tus consultas y trámites</label>
                    <input
                      type="text"
                      name="tramites"
                      onChange={formik.handleChange}
                      value={formik.values.tramites}
                      className="form-control "
                    />
                    <div className="text-danger">{formik.errors?.tramites}</div>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mt-5">
                <MyButtons />
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </form>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DatosContacto);
