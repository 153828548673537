/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import { Form, Row, Col, Button, Card } from 'react-bootstrap';
import JoditEditor, { Jodit } from "jodit-react";
import { Formik, FieldArray } from 'formik';
import { Fetch, catchError } from '../../../utils/fetch'
import { useDispatch, useSelector } from 'react-redux';
import { AlertSuccess, AlertInfo, AlertConfirm } from '../../../components/alert';
import { loadingAction } from '../../../redux/actions'
import { useHistory, useParams } from 'react-router-dom';
import ModalComponent from '../../../components/modal';
import { validate } from '../validationSchema';
import { EstadoContenido } from '../../../utils/constants';
import { removeSpecial } from '../../../utils/tools';

const VerEliminarPlan = () => {

    const { id, eliminar } = useParams()
    const dispatch = useDispatch()
    const history = useHistory()
    const isLoading = useSelector(state => state.loading)
    const permissions = useSelector(state => state.permissions)
    const [showModalColores, setShowModalColores] = React.useState(false)
    //const canPublish = permissions.planes.publicar.enabled;
    const [formValues, setFormValues] = React.useState({})

    // function preparePaste(jodit) {
    //     jodit.e.on(
    //         'paste',
    //         e => {

    //             jodit.e.stopPropagation('paste');
    //             let plainText = Jodit.modules.Helpers.getDataTransfer(e).getData(Jodit.constants.TEXT_PLAIN);plainText=removeSpecial(plainText);
    //             // plainText = plainText.replace(/(^|;)\s*font-[^;]+/g, "");
    //             // let regex = /<(?!(\/\s*)?(div|b|i|em|strong|u|p)[>,\s])([^>])*>/g;
    //             // plainText = plainText.replace(regex, '');
    //             plainText = plainText.normalize('NFKC').replaceAll("\n", "<br/>");

    //             jodit.s.insertHTML(
    //                 plainText
    //             );
    //             return false;

    //         },
    //         { top: true }
    //     );
    // }
    // Jodit.plugins.add('preparePaste', preparePaste);

    const config = useMemo(
        () => ({
            readonly: false, // all options from https://xdsoft.net/jodit/doc/
            language: 'es', disablePlugins: ['table'],
            askBeforePasteHTML: false,
            askBeforePasteFromWord: false,
            defaultActionOnPaste: "insert_clear_html",
            disabled: true
        }), []);

    React.useEffect(() => {
        if (id) {
            getplanesById()
        }
    }, [id])

    const getplanesById = async (values) => {
        dispatch(loadingAction(true));

        try {
            const { data } = await Fetch(`planes/${id}`, "get");
            setFormValues(data)

            dispatch(loadingAction(false));
        } catch (error) {
            catchError(error)
            dispatch(loadingAction(false));
        }
    }


    const submit = async (values) => {
        dispatch(loadingAction(true));

        try {
            const { data } = await Fetch("planes/" + id, "delete", values);

            dispatch(loadingAction(false));
            AlertSuccess('Plan eliminado con éxito')

            history.push("/planes/lista");

        } catch (error) {
            catchError(error)
            dispatch(loadingAction(false));
        }
    }


    const handleBlurTitulo = async (event, fn) => {
        const value = event.target.value;
        const name = event.target.name;
        const dataToApi = {
            contentTypeUID: "application::planes.planes",
            field: "slug",
            data: {
                nombre: value,
                slug: ""
            }
        }
        fn(name, value, false)
        try {
            const { data } = await Fetch("content-manager/uid/generate", "post", dataToApi);
            fn('slug', data.data, false)
        } catch (error) {
            console.error('mostrando error', error)
        }
    }

    const handleChangeJoiEditor = (data, field, fn) => {
        fn(field, data, false);
    }

    const initialValues = {
        nombre: "",
        descripcion: "",
        slug: "",
        miniatura: "",
        fondo: "",
        titulo: "",
        informacion: "",
        botones: [
            {
                nombre: "",
                url: "",
                estilo: "color1"
            }
        ],

        color: "color1",
        locale: "es",
    }

    const onCancelar = async (values2, type) => {

        dispatch(loadingAction(true));


        try {
            const params = {
                eliminado: false
            }

            if (formValues.estado == EstadoContenido.EnRevision) {
                params.published_at = null;
            }

            const { data } = await Fetch("planes/" + id, "put", params);

            dispatch(loadingAction(false));
            AlertSuccess('Se ha cancelado la eliminación del plan con éxito')

            if (formValues.estado == EstadoContenido.EnRevision) {
                history.push("/planes/lista-borradores");
            } else {
                history.push("/planes/lista");
            }

        } catch (error) {
            dispatch(loadingAction(false));
            catchError(error)
        }
    };

    return (
        <React.Fragment>
            <Formik
                initialValues={formValues || initialValues}
                enableReinitialize
                validationSchema={validate}
                onSubmit={(values, { setSubmitting }) => {
                    // setTimeout(() => {
                    //   alert(JSON.stringify(values, null, 2));
                    //   setSubmitting(false);
                    // }, 400);
                    submit(values);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                    /* and other goodies */
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col>
                                <ModalComponent isShow={showModalColores} setShowModal={setShowModalColores} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Header>
                                        Información del plan
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <label>Nombre del Plan</label>
                                                    <input type="text" disabled name="nombre" onChange={handleChange} onBlur={(e) => handleBlurTitulo(e, setFieldValue)} value={values?.nombre} className="form-control " />
                                                    {errors &&
                                                        errors.nombre &&
                                                        (<div className="text-danger">{errors.nombre}</div>)}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <label>Url</label>
                                                    <input type="text" name="slug" className="form-control" value={values?.slug} readOnly />
                                                    {errors &&
                                                        errors.url &&
                                                        (<div className="text-danger">{errors.url}</div>)}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <label>Descripcion del Plan</label>
                                                    <input type="text" disabled name="descripcion" onChange={handleChange} value={values.descripcion} className="form-control " />
                                                    {errors &&
                                                        errors.descripcion &&
                                                        (<div className="text-danger">{errors.descripcion}</div>)}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <label>Color de Plan</label>
                                                    <button type="button" onClick={() => setShowModalColores(true)} className="btn btn-info btn-xs me-2 btn-rounded">?</button>
                                                    <select name="color" disabled onChange={handleChange} className="form-control" value={values?.color}>
                                                        <option>Seleccionar</option>
                                                        <option value="color1">Color 1</option>
                                                        <option value="color2">Color 2</option>
                                                        <option value="color3">Color 3</option>
                                                        <option value="color4">Color 4</option>
                                                        <option value="color5">Color 5</option>
                                                        <option value="color6">Color 6</option>
                                                        <option value="color7">Color 7</option>
                                                    </select>
                                                    {errors &&
                                                        errors.color &&
                                                        (<div className="text-danger">{errors.color}</div>)}
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <label>Imagen Miniatura</label>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        {values.miniatura && Object.keys(values.miniatura).length > 0 &&
                                            <Row>
                                                <Col>
                                                    <Form.Group>
                                                        <img src={values.miniatura.url} alt="vista-previa" className="img-fluid w-50" />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        }


                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <label>Imagen de Fondo</label>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        {values.fondo && Object.keys(values.fondo).length > 0 &&
                                            <Row>
                                                <Col>
                                                    <Form.Group>
                                                        <img src={values.fondo.url} alt="vista-previa" className="img-fluid w-50" />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        }


                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <label>Título</label>
                                                    <input type="text" disabled name="titulo" onChange={handleChange} className="form-control" value={values.titulo} />
                                                    {errors &&
                                                        errors.titulo &&
                                                        (<div className="text-danger">{errors.titulo}</div>)}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <label>Información</label>
                                                    <JoditEditor
                                                        config={config}
                                                        value={values.informacion}
                                                        tabIndex={1} // tabIndex of textarea
                                                        onBlur={newContent => handleChangeJoiEditor(newContent, 'informacion', setFieldValue)}
                                                    />
                                                    {errors &&
                                                        errors.informacion &&
                                                        (<div className="text-danger">{errors.informacion}</div>)}
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        {/* Botones */}
                                        <Row>
                                            <Col>
                                                <h2>Botones</h2>
                                            </Col>
                                        </Row>
                                        <FieldArray name="botones">
                                            {({ move, swap, push, remove, insert, unshift, pop, form }) => {
                                                return (
                                                    <>
                                                        {values?.botones && values?.botones?.length > 0 &&
                                                            values?.botones.map((data, index) => {
                                                                return (
                                                                    <Row key={index}>


                                                                        <Col>
                                                                            <Row className="border m-3">

                                                                                <Col lg={12} className="p-0 d-flex justify-content-end">
                                                                                    <button type="button" className="btn btn-danger" onClick={() => remove(index)}>x</button>
                                                                                </Col>
                                                                                <Col lg={12}>
                                                                                    <div className="form-group">
                                                                                        <label>Nombre</label>
                                                                                        <input type="text" disabled className="form-control" name={`botones.${index}.nombre`} onChange={handleChange} value={values.botones[index].nombre} />
                                                                                        {errors &&
                                                                                            errors.botones &&
                                                                                            errors.botones[index] &&
                                                                                            errors.botones[index].nombre &&
                                                                                            (<div className="text-danger">{errors.botones[index].nombre}</div>)}
                                                                                    </div>
                                                                                </Col>
                                                                                <Col lg={12}>
                                                                                    <div className="form-group">
                                                                                        <label>Url</label>
                                                                                        <input type="text" disabled className="form-control" name={`botones.${index}.url`} onChange={handleChange} value={values.botones[index].url} />
                                                                                        {errors &&
                                                                                            errors.botones &&
                                                                                            errors.botones[index] &&
                                                                                            errors.botones[index].url &&
                                                                                            (<div className="text-danger">{errors.botones[index].url}</div>)}
                                                                                    </div>
                                                                                </Col>
                                                                                <Col lg={12}>
                                                                                    <div className="form-group">
                                                                                        <label>Estilo</label>
                                                                                        <button type="button" onClick={() => setShowModalColores(true)} className="btn btn-info btn-xs me-2 btn-rounded">?</button>
                                                                                        <select className="form-control" disabled name={`botones.${index}.estilo`} onChange={handleChange} value={values.botones[index].estilo}>
                                                                                            <option>Seleccionar</option>
                                                                                            <option value="color1">Color 1</option>
                                                                                            <option value="color2">Color 2</option>
                                                                                            <option value="color3">Color 3</option>
                                                                                            <option value="color4">Color 4</option>
                                                                                            <option value="color5">Color 5</option>
                                                                                            <option value="color6">Color 6</option>
                                                                                            <option value="color7">Color 7</option>
                                                                                        </select>
                                                                                        {errors &&
                                                                                            errors.botones &&
                                                                                            errors.botones[index] &&
                                                                                            errors.botones[index].estilo &&
                                                                                            (<div className="text-danger">{errors.botones[index].estilo}</div>)}
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>

                                                                            <Row>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                )
                                                            }

                                                            )}
                                                    </>
                                                )
                                            }
                                            }
                                        </FieldArray>

                                        <Row className="mt-5">
                                            <Col className="col col-lg-2">
                                                <Button
                                                    type="button"
                                                    onClick={() => handleSubmit()}
                                                    disabled={isLoading}
                                                    className="btn btn-danger btn-icon"
                                                >
                                                    <i className="fas fa-trash" />
                                                    Eliminar
                                                </Button>
                                            </Col>
                                            <Col className="col col-lg-3">
                                                <Button
                                                    type="button"
                                                    onClick={() => onCancelar()}
                                                    disabled={isLoading}
                                                    className="btn btn-primary btn-icon"
                                                >
                                                    <i className="fas fa-undo" />
                                                    Cancelar
                                                </Button>
                                            </Col>
                                        </Row>

                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>


                    </form>

                )}
            </Formik>

        </React.Fragment>
    );
}
export default VerEliminarPlan;