import * as Yup from 'yup';

export const validate = Yup.object().shape({
    nombre: Yup.string().required('Campo Obligatorio'),
    template3: Yup.object().shape({
        sidemenu: Yup.array().of(
            Yup.object().shape({
                nombre: Yup.string().required('campo obligatorio'),
                url: Yup.string().required("Campo obligatorio"),
                contenido: Yup.string().required('Campo Obligatorio'),
            })
        ).min(1, 'Se requiere minimo un item')
    })
});