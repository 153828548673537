/* eslint-disable react-hooks/exhaustive-deps */
import React,{useMemo} from 'react';
import { Form, Row, Col, Button, Card } from 'react-bootstrap';
import JoditEditor,{Jodit} from "jodit-react";
import { Formik, FieldArray } from 'formik';
import { Fetch, catchError } from '../../../utils/fetch'
import { useDispatch, useSelector } from 'react-redux';
import MediaUploader from '../../../utils/media-uploader'
import { AlertSuccess, AlertInfo } from '../../../components/alert';
import { loadingAction } from '../../../redux/actions'
import { useHistory, useParams } from 'react-router-dom';
import ModalComponent from '../../../components/modal';
import { validate } from '../validationSchema';
import { EstadoContenido } from '../../../utils/constants';
import PromocionesPlanes from '../promociones-planes/promocionesPlanes';
import { eliminarImagen, removeSpecial } from '../../../utils/tools';
import LayoutJodit from '../../../components/customizer/layout-jodit';
const VerPlanX = () => {

    const params = useParams()
    const id = params.id;
    const dispatch = useDispatch()
    const history = useHistory();
    const isLoading = useSelector(state => state.loading)
    const permissions = useSelector(state => state.permissions)
    //const canPublish = permissions.planes.publicar.enabled;
    const [formValues, setFormValues] = React.useState({})
    const [showModalColores, setShowModalColores] = React.useState(false)
    const [layoutJodit, setLayoutJodit] = React.useState("PC");
    const [promosPlan,setPromosPlan]=React.useState([])

    // function preparePaste(jodit) {
    //     jodit.e.on(
    //         'paste',
    //         e => {

    //             jodit.e.stopPropagation('paste');
    //             let plainText = Jodit.modules.Helpers.getDataTransfer(e).getData(Jodit.constants.TEXT_PLAIN);plainText=removeSpecial(plainText);
    //             // plainText = plainText.replace(/(^|;)\s*font-[^;]+/g, "");
    //             // let regex = /<(?!(\/\s*)?(div|b|i|em|strong|u|p)[>,\s])([^>])*>/g;
    //             // plainText = plainText.replace(regex, '');
    //             plainText = plainText.normalize('NFKC').replaceAll("\n","<br/>");

    //             jodit.s.insertHTML(
    //                 plainText
    //             );
    //             return false;

    //         },
    //         { top: true }
    //     );
    // }
    // Jodit.plugins.add('preparePaste', preparePaste);

    const config = useMemo(
        () => ({
        readonly: false, // all options from https://xdsoft.net/jodit/doc/
        language: 'es',disablePlugins:['table'],
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        defaultActionOnPaste: "insert_clear_html",
        enableDragAndDropFileToEditor: true,
        uploader: {
            url: process.env.REACT_APP_BASE_URL + '/upload',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt_token')
            },
            data: null,
            filesVariableName: function (i) {
                return "files"
            },
            withCredentials: false,
            pathVariableName: "path",
            format: "json",
            method: "POST",
            prepareData: function (formData) {
                formData.append('id', 1);

            },
            isSuccess: function (resp) {
                return !resp.error;
            },
            getMessage: function (resp) {
                return resp.msgs.join('\n');
            },
            process: function (resp) {
                return resp;
            },
            defaultHandlerSuccess: function (resp) {
                var imagenesPermitidas=["image/jpeg","image/png"];
                
                for (var i = 0; i < resp.length; i++) {
                    if(imagenesPermitidas.indexOf(resp[i].mime)!==-1)          
                        {
                            var img1 = new Image();
                            img1.src = resp[i]['url'];
                            img1.alt = 'imagen';
                            img1.className = "img-fluid"
                            this.s.insertImage(img1);
                        }else if(resp[i].mime==="application/pdf"){
                            const enlace = document.createElement('a');
                            enlace.setAttribute('href', resp[i].url);
                            const contenido = document.createTextNode(resp[i].name);
                            // add the text node to the newly created div
                            enlace.appendChild(contenido);
                            this.s.insertNode(enlace);
                        }else{
                            AlertInfo('Tipo de archivo no permitido');
                        }
                }
            }
        }
    }),[]);

    React.useEffect(() => {
        if (id) {
            getplanesById()
        }
    }, [])

    const getplanesById = async (values) => {
        // ?_publicationState=preview&published_at_null=true
        dispatch(loadingAction(true));
        try {

            const { data } = await Fetch(`planes/${id}?_publicationState=preview&published_at_null=true`, "get");           
            setFormValues(data)            
            const promociones=data.promociones.map(item=>item.promocion).filter(item=>item!=null);                      
            setPromosPlan(promociones)
            dispatch(loadingAction(false));
        } catch (error) {
            catchError(error)
            dispatch(loadingAction(false));
        }
    }


    const submit = async (values) => {
        values.published_at = new Date();
        values.estado = EstadoContenido.Publicado        
        values.promociones=promosPlan.map(item=> {
            return {promocion:item.id}
        });

        values.informacion=removeSpecial(values.informacion);

        

        dispatch(loadingAction(true));

        const idPadre = values.idPadre;
        const idToApi = idPadre ? idPadre : id;

        if (idPadre) {
            values.botones.forEach(e => { delete e.id });
        }

        try {

            const { data } = await Fetch("planes/aprobar/" + idToApi, "put", values);

            dispatch(loadingAction(false));

            AlertSuccess('Plan actualizado con éxito')

            history.push("/planes/lista");
        } catch (error) {
            catchError(error)
            dispatch(loadingAction(false));
        }
    }

    const onCancelar = async () => {

        dispatch(loadingAction(true));


        try {

            const params = {
                published_at: null,
                estado:EstadoContenido.EnRevision
            }

            const { data } = await Fetch("planes/" + id, "put", params);

            dispatch(loadingAction(false));
            AlertSuccess('Se ha cancelado la publicación del plan con éxito')

            history.push("/planes/lista-borradores");

        } catch (error) {
            dispatch(loadingAction(false));
            catchError(error)
        }
    };

    const handleBlurTitulo = async (event, fn) => {
        const value = event.target.value;
        const name = event.target.name;
        const dataToApi = {
            contentTypeUID: "application::planes.planes",
            field: "slug",
            data: {
                titulo: value,
                slug: ""
            }
        }
        fn(name, value, false)
        try {
            const { data } = await Fetch("content-manager/uid/generate", "post", dataToApi);
            fn('slug', data.data, false)
        } catch (error) {
            console.error('mostrando error', error)
        }
    }

    const handleChangeJoiEditor = (data, field, fn) => {
        fn(field, data, false);
    }

    const initialValues = {
        nombre: "",
        descripcion: "",
        slug: "",
        miniatura: "",
        fondo: "",
        titulo: "",
        informacion: "",
        botones: [
            {
                nombre: "",
                url: "",
                estilo: "color1"
            }
        ],

        color: "color1",
        locale: "es",
    }

    return (
        <React.Fragment>
            <Formik
                initialValues={formValues || initialValues}
                enableReinitialize
                validationSchema={validate}
                onSubmit={(values, { setSubmitting }) => {
                    // setTimeout(() => {
                    //   alert(JSON.stringify(values, null, 2));
                    //   setSubmitting(false);
                    // }, 400);
                    submit(values);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                    setValues
                    /* and other goodies */
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col>
                                <ModalComponent isShow={showModalColores} setShowModal={setShowModalColores} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Header>
                                    Información del plan
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <label>Nombre del Plan</label>
                                                    <input type="text" name="nombre" onChange={handleChange} onBlur={(e) => handleBlurTitulo(e, setFieldValue)} value={values?.nombre} className="form-control " />
                                                    {errors &&
                                                        errors.nombre &&
                                                        (<div className="text-danger">{errors.nombre}</div>)}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <label>Url</label>
                                                    <input type="text" name="slug" className="form-control" value={values?.slug} readOnly />
                                                    {errors &&
                                                        errors.url &&
                                                        (<div className="text-danger">{errors.url}</div>)}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <label>Descripcion del Plan</label>
                                                    <input type="text" name="descripcion" onChange={handleChange} value={values.descripcion} className="form-control " />
                                                    {errors &&
                                                        errors.descripcion &&
                                                        (<div className="text-danger">{errors.descripcion}</div>)}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <label>Color de Plan</label>
                                                    <button type="button" onClick={() => setShowModalColores(true)} className="btn btn-info btn-xs me-2 btn-rounded">?</button>
                                                    <select name="color" onChange={handleChange} className="form-control" value={values?.color}>
                                                        <option>Seleccionar</option>
                                                        <option value="color1">Color 1</option>
                                                        <option value="color2">Color 2</option>
                                                        <option value="color3">Color 3</option>
                                                        <option value="color4">Color 4</option>
                                                        <option value="color5">Color 5</option>
                                                        <option value="color6">Color 6</option>
                                                        <option value="color7">Color 7</option>
                                                    </select>
                                                    {errors &&
                                                        errors.color &&
                                                        (<div className="text-danger">{errors.color}</div>)}
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        {values.miniatura && Object.keys(values.miniatura).length > 0 &&
                                            <>
                                            <Row>
                                                <Col>
                                                    <Form.Group>
                                                        <img src={values.miniatura.url} alt="vista-previa" className="img-fluid w-50" />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                              <Col>
                                                <Button
                                                  type="button"
                                                  style={{ marginBottom: "10px" }}
                                                  onClick={() => {
                                                    eliminarImagen(values.miniatura.id).then(
                                                      (response) => {
                                                        setFieldValue("miniatura", "", false);
                                                      }
                                                    ).catch(error=>{}); 
                                                  }}
                                                  className="btn btn-danger btn-icon mt-2"
                                                >
                                                  <i className="fa fa-trash-o " />
                                                  Eliminar
                                                </Button>
                                              </Col>
                                            </Row>
                                            </>
                                        }

                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <label>Imagen Miniatura</label>
                                                    <MediaUploader value={values?.miniatura?100:0} key={values?.miniatura} setFieldValue={(name,value)=>{setFieldValue(name,value,false);setValues({...values,[name]:value})}} field={'miniatura'} tipoMedia='imagen' ancho={300} alto={370} />
                                                    {errors &&
                                                        errors.miniatura &&
                                                        (<div className="text-danger">{errors.miniatura}</div>)}
                                                    <span className="text-muted">Se recomienda una imagen de 300px de ancho x 370px de alto</span>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        {values.fondo && Object.keys(values.fondo).length > 0 &&
                                           <>
                                           <Row>
                                             <Col>
                                               <Form.Group>
                                                 <img
                                                   src={values.fondo.url}
                                                   alt="vista-previa"
                                                   className="img-fluid w-50"
                                                 />
                                               </Form.Group>
                                             </Col>
                                           </Row>
                                           <Row>
                                             <Col>
                                               <Button
                                                 type="button"
                                                 style={{ marginBottom: "10px" }}
                                                 onClick={() => {
                                                   eliminarImagen(values.fondo.id).then(
                                                     (response) => {
                                                       setFieldValue("fondo", "", false);
                                                     }
                                                   ).catch(error=>{}); 
                                                 }}
                                                 className="btn btn-danger btn-icon mt-2"
                                               >
                                                 <i className="fa fa-trash-o " />
                                                 Eliminar
                                               </Button>
                                             </Col>
                                           </Row>
                                         </>
                                        }

                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <label>Imagen de Fondo</label>
                                                    <MediaUploader value={values?.fondo?100:0} key={values?.fondo} setFieldValue={(name,value)=>{setFieldValue(name,value,false);setValues({...values,[name]:value})}} field={'fondo'} tipoMedia='imagen' ancho={300} alto={370} />
                                                    {errors &&
                                                        errors.fondo &&
                                                        (<div className="text-danger">{errors.fondo}</div>)}
                                                    <span className="text-muted">Se recomienda una imagen de 300px de ancho x 370px de alto</span>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <label>Titulo</label>
                                                    <input type="text" name="titulo" onChange={handleChange} className="form-control" value={values.titulo} />
                                                    {errors &&
                                                        errors.titulo &&
                                                        (<div className="text-danger">{errors.titulo}</div>)}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className={`${layoutJodit == "MOBILE" && "col-6"}`}>
                                                <Form.Group>
                                                    <label>Informacion</label>
                                                    <LayoutJodit
                                                        setLayoutJodit={setLayoutJodit}
                                                        layoutJodit={layoutJodit}
                                                    />     
                                                    <JoditEditor
                                                        config={config}
                                                        value={values.informacion}
                                                        tabIndex={1} // tabIndex of textarea
                                                        onBlur={newContent => handleChangeJoiEditor(newContent, 'informacion', setFieldValue)}
                                                    />
                                                    {errors &&
                                                        errors.informacion &&
                                                        (<div className="text-danger">{errors.informacion}</div>)}
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        {/* Botones */}
                                        <Row>
                                            <Col>
                                                <h2>Botones</h2>
                                            </Col>
                                        </Row>
                                        <FieldArray name="botones">
                                            {({ move, swap, push, remove, insert, unshift, pop, form }) => {
                                                return (
                                                    <>
                                                        {values?.botones && values?.botones?.length > 0 &&
                                                            values?.botones.map((data, index) => {
                                                                return (
                                                                    <Row key={index}>


                                                                        <Col>
                                                                            <Row className="border m-3">

                                                                                <Col lg={12} className="p-0 d-flex justify-content-end">
                                                                                    <button type="button" className="btn btn-danger" onClick={() => remove(index)}>x</button>
                                                                                </Col>
                                                                                <Col lg={12}>
                                                                                    <div className="form-group">
                                                                                        <label>Nombre</label>
                                                                                        <input type="text" className="form-control" name={`botones.${index}.nombre`} onChange={handleChange} value={values.botones[index].nombre} />
                                                                                        {errors &&
                                                                                            errors.botones &&
                                                                                            errors.botones[index] &&
                                                                                            errors.botones[index].nombre &&
                                                                                            (<div className="text-danger">{errors.botones[index].nombre}</div>)}
                                                                                    </div>
                                                                                </Col>
                                                                                <Col lg={12}>
                                                                                    <div className="form-group">
                                                                                        <label>Url</label>
                                                                                        <input type="text" className="form-control" name={`botones.${index}.url`} onChange={handleChange} value={values.botones[index].url} />
                                                                                        {errors &&
                                                                                            errors.botones &&
                                                                                            errors.botones[index] &&
                                                                                            errors.botones[index].url &&
                                                                                            (<div className="text-danger">{errors.botones[index].url}</div>)}
                                                                                    </div>
                                                                                </Col>
                                                                                <Col lg={12}>
                                                                                    <div className="form-group">
                                                                                        <label>Estilo</label>
                                                                                        <button type="button" onClick={() => setShowModalColores(true)} className="btn btn-info btn-xs me-2 btn-rounded">?</button>
                                                                                        <select className="form-control" name={`botones.${index}.estilo`} onChange={handleChange} value={values.botones[index].estilo}>
                                                                                            <option>Seleccionar</option>
                                                                                            <option value="color1">Color 1</option>
                                                                                            <option value="color2">Color 2</option>
                                                                                            <option value="color3">Color 3</option>
                                                                                            <option value="color4">Color 4</option>
                                                                                            <option value="color5">Color 5</option>
                                                                                            <option value="color6">Color 6</option>
                                                                                            <option value="color7">Color 7</option>
                                                                                        </select>
                                                                                        {errors &&
                                                                                            errors.botones &&
                                                                                            errors.botones[index] &&
                                                                                            errors.botones[index].estilo &&
                                                                                            (<div className="text-danger">{errors.botones[index].estilo}</div>)}
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>

                                                                            <Row>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                )
                                                            }

                                                            )}
                                                        <button type="button" className="btn btn-secondary" onClick={() => push({ nombre: "", url: "#", estilo: '' })}>Añadir Item</button>
                                                    </>
                                                )
                                            }
                                            }
                                        </FieldArray>
                                        <Row>
                                            <PromocionesPlanes key={promosPlan.length}  promosPlan={promosPlan} setPromosPlan={setPromosPlan}/>
                                        </Row>
                                        <Row className="mt-5">
                                            <Col className="col col-lg-2">
                                                <Button type="button" onClick={() => handleSubmit()} disabled={isLoading} className="btn btn-success btn-icon" ><i className="fa fa-floppy-o" />Publicar</Button>
                                            </Col>
                                            <Col className="col col-lg-3">
                                                <Button type="button" onClick={() => onCancelar()} disabled={isLoading} className="btn btn-primary btn-icon" ><i className="fa fa-undo " />Por revisar</Button>
                                            </Col>
                                        </Row>

                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>


                    </form>

                )}
            </Formik>

        </React.Fragment>
    );
}
export default VerPlanX;