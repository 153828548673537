import React from 'react'

import {Helmet} from "react-helmet";
import Topbar from '../../components/topbar';
import MainSidebar from '../../components/main-sidebar';
import Footer from '../../components/footer';
import PageHeader from '../../components/page-header';
// import Customizer from '../../customizer';
import LoadingOverlay from 'react-loading-overlay';
import { useSelector } from 'react-redux';
export default function BackendLayout ({children}) {
    const loading = useSelector(state => state.loading)
    return (
        <React.Fragment>
            
            <Helmet>
                <title>CMS - Plan de Salud</title>
                <style>
                </style>
            </Helmet>
            <Topbar />
            <MainSidebar />

            <PageHeader />
            <div className="main-content">
            <>
                <LoadingOverlay active={loading} spinner text='Cargando...'>{children}</LoadingOverlay>
            </>
            <Footer />
            </div>
            {/* <Customizer />  */}
        </React.Fragment>
    )
}

