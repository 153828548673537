import axios from 'axios'
import { AlertError, AlertInfo } from '../components/alert'
import { BASE_URL, GATEWAY_URL, GATEWAY_APIKEY } from './constants'

export const Fetch = async (url, method, data, options = { ContentType: 'application/json' }) => {
  const token = localStorage.getItem('jwt_token')
  const URL = `${BASE_URL}/${url}`;
  const HEADERS = {
    'Content-Type': options.ContentType
  };
  if (token) {
    HEADERS.Authorization = `Bearer ${token}`
  }

  return axios({
    url: URL,
    method,
    headers: HEADERS,
    data
  })
}



export const catchError = (error) => {
  console.error('[Error]:', error)
  if (error.response) {
    console.error('[Error Response]:', error.response.data)
    console.error('[Error Response]:', error.response.status)
    if (error.response.status === 403) {
      AlertInfo('No tiene permisos suficientes')
      return
    }
    if (error.response.status === 404) {
      AlertError('No se encontró lo que buscaba')
      return
    }
    console.error('[Error Response]:', error.response.headers)
    AlertError('se ha presentado un error' + JSON.stringify(error.response.data))
    return
  } else if (error.request) {
    console.error('[Error Request]:', error.request)
    console.log(error.request);
    AlertError('se ha presentado un error')

  } else {
    console.log('[Error Message]:', error.message);
    AlertError(`Error ${error.message}`)

  }
}


