import React, { useMemo } from "react";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import JoditEditor, { Jodit } from "jodit-react";
import { useFormik } from "formik";
import { Fetch, catchError } from "../../../utils/fetch";
import { useDispatch, useSelector } from "react-redux";
import MediaUploader from "../../../utils/media-uploader";
import { AlertInfo, AlertSuccess } from "../../../components/alert";
import { loadingAction } from "../../../redux/actions";
import { validate } from "../validationSchema";
import { useHistory } from "react-router-dom";
import { EstadoContenido } from "../../../utils/constants";
import { eliminarImagen, removeSpecial } from "../../../utils/tools";
import LayoutJodit from "../../../components/customizer/layout-jodit";

const NuevaNovedad = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.loading);
  const permissions = useSelector((state) => state.permissions);
  const [layoutJodit, setLayoutJodit] = React.useState("PC");
  //const canPublish = permissions.novedades.publicar.enabled;

  // function preparePaste(jodit) {
  //     jodit.e.on(
  //         'paste',
  //         e => {

  //             jodit.e.stopPropagation('paste');
  //             let plainText = Jodit.modules.Helpers.getDataTransfer(e).getData(Jodit.constants.TEXT_PLAIN);plainText=removeSpecial(plainText);
  //             // plainText = plainText.replace(/(^|;)\s*font-[^;]+/g, "");
  //             // let regex = /<(?!(\/\s*)?(div|b|i|em|strong|u|p)[>,\s])([^>])*>/g;
  //             // plainText = plainText.replace(regex, '');
  //             plainText = plainText.normalize('NFKC').replaceAll("\n","<br/>");

  //             jodit.s.insertHTML(
  //                 plainText
  //             );
  //             return false;

  //         },
  //         { top: true }
  //     );
  // }
  // Jodit.plugins.add('preparePaste', preparePaste);
  const config = useMemo(() => {
    return {
      readonly: false, // all options from https://xdsoft.net/jodit/doc/
      height: 500,
      language: "es",
      // disablePlugins: ['table'],
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      defaultActionOnPaste: "insert_clear_html",
      enableDragAndDropFileToEditor: true,
      uploader: {
        url: process.env.REACT_APP_BASE_URL + "/upload",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwt_token"),
        },
        data: null,
        filesVariableName: function (i) {
          return "files";
        },
        withCredentials: false,
        pathVariableName: "path",
        format: "json",
        method: "POST",
        prepareData: function (formData) {
          formData.append("id", 1);
        },
        isSuccess: function (resp) {
          return !resp.error;
        },
        getMessage: function (resp) {
          return resp.msgs.join("\n");
        },
        process: function (resp) {
          return resp;
        },
        defaultHandlerSuccess: function (resp) {
          var imagenesPermitidas = ["image/jpeg", "image/png"];

          for (var i = 0; i < resp.length; i++) {
            if (imagenesPermitidas.indexOf(resp[i].mime) !== -1) {
              var img1 = new Image();
              img1.src = resp[i]["url"];
              img1.alt = "imagen";
              img1.className = "img-fluid";
              this.s.insertImage(img1);
            } else if (resp[i].mime === "application/pdf") {
              const enlace = document.createElement("a");
              enlace.setAttribute("href", resp[i].url);
              const contenido = document.createTextNode(resp[i].name);
              enlace.appendChild(contenido);
              this.s.insertNode(enlace);
            } else {
              AlertInfo("Tipo de archivo no permitido");
            }
          }
        },
      },
    };
  }, []);

  const submit = async (values) => {
    dispatch(loadingAction(true));

    values.published_at = null;
    values.estado = EstadoContenido.EnRevision;

    values.descripcion = removeSpecial(values.descripcion);

    try {
      const { data } = await Fetch("novedades", "post", values);
      dispatch(loadingAction(false));

      AlertInfo(
        "Novedad creada con éxito.<br/>Recuerde que falta su confirmación para aprobar.<br/> Nota: Por favor, notificar al responsable de publicar"
      );
      history.push("/novedades/lista-borradores");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: {
      titulo: "",
      descripcionCorta: "",
      descripcion: "",
      imagen: "",
      destacado: false,
      slug: "",
      orden: 0,
      habilitado: true,
      locale: "es",
    },
    enableReinitialize: true,
    validationSchema: validate,
    onSubmit: (values) => submit(values),
  });

  const handleBlurTitulo = async (event) => {
    const value = event.target.value;
    const name = event.target.name;
    const dataToApi = {
      contentTypeUID: "application::novedades.novedades",
      field: "slug",
      data: {
        titulo: value,
        slug: "",
      },
    };
    setFieldValue(name, value, false);
    try {
      const { data } = await Fetch(
        "content-manager/uid/generate",
        "post",
        dataToApi
      );
      setFieldValue("slug", data.data, false);
    } catch (error) {
      console.error("mostrando error", error);
    }
  };

  const handleChangeJoiEditor = (data, field) => {
    setFieldValue(field, data, false);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Card>
              <Card.Header>Nueva novedad</Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>Título de la novedad</label>
                      <input
                        type="text"
                        name="titulo"
                        onChange={handleChange}
                        onBlur={(e) => handleBlurTitulo(e)}
                        value={values.titulo}
                        className="form-control "
                      />
                      {errors && errors?.titulo && (
                        <div className="text-danger">{errors?.titulo} </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>Url</label>
                      <input
                        type="text"
                        name="slug"
                        className="form-control"
                        value={values.slug}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {values?.imagen && Object.keys(values.imagen).length > 0 && (
                  <>
                    <Row>
                      <Col>
                        <Form.Group>
                          <img
                            src={values.imagen.url}
                            alt="vista-previa"
                            className="img-fluid w-50"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          type="button"
                          style={{ marginBottom: "10px" }}
                          onClick={() => {
                            eliminarImagen(values.imagen.id)
                              .then((response) => {
                                setFieldValue("imagen", "", false);
                              })
                              .catch((error) => {});
                          }}
                          className="btn btn-danger btn-icon mt-2"
                        >
                          <i className="fa fa-trash-o " />
                          Eliminar
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}

                <Row>
                  <Col>
                    <Form.Group>
                      <label>Imagen</label>
                      <MediaUploader
                        setFieldValue={setFieldValue}
                        field={"imagen"}
                        tipoMedia="imagen"
                        ancho={600}
                        alto={250}
                      />
                      {errors && errors?.imagen && (
                        <div className="text-danger">{errors?.imagen} </div>
                      )}
                      <span className="text-muted">
                        Se recomienda una imagen de 600px de ancho x 250px de
                        alto. En formato JPG o PNG.{" "}
                      </span>
                    </Form.Group>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col>
                    <h3>Información del contenido</h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>Destacado</label>
                      <select
                        className="form-control"
                        name="destacado"
                        value={values.destacado}
                        onChange={handleChange}
                      >
                        <option>Seleccionar</option>
                        <option value={true}>Si</option>
                        <option value={false}>No</option>
                      </select>
                      {errors && errors?.destacado && (
                        <div className="text-danger">{errors?.destacado} </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>Descripción Corta</label>
                      <textarea
                        name="descripcionCorta"
                        onChange={handleChange}
                        className="form-control"
                        value={values.descripcionCorta}
                      ></textarea>
                      {errors && errors?.descripcionCorta && (
                        <div className="text-danger">
                          {errors?.descripcionCorta}{" "}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className={`${layoutJodit == "MOBILE" && "col-6"}`}>
                    <Form.Group>
                      <label>Descripción</label>
                      <LayoutJodit
                        setLayoutJodit={setLayoutJodit}
                        layoutJodit={layoutJodit}
                      />
                      <JoditEditor
                        config={config}
                        value={values.descripcion}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={(newContent) =>
                          handleChangeJoiEditor(newContent, "descripcion")
                        }
                      />
                      {errors && errors?.descripcion && (
                        <div className="text-danger">
                          {errors?.descripcion}{" "}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Button
                  type="button"
                  onClick={() => handleSubmit()}
                  disabled={isLoading}
                  className="btn btn-success btn-icon mt-2"
                >
                  <i className="fa fa-floppy-o " />
                  Guardar
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};
export default NuevaNovedad;
