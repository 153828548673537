export const getStore = async (key) => {
    try {
      const store = localStorage.getItem(key);
      return store ? JSON.parse(store) : undefined
    } catch (e) {
      return undefined
    }
  }
  
  export const setStore = async (key, value) => {
    try {
      const store = JSON.stringify(value)
      await localStorage.setItem(key, store)
    } catch (e) {
      // saving error
    }
  }
  
  export const clearStore = async () => {
    try {
      await localStorage.clear()
      
    } catch (e) {
      // saving error
    }
  }