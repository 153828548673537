/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link,useHistory } from 'react-router-dom';
import DataGridForm from '../../../../components/common/DataGridForm';
import { loadingAction } from '../../../../redux/actions';
import { catchError, Fetch } from '../../../../utils/fetch';
import { formatDate } from '../../../../utils/formatDate';
import { AlertConfirm } from '../../../../components/alert';
import { EstadoContenido } from '../../../../utils/constants';

function ListarBorradorHomeBotones({ isLoadingAction, isLoading }) {
    const [contenido, setContenido] = React.useState(null)
    const history = useHistory();

    React.useEffect(() => {
        getContenido()
    }, [])

    const getContenido = async () => {
        isLoadingAction(true)
        try {
            const { data } = await Fetch('botones-homes?_publicationState=preview&published_at_null=true&estado=ER', 'get')
            setContenido(data)
            isLoadingAction(false)
        } catch (error) {
            console.error('mostrando error', error)
            isLoadingAction(false)
            catchError(error);
        }
    }

    function FActualizacion(data) {
        return formatDate(data?.updated_at)
    }

    const deleteContenido = async (values) => {
        isLoadingAction(true)
        try {
            values.eliminado=true;
            values.estado=EstadoContenido.EnRevision;   
            values.published_at=new Date();              
            delete values.idPadre;
            await Fetch('botones-homes/'+values.id, 'put', values)
            getContenido()
            isLoadingAction(false)
            history.push("/configuracion/botones-home/lista");
        } catch (error) {
            console.error('mostrando error', error)
            isLoadingAction(false)
            catchError(error);
        }
    }
	
	 function DeleteAlert(title, data) {
        AlertConfirm(title, 'question', (response) => {
            if (response.isConfirmed) {
                deleteContenido(data)
            }
        })
    }

    function Accion(data) {
        return (
            <>
                <Link to={"/configuracion/botones-home/ver-borrador/" + data.id} className="btn btn-sm btn btn-success"><i className="fa fa-pencil" /></Link>{' '}            
                <button type="button" className="btn btn-sm btn btn-danger" onClick={() => { DeleteAlert("Está apunto de eliminar este contenido", data) }} ><i className="fa fa-trash" /></button>{' '}
            </>
        )
    }


    const creacion=(data)=>formatDate(data?.created_at);



    return (
        <React.Fragment>
            <Row>
                <Col md="12">
                    <Card>
                        <Card.Header>
                        Listado de botones home en revisión
                        </Card.Header>
                        <Card.Body>

                            <div className='table-responsive'>
                                <DataGridForm dataSource={contenido}
                                    keyExpr='id'
                                    columns={[
                                        { caption: 'Id', dataField: 'id' },
                                        { caption: 'Nombre', dataField: 'botones.nombre' },
                                        { caption: 'Creado por', dataField: 'creado_por.username' },
                                        { caption: 'Creación',render:"creacion", cellRender:{creacion}},
                                        { caption: 'Actualizado por', dataField: 'actualizado_por.username' },
                                        { caption: 'Ult. Actualización', render: 'FActualizacion', cellRender: { FActualizacion } },
                                        { caption: 'Acción', alignment: 'center', render: 'Accion', cellRender: { Accion }, hidingPriority: 0 }
                                    ]} />
                            </div>
                           
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {

    return {
        isLoading: state.loading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        isLoadingAction: (data) => { dispatch(loadingAction(data)) }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ListarBorradorHomeBotones);