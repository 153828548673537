import React, { useMemo } from "react";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import JoditEditor, { Jodit } from "jodit-react";
import { useFormik } from "formik";
import { Fetch, catchError } from "../../../utils/fetch";
import { useDispatch, useSelector } from "react-redux";
import ImageUploader from "../../../utils/image-uploader";
import { AlertInfo, AlertSuccess } from "../../../components/alert";
import { loadingAction } from "../../../redux/actions";
import MediaUploader from "../../../utils/media-uploader";
import { validateModel } from "./validationSchema";
import { useHistory } from "react-router-dom";
import { BASE_URL, EstadoContenido } from "../../../utils/constants";
import { Label } from "devextreme-react/data-grid";
import "./style.css";

const NuevaPopup = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.data);
  const isLoading = useSelector((state) => state.loading);
  const permissions = useSelector((state) => state.permissions);
  const canPublish = permissions.popup.publicar.enabled;
  const history = useHistory();
  const [textoRecomienda, setTextoRecomienda] = React.useState(
    "Se recomienda una imagen de 1400px de ancho x 960px de alto. En formato JPG o PNG."
  );
  const [alto, setAlto] = React.useState(300);

  const submit = async (values) => {
    // values.creado_por = user.id;
    // values.actualizado_por = user;
    dispatch(loadingAction(true));

    values.published_at = null;
    values.estado = EstadoContenido.EnRevision;

    try {
      if (
        values.fecha_inicio === "" ||
        values.fecha_fin === "" ||
        !values.programacion
      ) {
        delete values.fecha_inicio;
        delete values.fecha_fin;
      }

      await Fetch("popups", "post", values);

      AlertInfo(
        "Popup creado con éxito.<br/>Recuerde que falta su confirmacion para aprobar.<br/> Nota: Por favor, notificar al responsable de publicar"
      );
      history.push("/popup/lista-borradores");

      dispatch(loadingAction(false));
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      nombre: "",
      imagen: {},
      programacion: false,
      enlace: "",
      enlace_interno: false,
      fecha_inicio: "",
      fecha_fin: "",
      created_by: user.id,
      updated_by: user,
    },
    enableReinitialize: true,
    validationSchema: validateModel,
    validate: ({ fecha_inicio, fecha_fin, programacion }) => {
      const errors = {};
      if (programacion && (fecha_inicio === "" || fecha_fin === "")) {
        errors.programacion = "Fechas y horas invalidas";
      }

      if (programacion) {
        let f_inicio = new Date(fecha_inicio);
        let f_fin = new Date(fecha_fin);

        if (isNaN(f_inicio) || isNaN(f_fin)) {
          errors.programacion = "Fechas inválidas";
        }

        if (fecha_inicio > fecha_fin) {
          errors.programacion =
            "La fecha final no puede ser anterior a la fecha de inicio";
        }
      }

      return errors;
    },
    onSubmit: (values) =>{          
      submit(values)
    },
  });

  const cambiarFecha = (item, e) => {        


    switch (item) {
      case "fecha_inicio":
        setFieldValue("fecha_inicio", `${e.target.value} 00:00:00`);
        break;
      case "hora_inicio":        
        setFieldValue(
          "fecha_inicio",
          `${values.fecha_inicio.split(" ")[0]} ${e.target.value}`
        );
        break;
      case "fecha_fin":
        setFieldValue("fecha_fin", `${e.target.value} 00:00:00`);
        break;
      case "hora_fin":
        setFieldValue(
          "fecha_fin",
          `${values.fecha_fin.split(" ")[0]} ${e.target.value}`
        );
        break;
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Row>
                  <Col>Nuevo Pop up</Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>Nombre Pop up</label>
                      <input
                        type="text"
                        name="nombre"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.nombre}
                        className="form-control "
                      />
                      {touched?.nombre && (
                        <div className="text-danger">{errors?.nombre}</div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                {Object.keys(values.imagen).length > 0 && (
                  <Row>
                    <Col>
                      <Form.Group>
                        <img
                          src={values.imagen.url}
                          alt="vista-previa"
                          className="img-fluid w-50"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col>
                    <Form.Group>
                      <label>Imagen</label>
                      <MediaUploader
                        setFieldValue={setFieldValue}
                        field={"imagen"}
                        tipoMedia="imagen"
                        ancho={600}
                        alto={alto}
                      />
                      {touched?.imagen && (
                        <div className="text-danger">{errors?.imagen}</div>
                      )}
                      <span className="text-muted" id="texto-recomienda">
                        {textoRecomienda}
                      </span>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group>
                      <label>
                        <input
                          type="checkbox"
                          value={values.programacion}
                          name="programacion"
                          onChange={handleChange}
                        />
                        <span style={{ marginLeft: "5px" }}>Programación</span>
                      </label>
                    </Form.Group>
                  </Col>
                </Row>
                {values.programacion && (
                  <>
                    <Row>
                      <Col id="columna-fecha" xs={12} md={9} lg={9} xl={7}>
                        <div>
                          <label>Inicio</label>
                          <input
                            type="date"
                            className="fecha-personalizada"
                            onChange={(e) => cambiarFecha("fecha_inicio", e)}
                            name="fecha_inicio"
                            value={values.fecha_inicio?.split(" ")[0]}
                          />
                          <input
                            type="time"
                            className="fecha-personalizada"
                            onChange={(e) => cambiarFecha("hora_inicio", e)}
                            value={values.fecha_inicio?.split(" ")[1]}
                          />
                        </div>
                        <div>
                          <label>Fin</label>
                          <input
                            type="date"
                            className="fecha-personalizada"
                            onChange={(e) => cambiarFecha("fecha_fin", e)}
                            name="fecha_fin"
                            value={values.fecha_fin?.split(" ")[0]}
                          />
                          <input
                            type="time"
                            className="fecha-personalizada"
                            onChange={(e) => cambiarFecha("hora_fin", e)}
                            value={values.fecha_fin?.split(" ")[1]}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "10px", marginBottom: "30px" }}>
                      <Col>
                        <span className="text-muted">
                          Seleccione una fecha y hora en el futuro, de inicio y
                          fin de visualización del pop up.
                        </span>
                        {touched.programacion && (
                          <div className="text-danger">
                            {errors?.programacion}
                          </div>
                        )}
                      </Col>
                    </Row>{" "}
                  </>
                )}

                <Row>
                  <Col>
                    <Form.Group>
                      <label>Enlace</label>
                      <input
                        type="text"
                        name="enlace"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.enlace}
                        className="form-control "
                      />
                      {touched.enlace && (
                        <div className="text-danger">{errors?.enlace}</div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group>
                      <label>¿El enlace es interno?</label>
                      <select
                        className="form-control"
                        name="enlace_interno"
                        onChange={handleChange}
                        value={values.enlace_interno}
                      >
                        <option value={true}>Si</option>
                        <option value={false}>No</option>
                      </select>
                      {touched.interno && (
                        <div className="text-danger">{errors?.interno}</div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Button
                  type="button"
                  onClick={() => handleSubmit()}
                  disabled={isLoading}
                  className="btn btn-success btn-icon mt-2"
                >
                  <i className="fa fa-floppy-o " />
                  Guardar
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};
export default NuevaPopup;
