import React from 'react';
import { Card, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link,useHistory } from 'react-router-dom';
import DataGridForm from '../../../components/common/DataGridForm';
import { loadingAction } from '../../../redux/actions';
import { catchError, Fetch } from '../../../utils/fetch';
import { formatDate } from '../../../utils/formatDate';
import { copyToClickBoard } from '../../../utils/slug';
import { AlertConfirm } from '../../../components/alert';
import { EstadoContenido } from '../../../utils/constants';
function ListaBorradorNovedades({ isLoadingAction, isLoading }) {
    const [contenido, setContenido] = React.useState(null)
    const history = useHistory();

    React.useEffect(() => {
        getContenido()

    }, [])

    const getContenido = async () => {
        isLoadingAction(true)
        try {
            const { data } = await Fetch('novedades?_publicationState=preview&published_at_null=true&estado=ER', 'get')
            setContenido(data)
            isLoadingAction(false)
        } catch (error) {
            console.error('mostrando error', error)
            isLoadingAction(false)
            catchError(error);
        }
    }

    function FActualizacion(data) {
        return formatDate(data?.updated_at)
    }

    function Imagen(data) {
        return (
            data?.imagen?.formats?.thumbnail?.url ? <><img src={data.imagen.formats.thumbnail.url} style={{ width: '64px', height: '64px', borderRadius: '32px', objectFit: 'cover' }} className="" alt="images" /></> : null
        )
    }

    function Destacado(data) {
        return (
            data.destacado ? <h4> <span className="badge bg-success">Si</span> </h4> : <span className="badge bg-secondary">No</span>
        )
    }

    const deleteContenido = async (values) => {
        isLoadingAction(true)
        try {
            values.eliminado=true;
            values.estado=EstadoContenido.EnRevision;   
            values.published_at=new Date();              
            delete values.idPadre;
            await Fetch('novedades/'+values.id, 'put', values)
            getContenido()
            isLoadingAction(false)
            history.push("/novedades/lista-por-eliminar");
        } catch (error) {
            console.error('mostrando error', error)
            isLoadingAction(false)
            catchError(error);
        }
    }
	
	 function DeleteAlert(title, data) {
        AlertConfirm(title, 'Para eliminarlo definitvamente o recuperarlo deberás ir a la sección "Por eliminar".','question', (response) => {
            if (response.isConfirmed) {
                deleteContenido(data)
            }
        })
    }

    function Accion(data) {
        return (
            <>
                <a href={`${process.env.REACT_APP_BASE_URL_HOME}/novedades-preview/${data.id}`} target="_blank" className="btn btn-sm btn btn-info"><i className="fa fa-eye" /></a>{' '}
                <Link to={"/novedades/ver-borrador/" + data.id} className="btn btn-sm btn btn-success"><i className="fa fa-pencil" /></Link>{' '}
                <button type="button" className="btn btn-sm btn btn-danger" onClick={() => { DeleteAlert("Está apunto de eliminar este contenido", data) }} ><i className="fa fa-trash" /></button>{' '}
            </>
        )
    }

    function Slug(data) {
        return (
            <>
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-top`}>
                            Hacer click para copiar url
                        </Tooltip>
                    }
                >
                    <i className='fa fa-copy fa-lg text-info' role="button"
                        onClick={() => copyToClickBoard('novedades/' + data.slug)}
                    ></i>
                </OverlayTrigger>
            </>
        )
    }

    const creacion=(data)=>formatDate(data?.created_at);
    
    return (
        <React.Fragment>
            <Row>
                <Col md="12">
                    <Card>
                        <Card.Header>
                            <div className="float-right mt-10">
                                <Link to="/novedades/nuevo" className="btn btn-primary btn-rounded box-shadow btn-icon"><i className="fa fa-plus" /> Nueva Novedad</Link>
                            </div>
                            Listado de novedades en revisión
                        </Card.Header>
                        <Card.Body>

                            <div className='table-responsive'>

                                <DataGridForm dataSource={contenido}
                                    keyExpr='id'
                                    columns={[
                                        { caption: 'Id', dataField: 'id' },
                                        { caption: 'Imagen', alignment: 'center', render: 'Imagen', cellRender: { Imagen } },
                                        { caption: 'Título', dataField: 'titulo' },
                                        { caption: 'Destacado', alignment: 'center', render: 'Destacado', cellRender: { Destacado } },
                                        { caption: 'Url', alignment: 'center', width: 50, render: 'Slug', cellRender: { Slug } },
                                        { caption: 'Creado por', dataField: 'creado_por.username' },
                                        { caption: 'Creación',render:"creacion", cellRender:{creacion}},
                                        { caption: 'Actualizado por', dataField: 'actualizado_por.username' },
                                        { caption: 'Ult. Actualización', render: 'FActualizacion', cellRender: { FActualizacion } },
                                        { caption: 'Acción', alignment: 'center', render: 'Accion', cellRender: { Accion }, hidingPriority: 0 }
                                    ]} />

                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {

    return {
        isLoading: state.loading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        isLoadingAction: (data) => { dispatch(loadingAction(data)) }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ListaBorradorNovedades);