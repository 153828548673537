/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import JoditEditor, { Jodit } from "jodit-react";
import { useFormik } from "formik";
import { Fetch, catchError } from "../../../utils/fetch";
import { useDispatch, useSelector } from "react-redux";
import ImageUploader from "../../../utils/image-uploader";
import { AlertSuccess, AlertInfo } from "../../../components/alert";
import { loadingAction } from "../../../redux/actions";
import { useHistory, useParams } from "react-router-dom";
import MediaUploader from "../../../utils/media-uploader";
import { EstadoContenido } from "../../../utils/constants";
import { validateModel } from "../nuevo/validationSchema";
import { formatDate,simpleFormatDate } from "../../../utils/formatDate";

const VerPopupX = () => {
  const params = useParams();
  const id = params.id;
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.loading);
  const permissions = useSelector((state) => state.permissions);
  const canPublish = permissions.popup.publicar.enabled;
  const [alto, setAlto] = React.useState(300);
  const [textoRecomienda, setTextoRecomienda] = React.useState(
    "Se recomienda una imagen de 1400px de ancho x 960px de alto. En formato JPG o PNG."
  );
  const user = useSelector((state) => state.user.data);

  React.useEffect(() => {
    if (id) {
      getPopupById();
    }
  }, []);

  const getPopupById = async (values) => {
    // ?_publicationState=preview&published_at_null=true
    dispatch(loadingAction(true));
    try {
      const { data } = await Fetch(
        `popups/${id}?_publicationState=preview&published_at_null=true`,
        "get"
      );
      data.fecha_inicio = data.fecha_inicio?simpleFormatDate(data.fecha_inicio) + ":00":null;
      data.fecha_fin = data.fecha_fin?simpleFormatDate(data.fecha_fin) + ":00":null;
      setValues(data);
      dispatch(loadingAction(false));
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const submit = async (values) => {
    values.published_at = new Date();
    values.estado = EstadoContenido.Publicado;

    dispatch(loadingAction(true));
    try {
      const { data } = await Fetch(
        "popups/aprobar/" + (values.idPadre === null ? id : values.idPadre),
        "put",
        values
      );

      AlertSuccess("Pop up actualizado con éxito");

      dispatch(loadingAction(false));

      history.push("/popup/lista");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const validar = ({ programacion, fecha_inicio, fecha_fin }) => {
    const errors = {};
    if (programacion && (fecha_inicio === "" || fecha_fin === "")) {
      errors.programacion = "Fechas y horas invalidas";
    }

    if (programacion) {
      let now = new Date();
      let f_inicio = new Date(fecha_inicio);
      let f_fin = new Date(fecha_fin);

      if (isNaN(f_inicio) || isNaN(f_fin)) {
        errors.programacion = "Fechas inválidas";
      }

      if (fecha_inicio > fecha_fin) {
        errors.programacion =
          "La fecha final no puede ser anterior a la fecha de inicio";
      }

      if (f_inicio < now) {
        errors.programacion =
          "La fecha de inicio no puede ser menor a la fecha actual";
      } else if (f_fin < now) {
        errors.programacion =
          "La fecha de fin no puede ser menor a la fecha actual";
      }
    }

    return errors;
  };

  const onCancelar = async () => {
    const errores = validar(values);
    setErrors(errores);
    if (Object.keys(errores).length > 0) return;

    dispatch(loadingAction(true));

    const params = {
      published_at: null,
      estado: EstadoContenido.EnRevision,
    };

    try {
      const { data } = await Fetch("popups/" + id, "put", params);

      dispatch(loadingAction(false));
      AlertSuccess("Se ha cancelado la publicación del popup con éxito");

      history.push("/popup/lista-borradores");
    } catch (error) {
      dispatch(loadingAction(false));
      catchError(error);
    }
  };

  const cambiarFecha = (item, e) => {
    switch (item) {
      case "fecha_inicio":
        setFieldValue("fecha_inicio", `${e.target.value} 00:00:00`);
        break;
      case "hora_inicio":
        setFieldValue(
          "fecha_inicio",
          `${values.fecha_inicio.split(" ")[0]} ${e.target.value}`
        );
        break;
      case "fecha_fin":
        setFieldValue("fecha_fin", `${e.target.value} 00:00:00`);
        break;
      case "hora_fin":
        setFieldValue(
          "fecha_fin",
          `${values.fecha_fin.split(" ")[0]} ${e.target.value}`
        );
        break;
    }
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    errors,
    touched,
    setValues,
    setErrors,
  } = useFormik({
    initialValues: {
      nombre: "",
      imagen: "",
      programacion: false,
      enlace: "",
      enlace_interno: false,
      fecha_inicio: "",
      fecha_fin: "",
      created_by: user.id,
      updated_by: user,
    },
    enableReinitialize: true,
    validationSchema: validateModel,
    validate: (valores) => {
      return validar(valores);
    },
    onSubmit: (values) => submit(values),
  });

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Row>
                  <Col>Nuevo Pop up</Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>Nombre Pop up</label>
                      <input
                        type="text"
                        name="nombre"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.nombre}
                        className="form-control "
                      />
                      {touched?.nombre && (
                        <div className="text-danger">{errors?.nombre}</div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                {Object.keys(values.imagen).length > 0 && (
                  <Row>
                    <Col>
                      <Form.Group>
                        <img
                          src={values.imagen.url}
                          alt="vista-previa"
                          className="img-fluid w-50"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col>
                    <Form.Group>
                      <label>Imagen</label>
                      <MediaUploader
                        setFieldValue={setFieldValue}
                        field={"imagen"}
                        tipoMedia="imagen"
                        ancho={600}
                        alto={alto}
                      />
                      {touched?.imagen && (
                        <div className="text-danger">{errors?.imagen}</div>
                      )}
                      <span className="text-muted" id="texto-recomienda">
                        {textoRecomienda}
                      </span>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group>
                      <label>
                        <input
                          type="checkbox"
                          checked={values.programacion}
                          name="programacion"
                          onChange={handleChange}
                        />
                        <span style={{ marginLeft: "5px" }}>Programación</span>
                      </label>
                    </Form.Group>
                  </Col>
                </Row>
                {values.programacion && (
                  <>
                    <Row>
                      <Col id="columna-fecha" xs={12} md={9} lg={9} xl={8}>
                        <div>
                          <label>Inicio</label>
                          <input
                            type="date"
                            className="fecha-personalizada"
                            onChange={(e) => cambiarFecha("fecha_inicio", e)}
                            name="fecha_inicio"
                            defaultValue={values.fecha_inicio?.split(" ")[0]}
                          />
                          <input
                            type="time"
                            className="fecha-personalizada"
                            onChange={(e) => cambiarFecha("hora_inicio", e)}
                            defaultValue={values.fecha_inicio?.split(" ")[1]}
                          />
                        </div>
                        <div>
                          <label>Fin</label>
                          <input
                            type="date"
                            className="fecha-personalizada"
                            onChange={(e) => cambiarFecha("fecha_fin", e)}
                            name="fecha_fin"
                            defaultValue={values.fecha_fin?.split(" ")[0]}
                          />
                          <input
                            type="time"
                            className="fecha-personalizada"
                            onChange={(e) => cambiarFecha("hora_fin", e)}
                            defaultValue={values.fecha_fin?.split(" ")[1]}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "10px", marginBottom: "30px" }}>
                      <Col>
                        <span className="text-muted">
                          Seleccione una fecha y hora en el futuro, de inicio y
                          fin de visualización del pop up.
                        </span>
                        <div className="text-danger">
                          {errors?.programacion}
                        </div>
                      </Col>
                    </Row>{" "}
                  </>
                )}

                <Row>
                  <Col>
                    <Form.Group>
                      <label>Enlace</label>
                      <input
                        type="text"
                        name="enlace"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.enlace}
                        className="form-control "
                      />
                      {touched.enlace && (
                        <div className="text-danger">{errors?.enlace}</div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group>
                      <label>¿El enlace es interno?</label>
                      <select
                        className="form-control"
                        name="enlace_interno"
                        onChange={handleChange}
                        value={values.enlace_interno}
                      >
                        <option value={true}>Si</option>
                        <option value={false}>No</option>
                      </select>
                      {touched.interno && (
                        <div className="text-danger">{errors?.interno}</div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mt-5">
                  <Col className="col col-lg-2">
                    <Button
                      type="button"
                      onClick={() => handleSubmit()}
                      disabled={isLoading}
                      className="btn btn-success btn-icon"
                    >
                      <i className="fa fa-floppy-o" />
                      Publicar
                    </Button>
                  </Col>
                  <Col className="col col-lg-3">
                    <Button
                      type="button"
                      onClick={() => onCancelar()}
                      disabled={isLoading}
                      className="btn btn-primary btn-icon"
                    >
                      <i className="fa fa-undo " />
                      Por revisar
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};
export default VerPopupX;
