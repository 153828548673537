/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import {Fetch, catchError} from '../../../utils/fetch'
import { useParams } from "react-router-dom";
import { connect } from 'react-redux'
import {loadingAction} from '../../../redux/actions';
//Template1Component
import Template1Component from '../../../components/templates/template1';
//Template2Component
import Template2Component from '../../../components/templates/template2';
//Template3Component
import Template3Component from '../../../components/templates/template3';
import Template4Component from '../../../components/templates/template4';


function VerPaginaTemplateX({user, token, isLoadingAction}) {
    const params = useParams();
    
    const { template, id } = params;
    const [dataResult, setDataResult] = React.useState(null);
    
    React.useEffect(()=>{
        if(id){
            getPaginasById(id)
        }
    },[id])
    
    const getPaginasById = async (id) => {
        isLoadingAction(true)
        try {
            const {data}  = await Fetch('paginas/'+id+'?_publicationState=preview&published_at_null=true', 'get');
            isLoadingAction(false)
            setDataResult(data)
        } catch (error) {
            isLoadingAction(false)
            catchError(error);
            console.error(error.message);
        }
    }


    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Card>
                        <Card.Header>
                        Información de la página
                        </Card.Header>
                        <Card.Body>
                            {template == 'template1' && <Template1Component dataResult={dataResult} accion="publicar" />}
                            {template == 'template2' && <Template2Component dataResult={dataResult} accion="publicar" />}
                            {template == 'template3' && <Template3Component dataResult={dataResult} accion="publicar" />}
                            {template == 'template4' && <Template4Component dataResult={dataResult} accion="publicar" />}
                            
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </React.Fragment>
    )
}

const mapStateToProps = (state)=> {
    
    return {
      user : state.user.data,
      token : state.user.token,
      isLoading: state.loading
    }
}

const mapDispatchToProps= (dispatch) =>{
    return {
        isLoadingAction: (data) => {dispatch(loadingAction(data))}
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(VerPaginaTemplateX);