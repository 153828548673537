import React, { useRef, useMemo, useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import { Fetch, catchError } from '../../../utils/fetch'
import { useDispatch, useSelector } from 'react-redux';
import MediaUploader from '../../../utils/media-uploader'
import { AlertInfo, AlertSuccess } from '../../alert';
import { loadingAction } from '../../../redux/actions'
import { validate } from './validationSchema'
import { useHistory } from 'react-router-dom';
import { EstadoContenido } from '../../../utils/constants';
import JoditEditor, { Jodit } from 'jodit-react';
import { eliminarImagen, removeSpecial } from '../../../utils/tools';
import LayoutJodit from '../../customizer/layout-jodit';


const Template1Component = ({ dataResult, accion }) => {

    const dispatch = useDispatch()
    const history = useHistory();
    const isLoading = useSelector(state => state.loading)
    const permissions = useSelector(state => state.permissions)
    const [layoutJodit, setLayoutJodit] = useState("PC");
    const editorRef = useRef(null);

    React.useEffect(() => {

        if (dataResult) {
            if (dataResult.template1.imagen == undefined) {
                dataResult.template1.imagen = ''
            }
            setValues(dataResult)
        }
    }, [dataResult])

    const config = useMemo(
        () => ({
            readonly: false, // all options from https://xdsoft.net/jodit/doc/
            language: 'es', disablePlugins: ['table'],
            disabled: (accion === 'eliminar-publicado' ? true : false),
            askBeforePasteHTML: false,
            askBeforePasteFromWord: false,
            defaultActionOnPaste: "insert_clear_html",
            enableDragAndDropFileToEditor: true,
            uploader: {
                url: process.env.REACT_APP_BASE_URL + '/upload',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt_token')
                },
                data: null,
                filesVariableName: function (i) {
                    return "files"
                },
                withCredentials: false,
                pathVariableName: "path",
                format: "json",
                method: "POST",
                prepareData: function (formData) {
                    formData.append('id', 1);

                },
                isSuccess: function (resp) {
                    return !resp.error;
                },
                getMessage: function (resp) {
                    return resp.msgs.join('\n');
                },
                process: function (resp) {
                    return resp;
                },
                defaultHandlerSuccess: function (resp) {
                    var imagenesPermitidas = ["image/jpeg", "image/png"];

                    for (var i = 0; i < resp.length; i++) {
                        if (imagenesPermitidas.indexOf(resp[i].mime) !== -1) {
                            var img1 = new Image();
                            img1.src = resp[i]['url'];
                            img1.alt = 'imagen';
                            img1.className = "img-fluid"
                            this.s.insertImage(img1);
                        } else if (resp[i].mime === "application/pdf") {
                            const enlace = document.createElement('a');
                            enlace.setAttribute('href', resp[i].url);
                            const contenido = document.createTextNode(resp[i].name);
                            // add the text node to the newly created div
                            enlace.appendChild(contenido);
                            this.s.insertNode(enlace);
                        } else {
                            AlertInfo('Tipor de archivo no permitido');
                        }

                    }
                }
            }
        }), []);


    const onGrabarNuevo = async (values) => {
        dispatch(loadingAction(true));

        values.template = 'template1';
        values.published_at = null;
        values.estado = EstadoContenido.EnRevision

        try {
            const { data } = await Fetch("paginas", "post", values);
            dispatch(loadingAction(false));

            AlertInfo('Página creada con éxito.<br/>Recuerde que falta su confirmación para aprobar.<br/> Nota: Por favor, notificar al responsable de publicar')
            history.push("/pages/lista-borradores");

        } catch (error) {
            catchError(error)
            dispatch(loadingAction(false));
        }
    }

    const onActualizarBorrador = async (values) => {
        dispatch(loadingAction(true));

        values.published_at = null;
        values.estado = EstadoContenido.EnRevision

        try {
            const { data } = await Fetch("paginas/" + dataResult.id, "put", values);

            dispatch(loadingAction(false));

            AlertInfo('Página actualizada con éxito.<br/>Recuerde que falta su confirmación para aprobar.<br/> Nota: Por favor, notificar al responsable de publicar')
            history.push("/pages/lista-borradores");

        } catch (error) {
            catchError(error)
            dispatch(loadingAction(false));
        }
    }

    const onPublicar = async (values) => {
        dispatch(loadingAction(true));

        values.published_at = new Date()
        values.estado = EstadoContenido.Publicado

        delete values.template1.id

        try {
            const { data } = await Fetch("paginas/aprobar/" + (values.idPadre === null ? dataResult.id : values.idPadre), "put", values);
            dispatch(loadingAction(false));

            AlertSuccess('Página actualizada con éxito')

            history.push("/pages/lista");
        } catch (error) {
            catchError(error)
            dispatch(loadingAction(false));
        }
    }

    const onActualizarPublicado = async (values) => {

        dispatch(loadingAction(true));

        values.published_at = null;
        values.idPadre = values.id;
        values.estado = EstadoContenido.EnRevision

        try {

            const { data } = await Fetch("paginas", "post", values);
            AlertInfo('Página actualizada con éxito.<br/>Recuerde que falta su confirmación para aprobar.<br/> Nota: Por favor, notificar al responsable de publicar')

            dispatch(loadingAction(false));
            history.push("/pages/lista");
        } catch (error) {
            catchError(error)
            dispatch(loadingAction(false));
        }
    }

    const onEliminarPublicado = async (values) => {
        dispatch(loadingAction(true));

        try {
            const { data } = await Fetch("paginas/" + dataResult.id, "delete");

            dispatch(loadingAction(false));

            AlertSuccess('Página eliminada con éxito')
            history.push("/pages/lista-por-eliminar");

        } catch (error) {
            catchError(error)
            dispatch(loadingAction(false));
        }
    }

    const submit = async (values) => {
        values.template1.contenido = removeSpecial(values.template1.contenido);
        if (errors?.template1) {
            dispatch(loadingAction(true));
            let errorKeys = Object.keys(errors.template1)
            let errorToCatch = { message: "" }
            for (const error of errorKeys) {
                errorToCatch.message += `
              ${errors.template1[error]}: ${error}
              `
            }
            dispatch(loadingAction(false));
            catchError(errorToCatch);
        }else{
            switch (accion) {
                case 'nuevo':
                        await onGrabarNuevo(values)
                    break;
    
                case 'actualizar-borrador':
                    await onActualizarBorrador(values)
                    break;
    
                case 'actualizar-publicado':
                    await onActualizarPublicado(values)
                    break;
    
                case 'publicar':
                    await onPublicar(values)
                    break;
    
                case 'eliminar-publicado':
                    await onEliminarPublicado(values)
                    break;
                default:
                    break;
            }
        }
        
    }

    const onCancelar = async (values) => {
        dispatch(loadingAction(true));

        if (accion === 'eliminar-publicado') {
            const params = {
                eliminado: false
            }

            if (values.estado == EstadoContenido.EnRevision) {
                params.published_at = null;
            }


            const { data } = await Fetch("paginas/" + dataResult.id, "put", params);

            dispatch(loadingAction(false));
            AlertSuccess('Se ha cancelado la eliminación de la página con éxito')


            //history.push("/pages/lista-por-eliminar");
            if (values.estado === EstadoContenido.EnRevision) {
                history.push("/pages/lista-borradores");
            } else {
                history.push("/pages/lista");
            }
        } else {//por aprobación
            const params = {
                published_at: null,
                estado: EstadoContenido.EnRevision
            }

            if (values.estado == EstadoContenido.EnRevision) {
                params.published_at = null;
            }


            const { data } = await Fetch("paginas/" + dataResult.id, "put", params);

            dispatch(loadingAction(false));
            AlertSuccess('Se ha cancelado la aprobación de la página con éxito')

            if (values.estado === EstadoContenido.EnRevision || values.estado === EstadoContenido.EnAprobacion) {

                history.push("/pages/lista-borradores");
            } else {
                history.push("/pages/lista");
            }

        }
    }

    const { values, errors, handleSubmit, handleChange, setFieldValue, setValues } = useFormik({
        initialValues: {
            nombre: "",
            slug: "",
            template: "template1",
            template1: {
                titulo: "",
                imagen: "",
                contenido: "",
            },
            locale: "es"
        },
        enableReinitialize: true,
        validationSchema: validate,
        onSubmit: (values) => submit(values)
    })

    const handleBlurTitulo = async (event) => {
        const value = event.target.value;
        const name = event.target.name;
        const dataToApi = {
            contentTypeUID: "application::paginas.paginas",
            field: "slug",
            data: {
                nombre: value,
                slug: ""
            }
        }
        setFieldValue(name, value, false)
        try {
            const { data } = await Fetch("content-manager/uid/generate", "post", dataToApi);
            setFieldValue('slug', data.data, false)
        } catch (error) {
            console.error('mostrando error', error)
        }
    }

    const handleChangeJoiEditor = (data) => {
        setFieldValue('template1.contenido', data, false);

    }

    const onEnviarAprobar = async () => {
        if (errors?.template1) {
            dispatch(loadingAction(true));
            let errorKeys = Object.keys(errors.template1)
            let errorToCatch = { message: "" }
            for (const error of errorKeys) {
                errorToCatch.message += `
              ${errors.template1[error]}: ${error}
              `
            }
            dispatch(loadingAction(false));
            catchError(errorToCatch);
        } else {
            dispatch(loadingAction(true));

            values.estado = EstadoContenido.EnAprobacion

            try {

                await Fetch("paginas/" + dataResult.id, "put", values);

                dispatch(loadingAction(false));

                AlertSuccess('Contenido enviado con éxito')
                history.push("/pages/listax");


            } catch (error) {
                dispatch(loadingAction(false));
                catchError(error)
            }
        }

    };

    function Botones() {
        let botonGrabar = '';
        let estiloBotonGrabar = '';
        let estiloIconoGrabar = ''

        switch (accion) {
            case 'nuevo':
            case 'actualizar-borrador':
            case 'actualizar-publicado':
                botonGrabar = 'Guardar'
                estiloBotonGrabar = 'btn btn-success btn-icon'
                estiloIconoGrabar = 'fa fa-floppy-o'
                break;

            case 'publicar':
                botonGrabar = 'Publicar'
                estiloBotonGrabar = 'btn btn-success btn-icon'
                estiloIconoGrabar = 'fa fa-floppy-o'
                break;

            case 'eliminar-publicado':
                botonGrabar = 'Eliminar'
                estiloBotonGrabar = 'btn btn-danger btn-icon'
                estiloIconoGrabar = 'fa fa-trash'
                break;

            default:
                break;
        }

        return (
            <Row className="mt-5">
                <Col className="col col-lg-2">
                    <Button type="button" onClick={() => accion === "eliminar-publicado" ? onEliminarPublicado(values) : handleSubmit()}
                        disabled={isLoading}
                        className={estiloBotonGrabar} >
                        <i className={estiloIconoGrabar} />
                        {botonGrabar}
                    </Button>
                </Col>

                {['publicar', 'eliminar-publicado'].includes(accion) &&
                    <Col className="col col-lg-3">
                        <Button type="button" onClick={() => onCancelar(values)} disabled={isLoading} className="btn btn-primary btn-icon" ><i className="fa fa-undo " />{accion === 'publicar' ? 'Por revisar' : 'Cancelar'}</Button>
                    </Col>}

                {['actualizar-borrador'].includes(accion) &&
                    <Col className="col col-lg-3">
                        <Button type="button" onClick={() => onEnviarAprobar()} disabled={isLoading} className="btn btn-info btn-icon" ><i className="fa fa-share " />Enviar aprobar</Button>
                    </Col>}
            </Row>
        )
    }

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit}>


                <Row>
                    <Col>
                        <h3>Información de la página</h3>
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <label>Nombre de la página</label>
                            <input type="text"
                                name="nombre"
                                disabled={accion === 'eliminar-publicado' ? true : false}
                                onChange={handleChange} onBlur={(e) => handleBlurTitulo(e)}
                                value={values.nombre}
                                className="form-control " />
                            {errors && errors.nombre ? <div className="text-danger">{errors.nombre}</div> : null}
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <label>Url: {values.slug}</label>
                        </Form.Group>
                    </Col>
                </Row>
                {values?.template1?.imagen && Object.keys(values.template1.imagen).length > 0 &&
                    <>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <img src={values.template1.imagen.url} alt="vista-previa" className="img-fluid w-25" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button
                                    type="button"
                                    style={{ marginBottom: "10px" }}
                                    onClick={() => {
                                        eliminarImagen(values.template1.imagen.id).then(response => {
                                            setFieldValue("template1.imagen", "", false)
                                        }).catch(error => { });
                                    }
                                    }
                                    className="btn btn-danger btn-icon mt-2"
                                >
                                    <i className="fa fa-trash-o " />
                                    Eliminar
                                </Button>
                            </Col>
                        </Row>
                    </>
                }
                {accion !== 'eliminar-publicado' &&
                    <Row>
                        <Col>
                            <Form.Group>
                                <label>Imagen</label>
                                <MediaUploader value={values.template1?.imagen ? 100 : 0} key={values.template1?.imagen} setFieldValue={(name, value) => { setFieldValue(name, value, false); setValues({ ...values, template1: { ...values.template1, imagen: value } }) }} field={'template1.imagen'} tipoMedia='imagen' ancho={300} alto={370} />
                                <div className="text-danger">{errors?.template1?.imagen}</div>
                                <span className="text-muted">Se recomienda una imagen de 300px de ancho x 370px de alto. En formato JPG o PNG.El alto de la imagen puede variar según las necesidades de cada página.</span>
                            </Form.Group>
                        </Col>
                    </Row>}

                <hr />
                <Row>
                    <Col>
                        <h3>Información del contenido</h3>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group>
                            <label>Título</label>
                            <input type="text"
                                name="template1.titulo"
                                disabled={accion === 'eliminar-publicado' ? true : false}
                                onChange={handleChange}
                                value={values.template1.titulo}
                                className="form-control " />
                            <div className="text-danger">{errors?.template1?.titulo}</div>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className={`${layoutJodit == "MOBILE" && "col-6"}`}>
                        <Form.Group>
                            <label>Contenido</label>
                            <LayoutJodit
                                setLayoutJodit={setLayoutJodit}
                                layoutJodit={layoutJodit}
                            />
                            <JoditEditor
                                config={config}
                                value={values?.template1?.contenido}
                                ref={editorRef}
                                tabIndex={1} // tabIndex of textarea
                                onChange={newContent => handleChangeJoiEditor(newContent)}
                            />
                            <div className="text-danger">{errors?.template1?.contenido}</div>
                        </Form.Group>
                    </Col>
                </Row>

                <Botones />

            </form>
        </React.Fragment>
    );
}



export default Template1Component;