/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import JoditEditor,{Jodit} from "jodit-react";
import React,{useMemo} from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { AlertInfo, AlertSuccess } from "../../../../components/alert";
import ModalComponent from "../../../../components/modal";
import ModalDegradadosComponent from "../../../../components/modal-degradados";
import { loadingAction } from "../../../../redux/actions";
import { EstadoContenido } from "../../../../utils/constants";
import { catchError, Fetch } from "../../../../utils/fetch";
import { validate } from '../validationSchema';
import { removeSpecial } from "../../../../utils/tools";
import LayoutJodit from "../../../../components/customizer/layout-jodit";

const VerBorradorInformacionUtil = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loading);
  const [layoutJodit, setLayoutJodit] = React.useState("PC");

//   function preparePaste(jodit) {
//     jodit.e.on(
//         'paste',
//         e => {

//             jodit.e.stopPropagation('paste');
//             let plainText = Jodit.modules.Helpers.getDataTransfer(e)
//                 .getData(Jodit.constants.TEXT_PLAIN);
//             // plainText = plainText.replace(/(^|;)\s*font-[^;]+/g, "");
//             // let regex = /<(?!(\/\s*)?(div|b|i|em|strong|u|p)[>,\s])([^>])*>/g;
//             // plainText = plainText.replace(regex, '');
//             plainText = plainText.normalize('NFKC').replaceAll("\n","<br/>");
//             plainText=removeSpecial(plainText)

//             jodit.s.insertHTML(
//                 plainText
//             );
//             return false;

//         },
//         { top: true }
//     );
// }
// Jodit.plugins.add('preparePaste', preparePaste);

  const config = useMemo(
    () => ({
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    language: 'es',disablePlugins:['table'],
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    defaultActionOnPaste: "insert_clear_html",
    enableDragAndDropFileToEditor: true,
    uploader: {
      url: process.env.REACT_APP_BASE_URL + '/upload',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('jwt_token')
      },
      data: null,
      filesVariableName: function (i) {
        return "files"
      },
      withCredentials: false,
      pathVariableName: "path",
      format: "json",
      method: "POST",
      prepareData: function (formData) {
        formData.append('id', 1);

      },
      isSuccess: function (resp) {
        return !resp.error;
      },
      getMessage: function (resp) {
        return resp.msgs.join('\n');
      },
      process: function (resp) {
        return resp;
      },
      defaultHandlerSuccess: function (resp) {
        var imagenesPermitidas=["image/jpeg","image/png"];                
        for (var i = 0; i < resp.length; i++) {
          if(imagenesPermitidas.indexOf(resp[i].mime)!==-1)          
            {
              var img1 = new Image();
              img1.src = resp[i]['url'];
              img1.alt = 'imagen';
              img1.className = "img-fluid"
              this.s.insertImage(img1);
            }else if(resp[i].mime==="application/pdf"){
              const enlace = document.createElement('a');
              enlace.setAttribute('href', resp[i].url);
              const contenido = document.createTextNode(resp[i].name);
              // add the text node to the newly created div
              enlace.appendChild(contenido);
              this.s.insertNode(enlace);
            }else{
              AlertInfo('Tipo de archivo no permitido');
            }
        }
      }
    }
  }),[]);

  const [showModal, setShowModal] = React.useState(false);
  const [showModalColores, setShowModalColores] = React.useState(false);
  const permissions = useSelector((state) => state.permissions);
  //const canPublish = permissions["botones-home"].publicar.enabled;

  const history = useHistory();
  React.useEffect(() => {
    if (id) {
      getHomeBotones();
    }
  }, [id]);

  const getHomeBotones = async (values) => {
    dispatch(loadingAction(true));

    try {
      const { data } = await Fetch("informacion-utils/" + id + "?_publicationState=preview", "get");
      setValues(data);
      dispatch(loadingAction(false));
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const submit = async (values) => {
    dispatch(loadingAction(true));

    values.published_at = null;
    values.estado = EstadoContenido.EnRevision

    values.whatsapp=removeSpecial(values.whatsapp);

    try {

      await Fetch("informacion-utils/" + id, "put", values);

      dispatch(loadingAction(false));

      AlertInfo('Sección actualizado con éxito.<br/>Recuerde que falta su confirmación para aprobar.<br/> Nota: Por favor, notificar al responsable de publicar')
      history.push("/configuracion/informacion-util/lista-borradores");

    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const onEnviarAprobar = async () => {
    dispatch(loadingAction(true));

    values.estado = EstadoContenido.EnAprobacion

    values.whatsapp=removeSpecial(values.whatsapp);

    try {

      await Fetch("informacion-utils/" + id, "put", values);

      dispatch(loadingAction(false));

      AlertSuccess('Contenido enviado con éxito')
      history.push("/configuracion/informacion-util/listax");


    } catch (error) {
      dispatch(loadingAction(false));
      catchError(error)
    }
  };


  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: {
      nombre: "",
      descripcion: "",
      whatsapp: "",
      centro_atencion_socio: "",
      urgencias_emergencias: "",
      turnos_odontologia: "",
      asistencia_al_viajero: "",
      central_de_turnos: "",
      turnos_diagnostico_x_imagenes: "",
      teleconsulta: "",
      locale: "es",
    },
    enableReinitialize: true,
    validationSchema: validate,
    onSubmit: (values) => submit(values),
  });

  const handleChangeJoiEditor = (data, field) => {
    setFieldValue(field, data, false);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <ModalDegradadosComponent
              isShow={showModal}
              setShowModal={setShowModal}
            />
            <ModalComponent
              isShow={showModalColores}
              setShowModal={setShowModalColores}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Header>
              Información útil
                            </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>Nombre</label>
                      <input
                        type="text"
                        name="nombre"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        value={values.nombre}
                      />
                      {errors &&
                        errors.nombre &&
                        (<div className="text-danger">{errors.nombre}</div>)}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>Descripcion</label>
                      <input
                        type="text"
                        name="descripcion"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        value={values.descripcion}
                      />
                      {errors &&
                        errors.descripcion &&
                        (<div className="text-danger">{errors.descripcion}</div>)}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className={`${layoutJodit == "MOBILE" && "col-6"}`}>
                    <Form.Group>
                      <label>Whatsapp</label>
                      <LayoutJodit
                                                    setLayoutJodit={setLayoutJodit}
                                                    layoutJodit={layoutJodit}
                                                />   
                      <JoditEditor
                        config={config}
                        value={values.whatsapp}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={(newContent) =>
                          handleChangeJoiEditor(
                            newContent,
                            "whatsapp"
                          )
                        }
                      />
                      {errors &&
                        errors.whatsapp &&
                        (<div className="text-danger">{errors.whatsapp}</div>)}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className={`${layoutJodit == "MOBILE" && "col-6"}`}>
                    <Form.Group>
                      <label>Centro de Atencion al Socio</label>
                      <JoditEditor
                        config={config}
                        value={values.centro_atencion_socio}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={(newContent) =>
                          handleChangeJoiEditor(
                            newContent,
                            "centro_atencion_socio"
                          )
                        }
                      />
                      {errors &&
                        errors.centro_atencion_socio &&
                        (<div className="text-danger">{errors.centro_atencion_socio}</div>)}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className={`${layoutJodit == "MOBILE" && "col-6"}`}>
                    <Form.Group>
                      <label>Urgencias Y Emergencias</label>
                      <JoditEditor
                        config={config}
                        value={values.urgencias_emergencias}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={(newContent) =>
                          handleChangeJoiEditor(
                            newContent,
                            "urgencias_emergencias"
                          )
                        }
                      />
                      {errors &&
                        errors.urgencias_emergencias &&
                        (<div className="text-danger">{errors.urgencias_emergencias}</div>)}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className={`${layoutJodit == "MOBILE" && "col-6"}`}>
                    <Form.Group>
                      <label>Turnos Odontología</label>
                      <JoditEditor
                        config={config}
                        value={values.turnos_odontologia}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={(newContent) =>
                          handleChangeJoiEditor(
                            newContent,
                            "turnos_odontologia"
                          )
                        }
                      />
                      {errors &&
                        errors.turnos_odontologia &&
                        (<div className="text-danger">{errors.turnos_odontologia}</div>)}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className={`${layoutJodit == "MOBILE" && "col-6"}`}>
                    <Form.Group>
                      <label>Asistencia al Viajero</label>
                      <JoditEditor
                        config={config}
                        value={values.asistencia_al_viajero}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={(newContent) =>
                          handleChangeJoiEditor(
                            newContent,
                            "asistencia_al_viajero"
                          )
                        }
                      />
                      {errors &&
                        errors.asistencia_al_viajero &&
                        (<div className="text-danger">{errors.asistencia_al_viajero}</div>)}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className={`${layoutJodit == "MOBILE" && "col-6"}`}>
                    <Form.Group>
                      <label>Central de turnos</label>
                      <JoditEditor
                        config={config}
                        value={values.central_de_turnos}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={(newContent) =>
                          handleChangeJoiEditor(
                            newContent,
                            "central_de_turnos"
                          )
                        }
                      />
                      {errors &&
                        errors.central_de_turnos &&
                        (<div className="text-danger">{errors.central_de_turnos}</div>)}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className={`${layoutJodit == "MOBILE" && "col-6"}`}>
                    <Form.Group>
                      <label>Turnos de Diagnostico por Imagenes</label>
                      <JoditEditor
                        config={config}
                        value={values.turnos_diagnostico_x_imagenes}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={(newContent) =>
                          handleChangeJoiEditor(
                            newContent,
                            "turnos_diagnostico_x_imagenes"
                          )
                        }
                      />
                      {errors &&
                        errors.turnos_diagnostico_x_imagenes &&
                        (<div className="text-danger">{errors.turnos_diagnostico_x_imagenes}</div>)}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className={`${layoutJodit == "MOBILE" && "col-6"}`}>
                    <Form.Group>
                      <label>Teleconsulta</label>
                      <JoditEditor
                        config={config}
                        value={values.teleconsulta}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={(newContent) =>
                          handleChangeJoiEditor(
                            newContent,
                            "teleconsulta"
                          )
                        }
                      />
                      {errors &&
                        errors.teleconsulta &&
                        (<div className="text-danger">{errors.teleconsulta}</div>)}
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mt-5">
                  <Col className="col col-lg-2">
                    <Button type="button" onClick={() => handleSubmit()} disabled={isLoading} className="btn btn-success btn-icon"><i className="fa fa-floppy-o " />Guardar</Button>
                  </Col>
                  <Col className="col col-lg-3">
                    <Button type="button" onClick={() => onEnviarAprobar(values)} disabled={isLoading} className="btn btn-info btn-icon" ><i className="fa fa-share " />Enviar aprobar</Button>
                  </Col>
                </Row>

              </Card.Body>
            </Card>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};
export default VerBorradorInformacionUtil;
