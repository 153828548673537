import React from 'react';
import { Row, Col, Card, Overlay, Tooltip, Button, OverlayTrigger } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Fetch, catchError } from '../../../utils/fetch'
import { AlertConfirm } from '../../../components/alert'
import { connect } from 'react-redux'
import { loadingAction } from '../../../redux/actions';
import { formatDate } from '../../../utils/formatDate';
import DataGridForm from '../../../components/common/DataGridForm';
import { copyToClickBoard } from '../../../utils/slug';

function ListaPromociones({ isLoadingAction, isLoading }) {
    const [contenido, setContenido] = React.useState(null)
    React.useEffect(() => {
        getContenido()
    }, [])

    const getContenido = async () => {
        isLoadingAction(true)
        try {
            const { data } = await Fetch('promociones?_eliminado=false&_sort=orden:ASC', 'get')

            setContenido(data)
            isLoadingAction(false)
        } catch (error) {
            console.error('mostrando error', error)
            isLoadingAction(false)
            catchError(error);
        }
    }



    const ordenContenido = async (e) => {

        try {
            isLoadingAction(true)

            let request = []
            e.forEach(async (item, indice) => {
                const params = {
                    orden: indice
                }
                request.push(Fetch('promociones/' + item.id, 'put', params))
            })

            Promise.all(request).then(response => { isLoadingAction(false) })

        } catch (error) {
            isLoadingAction(false)
            catchError(error);
        }
    }

    const deleteContenido = async (id) => {
        isLoadingAction(true)
        try {
            const params = {
                eliminado: true
            }
            await Fetch('promociones/' + id, 'put', params)
            getContenido()
            isLoadingAction(false)
        } catch (error) {
            console.error('mostrando error', error)
            isLoadingAction(false)
            catchError(error);
        }
    }



    function RenderAlert(title, id) {
        AlertConfirm(title, 'Para eliminarlo definitivamente o recuperarlo deberás ir a la sección "Por eliminar".','question', (response) => {
            if (response.isConfirmed) {
                deleteContenido(id)
            }
        })
    }

    const handlerOnOff = async (prmId, value) => {
        isLoadingAction(true)
        try {
            const params = {
                onOff: value
            }
            await Fetch('promociones/' + prmId, 'put', params)
            getContenido()
            isLoadingAction(false)
        } catch (error) {
            isLoadingAction(false)
            catchError(error);
        }
    }

    const onReorder = async (e) => {
        const visibleRows = e.component.getVisibleRows();
        const newData = [...contenido];
        const toIndex = newData.indexOf(visibleRows[e.toIndex].data);
        const fromIndex = newData.indexOf(e.itemData);

        newData.splice(fromIndex, 1);
        newData.splice(toIndex, 0, e.itemData);

        setContenido(newData);
        await ordenContenido(newData)
    }

    function FActualizacion(data) {
        return formatDate(data?.updated_at)
    }

    function OnOff(data) {
        return (
            <div class="custom-control custom-switch">
                <input type="checkbox"
                    checked={data.onOff}
                    class="custom-control-input"
                    onChange={(v) => handlerOnOff(data.id, v.target.checked)}
                    id={"csPublicado" + data.id} />
                <label role="button" class="custom-control-label" for={"csPublicado" + data.id}></label>
            </div>
        )
    }

    function Imagen(data) {
        return (
            data?.imagen?.formats?.thumbnail?.url ? <><img src={data.imagen.formats.thumbnail.url} style={{ width: '64px', height: '64px', borderRadius: '32px', objectFit: 'cover' }} className="" alt="images" /></> : null
        )
    }
  
    function Accion(data) {
        return (
            <>
                <Link to={"/promociones/ver/" + data.id} className="btn btn-sm btn btn-success"><i className="fa fa-pencil" /></Link>{' '}
                <a href={`${process.env.REACT_APP_BASE_URL_HOME}/promociones-preview/${data.slug}`} target="_blank" className="btn btn-sm btn btn-info"><i className="fa fa-eye" /></a>{' '}
                <button type="button" className="btn btn-sm btn btn-danger" onClick={() => { RenderAlert("Está apunto de eliminar este contenido", data.id) }} ><i className="fa fa-trash" /></button>{' '}
            </>
        )
    }

    function Slug(data) {
        return (
            <>
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-top`}>
                            Hacer click para copiar url
                        </Tooltip>
                    }
                >
                    <i className='fa fa-copy fa-lg text-info' role="button"
                        onClick={() => copyToClickBoard('promociones/' + data.slug)}
                    ></i>
                </OverlayTrigger>
            </>
        )
    }

    const creacion=(data)=>formatDate(data?.created_at);

    return (
        <React.Fragment>
            <Row>
                <Col md="12">
                    <Card>
                        <Card.Header>
                            <div className="float-right mt-10">
                                <Link to="/promociones/nuevo" className="btn btn-primary btn-rounded box-shadow btn-icon"><i className="fa fa-plus" /> Nueva Promoción</Link>
                            </div>
                            Listado de promociones publicadas
                        </Card.Header>
                        <Card.Body>

                            <div className='table-responsive'>
                                <DataGridForm dataSource={contenido}
                                    keyExpr='id'
                                    allowReordering={true}
                                    columnHidingEnabled={false}
                                    onReorder={onReorder}
                                    columns={[
                                        { caption: 'Id', dataField: 'id' },
                                        { caption: 'Imagen', alignment: 'center', render: 'Imagen', cellRender: { Imagen } },
                                        { caption: 'Título', dataField: 'titulo' },                                        
                                        { caption: 'Url', alignment: 'center', width: 50, render: 'Slug', cellRender: { Slug } },
                                        { caption: 'Creado por', dataField: 'creado_por.username' },
                                        { caption: 'Creación',render:"creacion", cellRender:{creacion}},
                                        { caption: 'Actualizado por', dataField: 'actualizado_por.username' },
                                        { caption: 'Ult. Actualización', render: 'FActualizacion', cellRender: { FActualizacion } },
                                        { caption: 'Inactivo/Activo', alignment: 'center', render: 'OnOff', cellRender: { OnOff } },
                                        { caption: 'Acción', alignment: 'center', render: 'Accion', cellRender: { Accion }, hidingPriority: 0 }
                                    ]} />
                            </div>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {

    return {
        isLoading: state.loading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        isLoadingAction: (data) => { dispatch(loadingAction(data)) }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ListaPromociones);