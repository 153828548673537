import {Modal, Button} from 'react-bootstrap'
import './colores.css';

import React from 'react';

const ModalComponent = ({isShow, setShowModal}) => {

  const handleClose = () => setShowModal(false);
  
return(        
        <Modal show={isShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Estilos de colores</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="row">
                <div className="col color0 d-flex justify-content-center align-items-center">
                    Color 0
                </div>
            </div>
            <div className="row">
                <div className="col color1 d-flex justify-content-center align-items-center">
                    Color 1
                </div>
                <div className="col color2 d-flex justify-content-center align-items-center">
                    Color 2
                </div>
                <div className="col color3 d-flex justify-content-center align-items-center">
                    Color 3
                </div>
            </div>
            <div className="row">
                <div className="col color4 d-flex justify-content-center align-items-center">
                    Color 4
                </div>
                <div className="col color5 d-flex justify-content-center align-items-center">
                    Color 5
                </div>
                <div className="col color6 d-flex justify-content-center align-items-center">
                    Color 6
                </div>
            </div>
            <div className="row">
                <div className="col color7 d-flex justify-content-center align-items-center">
                    Color 7
                </div>
                
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
);
}
export default ModalComponent;