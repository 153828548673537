import types from '../types';

export const loginAction = (token,user) => {

    return{
        type: types.login,
        payload: {
            token,
            user
        }
    }

}

export const logoutAction = (token) => {

    return{
        type: types.logout,
        payload: null
    }

}

export const loadingAction = (data) => {
    return{
        type: types.isLoading,
        payload: data
    }
}

export const permissionsAction = (data) => {
    return{
        type: types.permissions,
        payload: data
    }
}