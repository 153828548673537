/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { FieldArray, Formik } from 'formik';
import React from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { AlertSuccess } from '../../../../components/alert';
import ModalComponent from '../../../../components/modal';
import { loadingAction } from '../../../../redux/actions';
import { EstadoContenido } from '../../../../utils/constants';
import { catchError, Fetch } from '../../../../utils/fetch';
import MediaUploader from '../../../../utils/media-uploader';
import { validate } from '../validationSchema';

const VerEliminarHomeSlide = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const isLoading = useSelector(state => state.loading)
    const [formValues, setFormValues] = React.useState({})
    const [showModal, setShowModal] = React.useState(false)

    const history = useHistory();
    React.useEffect(() => {
        if (id) {
            getHomeSlideById()
        }
    }, [id])

    const getHomeSlideById = async (values) => {
        dispatch(loadingAction(true));
        try {
            const { data } = await Fetch("home-slides/" + id + "?_eliminado=true", "get");
            setFormValues(data)
            dispatch(loadingAction(false));
        } catch (error) {
            catchError(error)
            dispatch(loadingAction(false));
        }
    }

    const submit = async (values) => {


        dispatch(loadingAction(true));

        values.published_at = new Date();
        try {

            await Fetch("home-slides/" + id, "delete", values);

            dispatch(loadingAction(false));

            AlertSuccess('Slide eliminado con éxito')

            history.push("/configuracion/home-slide/lista");
        } catch (error) {
            catchError(error)
            dispatch(loadingAction(false));
        }
    }

    const onCancelar = async (values, type) => {

        dispatch(loadingAction(true));


        try {
            const params = {
                eliminado: false
            }

            if (formValues.estado == EstadoContenido.EnRevision) {
                params.published_at = null;
            }


            await Fetch("home-slides/" + id, "put", params);

            dispatch(loadingAction(false));
            AlertSuccess('Se ha cancelado la eliminación del slide con éxito')

            //history.push("/configuracion/home-slide/lista");

            if (formValues.estado == EstadoContenido.EnRevision) {
                history.push("/configuracion/home-slide/lista-borradores");
            } else {
                history.push("/configuracion/home-slide/lista");
            }

        } catch (error) {
            dispatch(loadingAction(false));
            catchError(error)
        }
    };

    const initialValues = {
        tipo: "", //IMAGEN O VIDEO
        media: '',
        enlace: '#',
        destacado: false,
        caption: {
            h1: "",
            h1_2: "",
            h3: "",
            telefono: "",
            botones: [
                {
                    nombre: "",
                    url: "",
                    estilo: "estilo1"
                }
            ]
        },
    }



    const handleShowColors = () => {
        setShowModal(true);
    }

    return (
        <React.Fragment>
            <Formik
                initialValues={formValues || initialValues}
                enableReinitialize
                validationSchema={validate}
                onSubmit={(values, { setSubmitting }) => {
                    // setTimeout(() => {
                    //   alert(JSON.stringify(values, null, 2));
                    //   setSubmitting(false);
                    // }, 400);
                    submit(values);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                    /* and other goodies */
                }) => (


                    <form onSubmit={handleSubmit}>
                        <Row>
                            <ModalComponent isShow={showModal} setShowModal={setShowModal} />
                            <Col>
                                <Card>
                                    <Card.Header>
                                        Información del slide
                                    </Card.Header>
                                    <Card.Body>

                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <label>Tipo</label>
                                                    <select disabled name="tipo" onChange={handleChange} className="form-control" value={values.tipo}>
                                                        <option>Seleccionar</option>
                                                        <option value="IMAGEN">IMAGEN</option>
                                                    </select>
                                                    {errors &&
                                                        errors.tipo &&
                                                        (<div className="text-danger">{errors.tipo}</div>)}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        {values?.media && Object.keys(values.media).length > 0 &&
                                            <Row>
                                                <Col>
                                                    <Form.Group>
                                                        <img src={values.media.url} alt="vista-previa" className="img-fluid w-50" />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        }

                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <label>Enlace</label>
                                                    <input
                                                        type="text"
                                                        name="enlace"
                                                        className="form-control"
                                                        onChange={handleChange}
                                                        value={values?.enlace}
                                                        disabled
                                                    />
                                                    {errors &&
                                                        errors.enlace &&
                                                        (<div className="text-danger">{errors.enlace}</div>)}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <label>Destacado</label>
                                                    <select disabled name="destacado" onChange={handleChange} className="form-control" value={values.destacado}>
                                                        <option value={false} selected>NO</option>
                                                        <option value={true}>SI</option>
                                                    </select>
                                                    {errors &&
                                                        errors.destacado &&
                                                        (<div className="text-danger">{errors.destacado}</div>)}
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        {(values.destacado === true || values.destacado === 'true') &&
                                            <>
                                                <Row>
                                                    <Col>
                                                        <Form.Group>
                                                            <label>H1 Texto</label>
                                                            <input disabled type="text" name="caption.h1" onChange={handleChange} onBlur={handleBlur} className="form-control" value={values?.caption?.h1} />
                                                            {errors &&
                                                                errors.caption &&
                                                                errors.caption.h1 &&
                                                                (<div className="text-danger">{errors.caption.h1}</div>)}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <div className="form-group">
                                                            <label>Estilo</label>
                                                            <button type="button" onClick={() => setShowModal(true)} className="btn btn-info btn-xs me-2 btn-rounded">?</button>
                                                            <select disabled className="form-control" name={`caption.h1_estilo`} onChange={handleChange} value={values.caption.h1_estilo} >
                                                                <option>Seleccionar</option>
                                                                <option value={'color_0'}>Color 0</option>
                                                                <option value={'color_1'}>Color 1</option>
                                                                <option value={'color_2'}>Color 2</option>
                                                                <option value={'color_3'}>Color 3</option>
                                                                <option value={'color_4'}>Color 4</option>
                                                                <option value={'color_5'}>Color 5</option>
                                                                <option value={'color_6'}>Color 6</option>
                                                                <option value={'color_7'}>Color 7</option>
                                                            </select>
                                                            {errors &&
                                                                errors.caption &&
                                                                errors.caption.h1_estilo &&
                                                                (<div className="text-danger">{errors.caption.h1_estilo}</div>)}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Group>
                                                            <label>H1 Texto2</label>
                                                            <input disabled type="text" name="caption.h1_2" onChange={handleChange} onBlur={handleBlur} className="form-control" value={values?.caption?.h1_2} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <div className="form-group">
                                                            <label>Estilo</label>
                                                            <button type="button" onClick={() => setShowModal(true)} className="btn btn-info btn-xs me-2 btn-rounded">?</button>
                                                            <select disabled className="form-control" name={`caption.h1_2_estilo`} onChange={handleChange} value={values.caption.h1_2_estilo} >
                                                                <option>Seleccionar</option>
                                                                <option value={'color_0'}>Color 0</option>
                                                                <option value={'color_1'}>Color 1</option>
                                                                <option value={'color_2'}>Color 2</option>
                                                                <option value={'color_3'}>Color 3</option>
                                                                <option value={'color_4'}>Color 4</option>
                                                                <option value={'color_5'}>Color 5</option>
                                                                <option value={'color_6'}>Color 6</option>
                                                                <option value={'color_7'}>Color 7</option>
                                                            </select>
                                                            {errors &&
                                                                errors.caption &&
                                                                errors.caption.h1_2_estilo &&
                                                                (<div className="text-danger">{errors.caption.h1_2_estilo}</div>)}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Group>
                                                            <label>H3 Texto</label>
                                                            <input disabled type="text" name="caption.h3" onChange={handleChange} onBlur={handleBlur} className="form-control" value={values?.caption?.h3} />
                                                            {errors &&
                                                                errors.caption &&
                                                                errors.caption.h3 &&
                                                                (<div className="text-danger">{errors.caption.h3}</div>)}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <div className="form-group">
                                                            <label>Estilo</label>
                                                            <button type="button" onClick={() => setShowModal(true)} className="btn btn-info btn-xs me-2 btn-rounded">?</button>
                                                            <select disabled className="form-control" name={`caption.h3_estilo`} onChange={handleChange} value={values.caption.h3_estilo} >
                                                                <option>Seleccionar</option>
                                                                <option value={'color_0'}>Color 0</option>
                                                                <option value={'color_1'}>Color 1</option>
                                                                <option value={'color_2'}>Color 2</option>
                                                                <option value={'color_3'}>Color 3</option>
                                                                <option value={'color_4'}>Color 4</option>
                                                                <option value={'color_5'}>Color 5</option>
                                                                <option value={'color_6'}>Color 6</option>
                                                                <option value={'color_7'}>Color 7</option>
                                                            </select>
                                                            {errors &&
                                                                errors.caption &&
                                                                errors.caption.h3_estilo &&
                                                                (<div className="text-danger">{errors.caption.h3_estilo}</div>)}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <div className="form-group">
                                                            <label>Estilo Barra Separadora</label>
                                                            <button type="button" onClick={() => setShowModal(true)} className="btn btn-info btn-xs me-2 btn-rounded">?</button>
                                                            <select disabled className="form-control" name={`caption.h1_b_estilo`} onChange={handleChange} value={values.caption.h1_b_estilo} >
                                                                <option>Seleccionar</option>
                                                                <option value={'color_0'}>Color 0</option>
                                                                <option value={'color_1'}>Color 1</option>
                                                                <option value={'color_2'}>Color 2</option>
                                                                <option value={'color_3'}>Color 3</option>
                                                                <option value={'color_4'}>Color 4</option>
                                                                <option value={'color_5'}>Color 5</option>
                                                                <option value={'color_6'}>Color 6</option>
                                                                <option value={'color_7'}>Color 7</option>
                                                            </select>
                                                            {errors &&
                                                                errors.caption &&
                                                                errors.caption.h1_b_estilo &&
                                                                (<div className="text-danger">{errors.caption.h1_b_estilo}</div>)}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Group>
                                                            <label>Teléfono</label>
                                                            <input disabled type="text" name="caption.telefono" onChange={handleChange} onBlur={handleBlur} className="form-control" value={values?.caption?.telefono} />
                                                            {errors &&
                                                                errors.caption &&
                                                                errors.caption.telefono &&
                                                                (<div className="text-danger">{errors.caption.telefono}</div>)}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <div className="form-group">
                                                            <label>Estilo</label>
                                                            <button type="button" onClick={() => setShowModal(true)} className="btn btn-info btn-xs me-2 btn-rounded">?</button>
                                                            <select disabled className="form-control" name={`caption.telefono_estilo`} onChange={handleChange} value={values.caption.telefono_estilo} >
                                                                <option>Seleccionar</option>
                                                                <option value={'color_0'}>Color 0</option>
                                                                <option value={'color_1'}>Color 1</option>
                                                                <option value={'color_2'}>Color 2</option>
                                                                <option value={'color_3'}>Color 3</option>
                                                                <option value={'color_4'}>Color 4</option>
                                                                <option value={'color_5'}>Color 5</option>
                                                                <option value={'color_6'}>Color 6</option>
                                                                <option value={'color_7'}>Color 7</option>
                                                            </select>
                                                            {errors &&
                                                                errors.caption &&
                                                                errors.caption.telefono_estilo &&
                                                                (<div className="text-danger">{errors.caption.telefono_estilo}</div>)}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <h2>Botones</h2>

                                                        <FieldArray name="caption.botones">
                                                            {({ move, swap, push, remove, insert, unshift, pop, form }) => {
                                                                return (
                                                                    <>
                                                                        {values?.caption?.botones && values?.caption?.botones.length > 0 &&
                                                                            values?.caption?.botones.map((data, index) => {
                                                                                return (
                                                                                    <Row className="border m-3">

                                                                                        <Col lg={12} className="p-0 d-flex justify-content-end">
                                                                                            <button disabled type="button" className="btn btn-danger" onClick={() => remove(index)}>x</button>
                                                                                        </Col>

                                                                                        <Col>
                                                                                            <div className="form-group">
                                                                                                <label>Nombre</label>
                                                                                                <input disabled type="text" className="form-control" name={`caption.botones.${index}.nombre`} onChange={handleChange} value={values.caption.botones[index]?.nombre} />
                                                                                                {errors &&
                                                                                                    errors.caption &&
                                                                                                    errors.caption.botones &&
                                                                                                    errors.caption.botones[index] &&
                                                                                                    errors.caption.botones[index].nombre &&
                                                                                                    (<div className="text-danger">{errors.caption.botones[index].nombre}</div>)}
                                                                                            </div>
                                                                                        </Col>
                                                                                        <Col>
                                                                                            <div className="form-group">
                                                                                                <label>Url</label>
                                                                                                <input disabled type="text" className="form-control" name={`caption.botones.${index}.url`} onChange={handleChange} value={values.caption.botones[index]?.url} />
                                                                                                {errors &&
                                                                                                    errors.caption &&
                                                                                                    errors.caption.botones &&
                                                                                                    errors.caption.botones[index] &&
                                                                                                    errors.caption.botones[index].url &&
                                                                                                    (<div className="text-danger">{errors.caption.botones[index].url}</div>)}
                                                                                            </div>
                                                                                        </Col>
                                                                                        <Col>
                                                                                            <div className="form-group">
                                                                                                <label>Estilo</label>
                                                                                                <button type="button" onClick={() => setShowModal(true)} className="btn btn-info btn-xs me-2 btn-rounded">?</button>
                                                                                                <select disabled className="form-control" name={`caption.botones.${index}.estilo`} onChange={handleChange} value={values.caption.botones[index]?.estilo} >
                                                                                                    <option>Seleccionar</option>
                                                                                                    <option value={'color1'}>Color 1</option>
                                                                                                    <option value={'color2'}>Color 2</option>
                                                                                                    <option value={'color3'}>Color 3</option>
                                                                                                    <option value={'color4'}>Color 4</option>
                                                                                                    <option value={'color5'}>Color 5</option>
                                                                                                    <option value={'color6'}>Color 6</option>
                                                                                                    <option value={'color7'}>Color 7</option>
                                                                                                </select>
                                                                                                {errors &&
                                                                                                    errors.caption &&
                                                                                                    errors.caption.botones &&
                                                                                                    errors.caption.botones[index] &&
                                                                                                    errors.caption.botones[index].estilo &&
                                                                                                    (<div className="text-danger">{errors.caption.botones[index].estilo}</div>)}
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                )
                                                                            }

                                                                            )}
                                                                        <button disabled type="button" className="btn btn-secondary" onClick={() => push({ nombre: "", url: "#", estilo: '' })}>Añadir Item</button>
                                                                    </>
                                                                )
                                                            }
                                                            }
                                                        </FieldArray>
                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                        <Row className="mt-5">
                                            <Col className="col col-lg-2">
                                                <Button
                                                    type="button"
                                                    onClick={() => handleSubmit()}
                                                    disabled={isLoading}
                                                    className="btn btn-danger btn-icon"
                                                >
                                                    <i className="fas fa-trash" />
                                                    Eliminar
                                                </Button>
                                            </Col>
                                            <Col className="col col-lg-3">
                                                <Button
                                                    type="button"
                                                    onClick={() => onCancelar()}
                                                    disabled={isLoading}
                                                    className="btn btn-primary btn-icon"
                                                >
                                                    <i className="fas fa-undo" />
                                                    Cancelar
                                                </Button>
                                            </Col>
                                        </Row>

                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </form>
                )}
            </Formik>
        </React.Fragment>
    );
}
export default VerEliminarHomeSlide;