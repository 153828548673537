import * as Yup from 'yup';

export const validate = Yup.object().shape({
    nombre: Yup.string().required('campo obligatorio'),
    descripcion: Yup.string().required('campo obligatorio'),
    whatsapp: Yup.string().required('campo obligatorio'),
    centro_atencion_socio: Yup.string().required('campo obligatorio'),
    urgencias_emergencias: Yup.string().required('campo obligatorio'),
    turnos_odontologia: Yup.string().required('campo obligatorio'),
    asistencia_al_viajero: Yup.string().required('campo obligatorio'),
    central_de_turnos: Yup.string().required('campo obligatorio'),
    turnos_diagnostico_x_imagenes: Yup.string().required('campo obligatorio'),
    teleconsulta: Yup.string().required('campo obligatorio'),
  });