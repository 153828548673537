import React from 'react';
import MenuCreador from "../../data/menu-creador.json";
import MenuAprobador from "../../data/menu-aprobador.json";
import BeNavGroup from './nav/nav-group';
import { Nav, Card, Image } from 'react-bootstrap'
import {connect} from 'react-redux'
function MainSidebar(props) {

    const {user} = props
    const [menu] = React.useState(user?.role?.type==='creador'?MenuCreador:MenuAprobador)
    //const userDecoded = decode(userData.token);
    return (
            <React.Fragment>
            {/* ============================================================== */}
            {/* 						Navigation Start 						*/}
            {/* ============================================================== */}
            <div className="main-sidebar-nav default-navigation">
                <div className="nano has-scrollbar">
                    <div className="nano-content sidebar-nav ">
                        <Card.Body className="border-bottom text-center nav-profile">
                            <div className="notify setpos">
                                 <span className="heartbit" /> 
                                 <span className="point" /> 
                            </div>
                            <Image alt="" src="/assets/img/logo.png" className="toggle-none hidden-xs" />                            
                            <p className="text-muted mv-0 toggle-none">Bienvenido <br/><b>{user && user.username}</b></p>						
                            <p className="text-muted mv-0 toggle-none">Rol <b>{user && user?.role?.name}</b></p>						
                        </Card.Body>
                        <Nav as='ul' className="metisMenu flex-column" id="menu">
                            {menu.map((groupItem, key) => {
                                return (
                                    <React.Fragment key={ key }>
                                        <li className="nav-heading"><span>{ groupItem.groupname.toUpperCase() }</span></li>
                                        <BeNavGroup menu={groupItem.children} /> 
                                    </React.Fragment>
                                );
                            })} 
                        </Nav>
                    </div>
                </div>
            </div>
            {/* ============================================================== */}
            {/* 						Navigation End	 						*/}
            {/* ============================================================== */}
            </React.Fragment>
        );
    }

const mapStateToProps = (state)=> {
    
    return {
      isAuthenticated : state.user.isAuthenticated,
      user : state.user.data,
    }
}
export default connect(mapStateToProps,null)(MainSidebar);