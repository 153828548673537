/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Row, Col, Card, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Fetch, catchError } from '../../../utils/fetch'
import { connect } from 'react-redux'
import { loadingAction } from '../../../redux/actions';
import { formatDate } from '../../../utils/formatDate';
import { copyToClickBoard } from '../../../utils/slug';
import DataGridForm from '../../../components/common/DataGridForm';
function ListaPorEliminar({ isLoadingAction, isLoading }) {
    const [contenido, setContenido] = React.useState(null)

    React.useEffect(() => {
        getContenido()
    }, [])

    const getContenido = async () => {
        isLoadingAction(true)
        try {
            const { data } = await Fetch('prestadores?_eliminado=true', 'get')
            setContenido(data)
            isLoadingAction(false)
        } catch (error) {
            console.error('mostrando error', error)
            isLoadingAction(false)
            catchError(error);
        }
    }

    function FActualizacion(data) {
        return formatDate(data?.updated_at)
    }

    function Imagen(data) {
        return (
            data?.imagen?.formats?.thumbnail?.url ? <><img src={data.imagen.formats.thumbnail.url} style={{ width: '64px', height: '64px', borderRadius: '32px', objectFit: 'cover' }} className="" alt="images" /></> : null
        )
    }

    function Destacado(data) {
        return (
            data.destacado ? <h4> <span className="badge bg-success">Si</span> </h4> : <span className="badge bg-secondary">No</span>
        )
    }

    function Accion(data) {
        return (
            <>
                <a href={`${process.env.REACT_APP_BASE_URL_HOME}/prestadores-preview/${data.slug}`} target="_blank" className="btn btn-sm btn btn-info"><i className="fa fa-eye" /></a>{' '}
                <Link to={"/prestadores/ver-para-eliminar/" + data.id} className="btn btn-sm btn btn-success"><i className="fa fa-pencil" /></Link>{' '}
            </>
        )
    }

    function Slug(data) {
        return (
            <>
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-top`}>
                            Hacer click para copiar url
                        </Tooltip>
                    }
                >
                    <i className='fa fa-copy fa-lg text-info' role="button"
                        onClick={() => copyToClickBoard('prestadores/' +data.slug)}
                    ></i>
                </OverlayTrigger>
            </>
        )
    }


    const creacion=(data)=>formatDate(data?.created_at);

    return (
        <React.Fragment>
            <Row>
                <Col md="12">
                    <Card>
                        <Card.Header>
                        Listado de novedades de prestadores por eliminar
                        </Card.Header>
                        <Card.Body>
                            <div className='table-responsive'>

                                <DataGridForm dataSource={contenido}
                                    keyExpr='id'
                                    columns={[
                                        { caption: 'Id', dataField: 'id' },
                                        { caption: 'Imagen', alignment: 'center', render: 'Imagen', cellRender: { Imagen } },
                                        { caption: 'Título', dataField: 'titulo' },
                                        { caption: 'Destacado', alignment: 'center', render: 'Destacado', cellRender: { Destacado } },
                                        { caption: 'Url', alignment: 'center', width: 50, render: 'Slug', cellRender: { Slug } },
                                        { caption: 'Creado por', dataField: 'creado_por.username' },
                                        { caption: 'Creación',render:"creacion", cellRender:{creacion}},
                                        { caption: 'Actualizado por', dataField: 'actualizado_por.username' },
                                        { caption: 'Ult. Actualización', render: 'FActualizacion', cellRender: { FActualizacion } },
                                        { caption: 'Acción', alignment: 'center', render: 'Accion', cellRender: { Accion }, hidingPriority: 0 }
                                    ]} />
                               
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {

    return {
        isLoading: state.loading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        isLoadingAction: (data) => { dispatch(loadingAction(data)) }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ListaPorEliminar);