import {Modal, Button} from 'react-bootstrap'
import './degradados.css';

import React from 'react';

const ModalDegradadosComponent = ({isShow, setShowModal}) => {

  const handleClose = () => setShowModal(false);
  
return(        
        <Modal show={isShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Estilos de degradados</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="row">
                <div className="col degradado1 d-flex justify-content-center align-items-center">
                    Degradado 1
                </div>
                <div className="col degradado2 d-flex justify-content-center align-items-center">
                    Degradado 2
                </div>
            </div>
            <div className="row">
                <div className="col degradado3 d-flex justify-content-center align-items-center">
                    Degradado 3
                </div>
                <div className="col degradado4 d-flex justify-content-center align-items-center">
                    Degradado 4
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
);
}
export default ModalDegradadosComponent;