/* eslint-disable react-hooks/exhaustive-deps */

import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FieldArray, Formik } from "formik";
import React, { useMemo } from "react";
import JoditEditor from "jodit-react";

import LayoutJoditList from "../../customizer/layout-jodit-list";
import { EstadoContenido } from "../../../utils/constants";
import { Fetch, catchError } from "../../../utils/fetch";
import { loadingAction } from "../../../redux/actions";
import { AlertInfo, AlertSuccess } from "../../alert";
import { removeSpecial } from "../../../utils/tools";
import { validate } from "./validationSchema";

const Template3Component = ({ dataResult, accion }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.loading);
  const [formValues, setFormValues] = React.useState({});
  const [layoutJoditList, setLayoutJoditList] = React.useState([]);

  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/doc/
      language: "es",
      disablePlugins: ["table"],
      disabled: accion === "eliminar-publicado" ? true : false,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      defaultActionOnPaste: "insert_clear_html",
      enableDragAndDropFileToEditor: true,
      uploader: {
        url: process.env.REACT_APP_BASE_URL + "/upload",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwt_token"),
        },
        data: null,
        filesVariableName: function (i) {
          return "files";
        },
        withCredentials: false,
        pathVariableName: "path",
        format: "json",
        method: "POST",
        prepareData: function (formData) {
          formData.append("id", 1);
        },
        isSuccess: function (resp) {
          return !resp.error;
        },
        getMessage: function (resp) {
          return resp.msgs.join("\n");
        },
        process: function (resp) {
          return resp;
        },
        defaultHandlerSuccess: function (resp) {
          var imagenesPermitidas = ["image/jpeg", "image/png"];

          for (var i = 0; i < resp.length; i++) {
            if (imagenesPermitidas.indexOf(resp[i].mime) !== -1) {
              var img1 = new Image();
              img1.src = resp[i]["url"];
              img1.alt = "imagen";
              img1.className = "img-fluid";
              this.s.insertImage(img1);
            } else if (resp[i].mime === "application/pdf") {
              const enlace = document.createElement("a");
              enlace.setAttribute("href", resp[i].url);
              const contenido = document.createTextNode(resp[i].name);
              // add the text node to the newly created div
              enlace.appendChild(contenido);
              this.s.insertNode(enlace);
            } else {
              AlertInfo("Tipo de archivo no permitido");
            }
          }
        },
      },
    }),
    []
  );

  React.useEffect(() => {
    if (dataResult) {
      setFormValues(dataResult);

      const count = dataResult.template3.sidemenu.length;
      const aux = [];

      for (let i = 0; i < count; i++) {
        aux.push("PC");
      }

      setLayoutJoditList(aux);
    }
  }, [dataResult]);

  const onGrabarNuevo = async (values) => {
    dispatch(loadingAction(true));

    values.template = "template3";
    values.published_at = null;
    values.estado = EstadoContenido.EnRevision;

    try {
      const { data } = await Fetch("paginas", "post", values);
      dispatch(loadingAction(false));

      AlertInfo(
        "Página creada con éxito.<br/>Recuerde que falta su confirmación para aprobar.<br/> Nota: Por favor, notificar al responsable de publicar"
      );
      history.push("/pages/lista-borradores");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const onActualizarBorrador = async (values) => {
    dispatch(loadingAction(true));

    values.published_at = null;
    values.estado = EstadoContenido.EnRevision;

    try {
      const { data } = await Fetch("paginas/" + dataResult.id, "put", values);

      dispatch(loadingAction(false));

      AlertInfo(
        "Página actualizada con éxito.<br/>Recuerde que falta su confirmación para aprobar.<br/> Nota: Por favor, notificar al responsable de publicar"
      );
      history.push("/pages/lista-borradores");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const onPublicar = async (values) => {
    dispatch(loadingAction(true));

    values.published_at = new Date();
    values.estado = EstadoContenido.Publicado;

    delete values.template3.id;
    values.template3.sidemenu.forEach((x) => delete x.id);

    try {
      const { data } = await Fetch(
        "paginas/aprobar/" +
          (values.idPadre === null ? dataResult.id : values.idPadre),
        "put",
        values
      );
      dispatch(loadingAction(false));

      AlertSuccess("Página actualizada con éxito");

      history.push("/pages/lista");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const onActualizarPublicado = async (values) => {
    dispatch(loadingAction(true));

    values.published_at = null;
    values.idPadre = values.id;
    values.estado = EstadoContenido.EnRevision;

    try {
      const { data } = await Fetch("paginas", "post", values);

      AlertInfo(
        "Página actualizada con éxito.<br/>Recuerde que falta su confirmación para aprobar.<br/> Nota: Por favor, notificar al responsable de publicar"
      );

      dispatch(loadingAction(false));
      history.push("/pages/lista");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const onEliminarPublicado = async (values) => {
    dispatch(loadingAction(true));

    try {
      const { data } = await Fetch("paginas/" + dataResult.id, "delete");

      dispatch(loadingAction(false));

      AlertSuccess("Página eliminada con éxito");
      history.push("/pages/lista-por-eliminar");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const submit = async (values, errors) => {
    values.template3.sidemenu = values.template3.sidemenu.map((item) => {
      item.contenido = removeSpecial(item.contenido);
      return item;
    });

    if (errors?.template3) {
      dispatch(loadingAction(true));
      let errorKeys = Object.keys(errors.template3);
      let errorToCatch = { message: "" };
      for (const error of errorKeys) {
        errorToCatch.message += `
        ${errors.template3[error]}: ${error}
        `;
      }
      dispatch(loadingAction(false));
      catchError(errorToCatch);
    } else {
      switch (accion) {
        case "nuevo":
          await onGrabarNuevo(values);
          break;

        case "actualizar-borrador":
          await onActualizarBorrador(values);
          break;

        case "actualizar-publicado":
          await onActualizarPublicado(values);
          break;

        case "publicar":
          await onPublicar(values);
          break;

        case "eliminar-publicado":
          await onEliminarPublicado(values);
          break;
        default:
          break;
      }
    }
  };

  const onCancelar = async () => {
    dispatch(loadingAction(true));

    if (accion === "eliminar-publicado") {
      const params = {
        eliminado: false,
      };

      if (formValues.estado == EstadoContenido.EnRevision) {
        params.published_at = null;
      }

      const { data } = await Fetch("paginas/" + dataResult.id, "put", params);

      dispatch(loadingAction(false));
      AlertSuccess("Se ha cancelado la eliminación de la página con éxito");

      if (formValues.estado === EstadoContenido.EnRevision) {
        history.push("/pages/lista-borradores");
      } else {
        history.push("/pages/lista");
      }
    } else {
      //por aprobación
      const params = {
        published_at: null,
        estado: EstadoContenido.EnRevision,
      };

      if (formValues.estado == EstadoContenido.EnAprobacion) {
        params.published_at = null;
      }

      const { data } = await Fetch("paginas/" + dataResult.id, "put", params);

      dispatch(loadingAction(false));
      AlertSuccess("Se ha cancelado la aprobación de la página con éxito");

      if (formValues.estado == EstadoContenido.EnAprobacion) {
        history.push("/pages/lista-borradores");
      } else {
        history.push("/pages/lista");
      }
    }
  };

  const handleBlurTitulo = async (event, fn) => {
    const value = event.target.value;
    const name = event.target.name;
    const dataToApi = {
      contentTypeUID: "application::paginas.paginas",
      field: "slug",
      data: {
        nombre: value,
        slug: "",
      },
    };
    try {
      const { data } = await Fetch(
        "content-manager/uid/generate",
        "post",
        dataToApi
      );
      fn("slug", data.data, false);
    } catch (error) {
      console.error("mostrando error", error);
    }
  };

  const onEnviarAprobar = async (values, errors) => {
    if (errors?.template3) {
      dispatch(loadingAction(true));
      let errorKeys = Object.keys(errors.template3);
      let errorToCatch = { message: "" };
      for (const error of errorKeys) {
        errorToCatch.message += `
        ${errors.template3[error]}: ${error}
        `;
      }
      dispatch(loadingAction(false));
      catchError(errorToCatch);
    } else {
      dispatch(loadingAction(true));

      values.estado = EstadoContenido.EnAprobacion;

      try {
        await Fetch("paginas/" + dataResult.id, "put", values);

        dispatch(loadingAction(false));

        AlertSuccess("Contenido enviado con éxito");
        history.push("/pages/listax");
      } catch (error) {
        dispatch(loadingAction(false));
        catchError(error);
      }
    }
  };

  function Botones({ handleSubmit, values, errors }) {
    let botonGrabar = "";
    let estiloBotonGrabar = "";
    let estiloIconoGrabar = "";

    switch (accion) {
      case "nuevo":
      case "actualizar-borrador":
      case "actualizar-publicado":
        botonGrabar = "Guardar";
        estiloBotonGrabar = "btn btn-success btn-icon";
        estiloIconoGrabar = "fa fa-floppy-o";
        break;

      case "publicar":
        botonGrabar = "Publicar";
        estiloBotonGrabar = "btn btn-success btn-icon";
        estiloIconoGrabar = "fa fa-floppy-o";
        break;

      case "eliminar-publicado":
        botonGrabar = "Eliminar";
        estiloBotonGrabar = "btn btn-danger btn-icon";
        estiloIconoGrabar = "fa fa-trash";
        break;

      default:
        break;
    }

    return (
      <Row className="mt-5">
        <Col className="col col-lg-2">
          <Button
            type="button"
            onClick={() =>
              accion === "eliminar-publicado"
                ? onEliminarPublicado(values)
                : handleSubmit()
            }
            disabled={isLoading}
            className={estiloBotonGrabar}
          >
            <i className={estiloIconoGrabar} />
            {botonGrabar}
          </Button>
        </Col>

        {["publicar", "eliminar-publicado"].includes(accion) && (
          <Col className="col col-lg-3">
            <Button
              type="button"
              onClick={() => onCancelar()}
              disabled={isLoading}
              className="btn btn-primary btn-icon"
            >
              <i className="fa fa-undo " />
              {accion === "publicar" ? "Por revisar" : "Cancelar"}
            </Button>
          </Col>
        )}

        {["actualizar-borrador"].includes(accion) && (
          <Col className="col col-lg-3">
            <Button
              type="button"
              onClick={() => onEnviarAprobar(values, errors)}
              disabled={isLoading}
              className="btn btn-info btn-icon"
            >
              <i className="fa fa-share " />
              Enviar aprobar
            </Button>
          </Col>
        )}
      </Row>
    );
  }

  const initialValues = {
    nombre: "",
    slug: "",
    template: "template3",
    template3: {
      sidemenu: [],
    },
    locale: "es",
  };

  const handleLayoutJodit = (index, device) => {
    const data = [...layoutJoditList];
    data[index] = device;
    setLayoutJoditList(data);
  };

  const handleAgregarItim = (push) => {
    setLayoutJoditList((val) => [...val, "PC"]);
    push({ nombre: "", contenido: "", url: "#" });
  };

  return (
    <Formik
      initialValues={formValues || initialValues}
      enableReinitialize
      validationSchema={validate}
      onSubmit={(values, errors) => {
        submit(values, errors);
      }}
    >
      {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
        <form handleSubmit={handleSubmit}>
          <Row>
            <Col>
              <h2>Información de la página</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="form-group">
                <label>Nombre de la página</label>
                <input
                  type="text"
                  name="nombre"
                  disabled={accion === "eliminar-publicado" ? true : false}
                  onChange={handleChange}
                  onBlur={(e) => handleBlurTitulo(e, setFieldValue)}
                  value={values.nombre}
                  className="form-control "
                />
                <div className="text-danger">{errors?.nombre}</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="form-group">
                <label>Url</label>
                <input
                  type="text"
                  name="slug"
                  className="form-control"
                  value={values.slug}
                  readOnly
                />
              </div>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <h2>Información del contenido</h2>
            </Col>
          </Row>

          <Row>
            <Col>
              <FieldArray name="template3.sidemenu">
                {({ push, remove }) => {
                  return (
                    <>
                      {values?.template3?.sidemenu &&
                        values?.template3?.sidemenu?.length > 0 &&
                        values?.template3?.sidemenu?.map((data, index) => {
                          return (
                            <Row key={index} className="border m-3">
                              <Col
                                lg={12}
                                className="p-0 d-flex justify-content-end"
                              >
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={() => remove(index)}
                                >
                                  x
                                </button>
                              </Col>
                              <Col lg={12}>
                                <div className="form-group">
                                  <label>Nombre</label>
                                  <input
                                    type="text"
                                    disabled={
                                      accion === "eliminar-publicado"
                                        ? true
                                        : false
                                    }
                                    className="form-control"
                                    name={`template3.sidemenu[${index}].nombre`}
                                    onChange={handleChange}
                                    value={
                                      values?.template3?.sidemenu[index]?.nombre
                                    }
                                  />
                                  {errors &&
                                    errors.template3 &&
                                    errors.template3.sidemenu &&
                                    errors.template3.sidemenu[index] &&
                                    errors.template3.sidemenu[index].nombre && (
                                      <div className="text-danger">
                                        {
                                          errors.template3.sidemenu[index]
                                            .nombre
                                        }
                                      </div>
                                    )}
                                </div>
                              </Col>
                              <Col lg={12}>
                                <div className="form-group">
                                  <label>Url</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name={`template3.sidemenu[${index}].url`}
                                    onChange={handleChange}
                                    value={
                                      values?.template3?.sidemenu[index]?.url
                                    }
                                  />
                                  {errors &&
                                    errors.template3 &&
                                    errors.template3.sidemenu &&
                                    errors.template3.sidemenu[index] &&
                                    errors.template3.sidemenu[index].url && (
                                      <div className="text-danger">
                                        {
                                          errors?.template3?.sidemenu[index]
                                            ?.url
                                        }
                                      </div>
                                    )}
                                </div>
                              </Col>
                              <Col
                                className={`${
                                  layoutJoditList[index] == "MOBILE" && "col-6"
                                }`}
                              >
                                <div className="form-group">
                                  <label>Contenido</label>
                                  <LayoutJoditList
                                    index={index}
                                    setLayoutJodit={handleLayoutJodit}
                                    layoutJodit={layoutJoditList[index]}
                                  />
                                  <JoditEditor
                                    config={config}
                                    value={
                                      values?.template3?.sidemenu[index]
                                        ?.contenido
                                    }
                                    tabIndex={1} // tabIndex of textarea
                                    onChange={handleChange}
                                    onBlur={(v) =>
                                      setFieldValue(
                                        `template3.sidemenu[${index}].contenido`,
                                        v,
                                        false
                                      )
                                    }
                                  />
                                  {errors &&
                                    errors.template3 &&
                                    errors.template3.sidemenu &&
                                    errors.template3.sidemenu[index] &&
                                    errors.template3.sidemenu[index]
                                      .contenido && (
                                      <div className="text-danger">
                                        {
                                          errors.template3.sidemenu[index]
                                            .contenido
                                        }
                                      </div>
                                    )}
                                </div>
                              </Col>
                            </Row>
                          );
                        })}
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => handleAgregarItim(push)}
                      >
                        Añadir Item
                      </button>
                    </>
                  );
                }}
              </FieldArray>

              <Botones
                handleSubmit={handleSubmit}
                values={values}
                errors={errors}
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
export default Template3Component;
