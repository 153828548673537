import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from "react-redux";
import ErrorBoundary from '../components/exception/error';
function PrivateRoute({component: C,  isAuthenticated, ...rest  }) {  

  return (
    <Route
      {...rest}
      render={props =>
          isAuthenticated
          ? <ErrorBoundary><C {...props} /></ErrorBoundary>         
          : <Redirect to="/auth/login" />}
    />
   );
}

const mapStateToProps = (state)=> {
    
    return {
      isAuthenticated : state.user.isAuthenticated,
    }
}
export default connect(mapStateToProps,null)(PrivateRoute);
