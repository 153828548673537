/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Row, Col, Card, Button, Accordion } from "react-bootstrap";
import { Fetch, catchError } from "../../../utils/fetch";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Formik, FieldArray } from "formik";
import { loadingAction } from "../../../redux/actions";
import { AlertSuccess } from "../../../components/alert";
import { validationSchema } from "../validationSchema";
import { EstadoContenido } from "../../../utils/constants";
import useDragMenu from "../../../hooks/useDragMenu";

function VerMenuX({ token, isLoading, isLoadingAction, permissions }) {
  const params = useParams();
  const { id } = params;
  const history = useHistory();
  const [formValues, setFormValues] = useState({});
  const { handleDragOver, handleDragStart, handleDrop, sortArray } =
    useDragMenu(setFormValues);

  React.useEffect(() => {
    if (id) {
      getMenusById(id);
    }
  }, [id]);

  const getMenusById = async (id) => {
    isLoadingAction(true);
    try {
      const { data } = await Fetch(
        "menus/" + id + "?_publicationState=preview&published_at_null=true",
        "get"
      );

      sortArray(data);
      isLoadingAction(false);
      setFormValues(data);
    } catch (error) {
      isLoadingAction(false);
      catchError(error);
    }
  };

  const initialValues = {
    locale: "es",
    menu: {
      nombre: "",
      url: "#",
      esBoton: false,
      onClick: "",
      enlaceInterno: true,
    },
    submenu: [
      {
        nombre: "",
        url: "",
        esBoton: false,
        onClick: "",
        enlaceInterno: true,
      },
    ],
  };

  const submit = async (values, type) => {
    delete values.menu.id;

    values.published_at = new Date();
    values.submenu.forEach(function (v) {
      delete v.id;
    });
    values.estado = EstadoContenido.Publicado;

    const idPadre = values.idPadre;
    const idToApi = idPadre ? idPadre : id;

    isLoadingAction(true);

    try {
      const { data } = await Fetch("menus/aprobar/" + idToApi, "put", values);
      isLoadingAction(false);

      AlertSuccess("Menú actualizado con éxito");

      history.push("/menus/lista");
    } catch (error) {
      isLoadingAction(false);
      catchError(error);
    }
  };

  const onCancelar = async () => {
    isLoadingAction(true);

    try {
      const params = {
        published_at: null,
        estado: EstadoContenido.EnRevision,
      };

      const { data } = await Fetch("menus/" + id, "put", params);

      isLoadingAction(false);
      AlertSuccess("Se ha cancelado la publicación del menú con éxito");

      history.push("/menus/lista-borradores");
    } catch (error) {
      isLoadingAction(false);
      catchError(error);
    }
  };

  return (
    <>
      <Formik
        initialValues={formValues || initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          // setTimeout(() => {
          //   alert(JSON.stringify(values, null, 2));
          //   setSubmitting(false);
          // }, 400);
          submit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Card>
                  <Card.Header>Información del menú</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <div className="form-group">
                          <label>Nombre</label>
                          <input
                            type="text"
                            name="menu.nombre"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.menu?.nombre || ""}
                          />
                          {errors && errors.menu && errors.menu.nombre && (
                            <div className="text-danger">
                              {errors.menu.nombre}
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col>
                        <div className="form-group">
                          <label>URL</label>
                          <input
                            type="text"
                            name="menu.url"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.menu?.url}
                          />
                          {errors && errors.menu && errors.menu.url && (
                            <div className="text-danger">{errors.menu.url}</div>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="form-group">
                          <label>Es Botón</label>
                          <select
                            className="form-control"
                            name="menu.esBoton"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.menu?.esBoton}
                          >
                            <option value={true}>Si</option>
                            <option value={false}>No</option>
                          </select>
                          {errors && errors.menu && errors.menu.esBoton && (
                            <div className="text-danger">
                              {errors.menu.esBoton}
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col>
                        <div className="form-group">
                          <label>Al dar click</label>
                          <input
                            type="text"
                            name="menu.onClick"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.menu?.onClick}
                          />
                          {errors && errors.menu && errors.menu.onClick && (
                            <div className="text-danger">
                              {errors.menu.onClick}
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-12 col-lg-6">
                        <div className="form-group">
                          <label>El enlace es interno?</label>
                          <select
                            className="form-control"
                            name={`menu.enlaceInterno`}
                            value={values?.menu?.enlaceInterno}
                            onChange={handleChange}
                          >
                            <option value={true}>Si</option>
                            <option value={false}>No</option>
                          </select>
                          <div className="text-danger">
                            {errors?.menu?.enlaceInterno}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {/* Columna1 */}
                    <Row>
                      <Col>
                        <h2>Submenu</h2>
                        <FieldArray name="submenu">
                          {({
                            move,
                            swap,
                            push,
                            remove,
                            insert,
                            unshift,
                            pop,
                            form,
                          }) => {
                            return (
                              <>
                                {values?.submenu &&
                                  values?.submenu.length > 0 &&
                                  values?.submenu.map((data, index) => {
                                    return (
                                      <Row key={index}>
                                        <Col>
                                          <Accordion>
                                            <Card
                                              className="border mb-2"
                                              draggable
                                              onDragStart={(e) =>
                                                handleDragStart(e, index)
                                              }
                                              onDragOver={handleDragOver}
                                              onDrop={(e) =>
                                                handleDrop(e, index, formValues)
                                              }
                                            >
                                              <Card.Header>
                                                <i
                                                  class="fad fa-grip-vertical"
                                                  style={{
                                                    cursor: "all-scroll",
                                                  }}
                                                />
                                                <Accordion.Toggle
                                                  as={Button}
                                                  variant="link"
                                                  eventKey="0"
                                                >
                                                  {
                                                    values.submenu[index]
                                                      ?.nombre
                                                  }
                                                </Accordion.Toggle>
                                                <button
                                                  type="button"
                                                  className="btn btn-default"
                                                  style={{ float: "right" }}
                                                  onClick={() => remove(index)}
                                                >
                                                  X
                                                </button>
                                              </Card.Header>
                                              <Accordion.Collapse eventKey="0">
                                                <Card.Body>
                                                  <Row>
                                                    <Col>
                                                      <div className="form-group">
                                                        <label>Nombre</label>
                                                        <input
                                                          type="text"
                                                          name={`submenu.${index}.nombre`}
                                                          className="form-control"
                                                          onChange={
                                                            handleChange
                                                          }
                                                          onBlur={handleBlur}
                                                          value={
                                                            values.submenu[
                                                              index
                                                            ]?.nombre
                                                          }
                                                        />
                                                        {errors &&
                                                          errors.submenu &&
                                                          errors.submenu[
                                                            index
                                                          ] &&
                                                          errors.submenu[index]
                                                            .nombre && (
                                                            <div className="text-danger">
                                                              {
                                                                errors.submenu[
                                                                  index
                                                                ].nombre
                                                              }
                                                            </div>
                                                          )}
                                                      </div>
                                                    </Col>
                                                    <Col>
                                                      <div className="form-group">
                                                        <label>URL</label>
                                                        <input
                                                          type="text"
                                                          name={`submenu.${index}.url`}
                                                          className="form-control"
                                                          onChange={
                                                            handleChange
                                                          }
                                                          onBlur={handleBlur}
                                                          value={
                                                            values.submenu[
                                                              index
                                                            ].url
                                                          }
                                                        />
                                                        {errors &&
                                                          errors.submenu &&
                                                          errors.submenu[
                                                            index
                                                          ] &&
                                                          errors.submenu[index]
                                                            .url && (
                                                            <div className="text-danger">
                                                              {
                                                                errors.submenu[
                                                                  index
                                                                ].url
                                                              }
                                                            </div>
                                                          )}
                                                      </div>
                                                    </Col>
                                                  </Row>
                                                  <Row>
                                                    <Col>
                                                      <div className="form-group">
                                                        <label>Es Botón</label>
                                                        <select
                                                          className="form-control"
                                                          name={`submenu.${index}.esBoton`}
                                                          onChange={
                                                            handleChange
                                                          }
                                                          onBlur={handleBlur}
                                                          value={
                                                            values.submenu[
                                                              index
                                                            ].esBoton
                                                          }
                                                        >
                                                          <option value={true}>
                                                            Si
                                                          </option>
                                                          <option value={false}>
                                                            No
                                                          </option>
                                                        </select>
                                                        {errors &&
                                                          errors.submenu &&
                                                          errors.submenu[
                                                            index
                                                          ] &&
                                                          errors.submenu[index]
                                                            .esBoton && (
                                                            <div className="text-danger">
                                                              {
                                                                errors.submenu[
                                                                  index
                                                                ].esBoton
                                                              }
                                                            </div>
                                                          )}
                                                      </div>
                                                    </Col>
                                                    <Col>
                                                      <div className="form-group">
                                                        <label>
                                                          Al dar click
                                                        </label>
                                                        <input
                                                          type="text"
                                                          name={`submenu.${index}.onClick`}
                                                          className="form-control"
                                                          onChange={
                                                            handleChange
                                                          }
                                                          onBlur={handleBlur}
                                                          value={
                                                            values.submenu[
                                                              index
                                                            ].onClick
                                                          }
                                                        />
                                                        {errors &&
                                                          errors.submenu &&
                                                          errors.submenu[
                                                            index
                                                          ] &&
                                                          errors.submenu[index]
                                                            .onClick && (
                                                            <div className="text-danger">
                                                              {
                                                                errors.submenu[
                                                                  index
                                                                ].onClick
                                                              }
                                                            </div>
                                                          )}
                                                      </div>
                                                    </Col>
                                                  </Row>

                                                  <Row>
                                                    <Col className="col-12 col-lg-6">
                                                      <div className="form-group">
                                                        <label>
                                                          El enlace es interno?
                                                        </label>
                                                        <select
                                                          className="form-control"
                                                          name={`submenu.${index}.enlaceInterno`}
                                                          value={
                                                            values.submenu[
                                                              index
                                                            ].enlaceInterno
                                                          }
                                                          onChange={
                                                            handleChange
                                                          }
                                                        >
                                                          <option value={true}>
                                                            Si
                                                          </option>
                                                          <option value={false}>
                                                            No
                                                          </option>
                                                        </select>
                                                        {errors &&
                                                          errors.submenu &&
                                                          errors.submenu[
                                                            index
                                                          ] &&
                                                          errors.submenu[index]
                                                            .enlaceInterno && (
                                                            <div className="text-danger">
                                                              {
                                                                errors.submenu[
                                                                  index
                                                                ].enlaceInterno
                                                              }
                                                            </div>
                                                          )}
                                                      </div>
                                                    </Col>
                                                  </Row>
                                                </Card.Body>
                                              </Accordion.Collapse>
                                            </Card>
                                          </Accordion>
                                        </Col>
                                      </Row>
                                    );
                                  })}
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  onClick={() =>
                                    push({
                                      nombre: "nombreMenu",
                                      url: "#",
                                      esBoton: false,
                                      onClick: "",
                                    })
                                  }
                                >
                                  Añadir Item
                                </button>
                              </>
                            );
                          }}
                        </FieldArray>
                      </Col>
                    </Row>
                    <Row className="mt-5">
                      <Col className="col col-lg-2">
                        <Button
                          type="button"
                          onClick={() => handleSubmit()}
                          disabled={isLoading}
                          className="btn btn-success btn-icon"
                        >
                          <i className="fa fa-floppy-o" />
                          Publicar
                        </Button>
                      </Col>
                      <Col className="col col-lg-3">
                        <Button
                          type="button"
                          onClick={() => onCancelar()}
                          disabled={isLoading}
                          className="btn btn-primary btn-icon"
                        >
                          <i className="fa fa-undo " />
                          Por revisar
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
    token: state.user.token,
    isLoading: state.loading,
    permissions: state.permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerMenuX);
