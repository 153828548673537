/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Row, Col, Card, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Fetch, catchError } from '../../../utils/fetch'
import { connect } from 'react-redux'
import { loadingAction } from '../../../redux/actions';
import { formatDate } from '../../../utils/formatDate';
import DataGridForm from '../../../components/common/DataGridForm';
import { copyToClickBoard } from '../../../utils/slug';
function ListarPaginasX({ isLoadingAction }) {

    const [paginas, setPaginas] = React.useState(null)

    React.useEffect(() => {
        getPaginas()
    }, [])

    const getPaginas = async () => {
        isLoadingAction(true)
        try {
            const { data } = await Fetch('paginas?_publicationState=preview&published_at_null=true&estado=EA', 'get')
            setPaginas(data)
            isLoadingAction(false)
        } catch (error) {
            isLoadingAction(false)
            catchError(error);
        }
    }

    function FActualizacion(data) {
        return formatDate(data?.updated_at)
    }

    function Plantilla(data) {
        return (
            <>
                {data.template == 'template1' && 'Plantilla 1'}
                {data.template == 'template2' && 'Plantilla 2'}
                {data.template == 'template3' && 'Plantilla 3'}
                {data.template == 'template4' && 'Plantilla 4'}</>
        )
    }

    function Slug(data) {
        return (
            <>
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-top`}>
                            Hacer click para copiar url
                        </Tooltip>
                    }
                >
                    <i className='fa fa-copy fa-lg text-info' role="button"
                        onClick={() => copyToClickBoard(data.slug)}
                    ></i>
                </OverlayTrigger>
            </>
        )
    } 

    function Accion(data) {
        return (
            <>
                <a href={`${process.env.REACT_APP_BASE_URL_HOME}/pages-preview/${data.id}`} target="_blank" className="btn btn-sm btn btn-info"><i className="fa fa-eye" /></a>{' '}
                <Link to={"/pages/aprobar/" + data.template + "/" + data.id} className="btn btn-sm btn btn-success"><i className="fa fa-pencil" /></Link>{' '}
            </>
        )
    }

    const creacion=(data)=>formatDate(data?.created_at);

    return (
        <React.Fragment>
            <Row>
                <Col md="12">
                    <Card>
                        <Card.Header>
                        Listado de páginas por aprobar
                        </Card.Header>
                        <Card.Body>

                            <DataGridForm dataSource={paginas}
                                keyExpr='id'
                                columnHidingEnabled={true}
                                columns={[
                                    { caption: 'Id', dataField: 'id' },
                                    { caption: 'Nombre', dataField: 'nombre' },
                                    { caption: 'Url', alignment: 'center', width:50, render: 'Slug', cellRender: { Slug } },
                                    { caption: 'Plantilla', render: 'Plantilla', cellRender: { Plantilla } },
                                    { caption: 'Creado por', dataField: 'creado_por.username' },
                                    { caption: 'Creación',render:"creacion", cellRender:{creacion}},
                                    { caption: 'Actualizado por', dataField: 'actualizado_por.username' },
                                    { caption: 'Ult. Actualización', render: 'FActualizacion', cellRender: { FActualizacion } },
                                    { caption: 'Acción', alignment: 'center', render: 'Accion', cellRender: { Accion } }
                                ]} />
                           
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {

    return {
        isLoading: state.loading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        isLoadingAction: (data) => { dispatch(loadingAction(data)) }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ListarPaginasX);