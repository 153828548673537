/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { FieldArray, Formik } from "formik";
import React from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { AlertSuccess } from "../../../../components/alert";
import ModalComponent from "../../../../components/modal";
import { loadingAction } from "../../../../redux/actions";
import { EstadoContenido } from "../../../../utils/constants";
import { catchError, Fetch } from "../../../../utils/fetch";
import MediaUploader from "../../../../utils/media-uploader";
import { validate } from "../validationSchema";
import "./ver.css";
import { eliminarImagen } from "../../../../utils/tools";
const VerHomeSlide = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loading);
  const [formValues, setFormValues] = React.useState({});
  const [showModal, setShowModal] = React.useState(false);
  const permissions = useSelector((state) => state.permissions);
  //const canPublish = permissions['home-slides'].publicar.enabled;

  const history = useHistory();
  React.useEffect(() => {
    if (id) {
      getHomeSlideById();
    }
  }, [id]);

  const getHomeSlideById = async (values) => {
    dispatch(loadingAction(true));
    try {
      const { data } = await Fetch(
        "home-slides/" + id + "?_publicationState=preview",
        "get"
      );
      setFormValues(data);
      dispatch(loadingAction(false));
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const submit = async (values) => {
    dispatch(loadingAction(true));

    values.published_at = null;
    values.idPadre = values.id;
    values.estado = EstadoContenido.EnRevision;

    try {
      await Fetch("home-slides/", "post", values);

      dispatch(loadingAction(false));

      AlertSuccess("Home slide actualizado con éxito");

      history.push("/configuracion/home-slide/lista");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const initialValues = {
    tipo: "", //IMAGEN O VIDEO
    media: "",
    enlace: "#",
    destacado: false,
    caption: {
      h1: "",
      h1_2: "",
      h3: "",
      telefono: "",
      botones: [
        {
          nombre: "",
          url: "",
          estilo: "estilo1",
        },
      ],
    },
  };

  const handleShowColors = () => {
    setShowModal(true);
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={formValues || initialValues}
        enableReinitialize
        validationSchema={validate}
        onSubmit={(values, { setSubmitting }) => {
          // setTimeout(() => {
          //   alert(JSON.stringify(values, null, 2));
          //   setSubmitting(false);
          // }, 400);
          submit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          setValues
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <ModalComponent isShow={showModal} setShowModal={setShowModal} />
              <Col>
                <Card>
                  <Card.Header>Información del slide</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Tipo</label>
                          <select
                            name="tipo"
                            onChange={handleChange}
                            className="form-control"
                            value={values.tipo}
                          >
                            <option>Seleccionar</option>
                            <option value="IMAGEN">IMAGEN</option>
                            <option value="VIDEO">VIDEO</option>
                          </select>
                          {errors && errors.tipo && (
                            <div className="text-danger">{errors.tipo}</div>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    {values?.media && Object.keys(values.media).length > 0 && (
                      <>
                      <Row>
                        <Col>
                          <Form.Group>
                            {!values.media.mime.startsWith("video") ? (
                              <img
                                src={values.media.url}
                                key={values.media.url}
                                alt="vista-previa"
                                className="img-fluid w-50"
                              />
                            ) : (
                              <>
                                <video
                                  width="50%"
                                  height="auto"
                                  controls
                                  key={values.media.url}
                                >
                                  Tu navegador no admite el elemento{" "}
                                  <code>video</code>.
                                  <source src={values.media.url} />
                                </video>
                              </>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button
                            type="button"
                            style={{ marginBottom: "10px" }}
                            onClick={() => {
                              eliminarImagen(values.media.id).then(
                                (response) => {
                                  setFieldValue(
                                    "media",
                                    "",
                                    false
                                  );
                                }
                              ).catch(error=>{}); ;
                            }}
                            className="btn btn-danger btn-icon mt-2"
                          >
                            <i className="fa fa-trash-o " />
                            Eliminar
                          </Button>
                        </Col>
                      </Row>
                    </>
                    )}

                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Seleccionar archivo</label>
                          {values.tipo === "IMAGEN" ? (
                            <>
                              <MediaUploader
                                value={values?.media ? 100 : 0}
                                key={values?.media}
                                setFieldValue={(name,value)=>{setFieldValue(name,value,false);setValues({...values,[name]:value})}}
                                field={"media"}
                                tipoMedia="imagen"
                                ancho={1200}
                                alto={600}
                              />
                              <span className="text-muted">
                                Se recomienda una imagen de 2600px de ancho x
                                450px de alto. En formato JPG o PNG.
                              </span>
                              <div className="text-danger">{errors?.media}</div>
                            </>
                          ) : (
                            <>
                              <MediaUploader
                                value={values?.media ? 100 : 0}
                                key={values?.media}
                                setFieldValue={(name,value)=>{setFieldValue(name,value,false);setValues({...values,[name]:value})}}
                                field={"media"}
                                tipoMedia="video"
                                ancho={1920}
                                alto={1080}
                              />
                              <span className="text-muted">
                                Se recomienda un video de 1920px de ancho x
                                1080px de alto. En formato MP4 ó WEBM.
                              </span>
                              <div className="text-danger">{errors?.media}</div>
                            </>
                          )}

                          {errors && errors.media && (
                            <div className="text-danger">{errors.media}</div>
                          )}
                          <span className="text-muted">
                            Se recomienda una imagen de 2600px de ancho x 450px
                            de alto. En formato JPG o PNG.
                          </span>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Enlace</label>
                          <input
                            type="text"
                            name="enlace"
                            className="form-control"
                            onChange={handleChange}
                            value={values?.enlace}
                          />
                          {errors && errors.enlace && (
                            <div className="text-danger">{errors.enlace}</div>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Destacado</label>
                          <select
                            name="destacado"
                            onChange={handleChange}
                            className="form-control"
                            value={values.destacado}
                          >
                            <option value={false} selected>
                              NO
                            </option>
                            {!values?.media?.mime.startsWith("video") ? (
                              <option value={true}>SI</option>
                            ) : (
                              <></>
                            )}
                          </select>
                          {errors && errors.destacado && (
                            <div className="text-danger">
                              {errors.destacado}
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>

                    {(values.destacado === true ||
                      values.destacado === "true") && (
                      <>
                        <Row>
                          <Col>
                            <Form.Group>
                              <label>H1 Texto1</label>
                              <input
                                type="text"
                                name="caption.h1"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control"
                                value={values?.caption?.h1}
                              />
                              {errors &&
                                errors.caption &&
                                errors.caption.h1 && (
                                  <div className="text-danger">
                                    {errors.caption.h1}
                                  </div>
                                )}
                            </Form.Group>
                          </Col>
                          <Col>
                            <div className="form-group">
                              <label>Estilo</label>
                              <button
                                type="button"
                                onClick={() =>
                                  setShowModal(true)
                                }
                                className="btn btn-info btn-xs me-2 btn-rounded"
                              >
                                ?
                              </button>
                              <select
                                className="form-control"
                                name={`caption.h1_estilo`}
                                onChange={handleChange}
                                value={
                                  values.caption.h1_estilo
                                }
                              >
                                <option>Seleccionar</option>
                                <option value={"color_0"}>
                                  Color 0
                                </option>
                                <option value={"color_1"}>
                                  Color 1
                                </option>
                                <option value={"color_2"}>
                                  Color 2
                                </option>
                                <option value={"color_3"}>
                                  Color 3
                                </option>
                                <option value={"color_4"}>
                                  Color 4
                                </option>
                                <option value={"color_5"}>
                                  Color 5
                                </option>
                                <option value={"color_6"}>
                                  Color 6
                                </option>
                                <option value={"color_7"}>
                                  Color 7
                                </option>
                              </select>
                              {errors &&
                                errors.caption &&
                                errors.caption.h1_estilo && (
                                  <div className="text-danger">
                                    {
                                      errors.caption
                                        .h1_estilo
                                    }
                                  </div>
                                )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Group>
                              <label>H1 Texto2</label>
                              <input
                                type="text"
                                name="caption.h1_2"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control"
                                value={values?.caption?.h1_2}
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <div className="form-group">
                              <label>Estilo</label>
                              <button
                                type="button"
                                onClick={() =>
                                  setShowModal(true)
                                }
                                className="btn btn-info btn-xs me-2 btn-rounded"
                              >
                                ?
                              </button>
                              <select
                                className="form-control"
                                name={`caption.h1_2_estilo`}
                                onChange={handleChange}
                                value={
                                  values.caption.h1_2_estilo
                                }
                              >
                                <option>Seleccionar</option>
                                <option value={"color_0"}>
                                  Color 0
                                </option>
                                <option value={"color_1"}>
                                  Color 1
                                </option>
                                <option value={"color_2"}>
                                  Color 2
                                </option>
                                <option value={"color_3"}>
                                  Color 3
                                </option>
                                <option value={"color_4"}>
                                  Color 4
                                </option>
                                <option value={"color_5"}>
                                  Color 5
                                </option>
                                <option value={"color_6"}>
                                  Color 6
                                </option>
                                <option value={"color_7"}>
                                  Color 7
                                </option>
                              </select>
                              {errors &&
                                errors.caption &&
                                errors.caption.h1_2_estilo  && (
                                  <div className="text-danger">
                                    {
                                      errors.caption
                                        .h1_2_estilo
                                    }
                                  </div>
                                )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Group>
                              <label>H3 Texto</label>
                              <input
                                type="text"
                                name="caption.h3"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control"
                                value={values?.caption?.h3}
                              />
                              {errors &&
                                errors.caption &&
                                errors.caption.h3 && (
                                  <div className="text-danger">
                                    {errors.caption.h3}
                                  </div>
                                )}
                            </Form.Group>
                          </Col>
                          <Col>
                            <div className="form-group">
                              <label>Estilo</label>
                              <button
                                type="button"
                                onClick={() =>
                                  setShowModal(true)
                                }
                                className="btn btn-info btn-xs me-2 btn-rounded"
                              >
                                ?
                              </button>
                              <select
                                className="form-control"
                                name={`caption.h3_estilo`}
                                onChange={handleChange}
                                value={
                                  values.caption.h3_estilo
                                }
                              >
                                <option>Seleccionar</option>
                                <option value={"color_0"}>
                                  Color 0
                                </option>
                                <option value={"color_1"}>
                                  Color 1
                                </option>
                                <option value={"color_2"}>
                                  Color 2
                                </option>
                                <option value={"color_3"}>
                                  Color 3
                                </option>
                                <option value={"color_4"}>
                                  Color 4
                                </option>
                                <option value={"color_5"}>
                                  Color 5
                                </option>
                                <option value={"color_6"}>
                                  Color 6
                                </option>
                                <option value={"color_7"}>
                                  Color 7
                                </option>
                              </select>
                              {errors &&
                                errors.caption &&
                                errors.caption.h3_estilo && (
                                  <div className="text-danger">
                                    {
                                      errors.caption
                                        .h3_estilo
                                    }
                                  </div>
                                )}
                            </div>
                          </Col>
                        </Row>
                        
                        <Row>
                          <Col>
                            <div className="form-group">
                              <label>Estilo Barra Separadora</label>
                              <button
                                type="button"
                                onClick={() =>
                                  setShowModal(true)
                                }
                                className="btn btn-info btn-xs me-2 btn-rounded"
                              >
                                ?
                              </button>
                              <select
                                className="form-control"
                                name={`caption.h1_b_estilo`}
                                onChange={handleChange}
                                value={
                                  values.caption.h1_b_estilo
                                }
                              >
                                <option>Seleccionar</option>
                                <option value={"color_0"}>
                                  Color 0
                                </option>
                                <option value={"color_1"}>
                                  Color 1
                                </option>
                                <option value={"color_2"}>
                                  Color 2
                                </option>
                                <option value={"color_3"}>
                                  Color 3
                                </option>
                                <option value={"color_4"}>
                                  Color 4
                                </option>
                                <option value={"color_5"}>
                                  Color 5
                                </option>
                                <option value={"color_6"}>
                                  Color 6
                                </option>
                                <option value={"color_7"}>
                                  Color 7
                                </option>
                              </select>
                              {errors &&
                                errors.caption &&
                                errors.caption.h1_b_estilo && (
                                  <div className="text-danger">
                                    {
                                      errors.caption
                                        .h1_b_estilo
                                    }
                                  </div>
                                )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Group>
                              <label>Teléfono</label>
                              <input
                                type="text"
                                name="caption.telefono"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control"
                                value={values?.caption?.telefono}
                              />
                              {errors &&
                                errors.caption &&
                                errors.caption.telefono && (
                                  <div className="text-danger">
                                    {errors.caption.telefono}
                                  </div>
                                )}
                            </Form.Group>
                          </Col>
                          <Col>
                            <div className="form-group">
                              <label>Estilo</label>
                              <button
                                type="button"
                                onClick={() =>
                                  setShowModal(true)
                                }
                                className="btn btn-info btn-xs me-2 btn-rounded"
                              >
                                ?
                              </button>
                              <select
                                className="form-control"
                                name={`caption.telefono_estilo`}
                                onChange={handleChange}
                                value={
                                  values.caption.telefono_estilo
                                }
                              >
                                <option>Seleccionar</option>
                                <option value={"color_0"}>
                                  Color 0
                                </option>
                                <option value={"color_1"}>
                                  Color 1
                                </option>
                                <option value={"color_2"}>
                                  Color 2
                                </option>
                                <option value={"color_3"}>
                                  Color 3
                                </option>
                                <option value={"color_4"}>
                                  Color 4
                                </option>
                                <option value={"color_5"}>
                                  Color 5
                                </option>
                                <option value={"color_6"}>
                                  Color 6
                                </option>
                                <option value={"color_7"}>
                                  Color 7
                                </option>
                              </select>
                              {errors &&
                                errors.caption &&
                                errors.caption.telefono_estilo && (
                                  <div className="text-danger">
                                    {
                                      errors.caption
                                        .telefono_estilo
                                    }
                                  </div>
                                )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <h2>Botones</h2>

                            <FieldArray name="caption.botones">
                              {({
                                move,
                                swap,
                                push,
                                remove,
                                insert,
                                unshift,
                                pop,
                                form,
                              }) => {
                                return (
                                  <>
                                    {values?.caption?.botones &&
                                      values?.caption?.botones.length > 0 &&
                                      values?.caption?.botones.map(
                                        (data, index) => {
                                          return (
                                            <Row className="border m-3">
                                              <Col
                                                lg={12}
                                                className="p-0 d-flex justify-content-end"
                                              >
                                                <button
                                                  type="button"
                                                  className="btn btn-danger"
                                                  onClick={() => remove(index)}
                                                >
                                                  x
                                                </button>
                                              </Col>

                                              <Col>
                                                <div className="form-group">
                                                  <label>Nombre</label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    name={`caption.botones.${index}.nombre`}
                                                    onChange={handleChange}
                                                    value={
                                                      values.caption.botones[
                                                        index
                                                      ]?.nombre
                                                    }
                                                  />
                                                  {errors &&
                                                    errors.caption &&
                                                    errors.caption.botones &&
                                                    errors.caption.botones[
                                                      index
                                                    ] &&
                                                    errors.caption.botones[
                                                      index
                                                    ].nombre && (
                                                      <div className="text-danger">
                                                        {
                                                          errors.caption
                                                            .botones[index]
                                                            .nombre
                                                        }
                                                      </div>
                                                    )}
                                                </div>
                                              </Col>
                                              <Col>
                                                <div className="form-group">
                                                  <label>Url</label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    name={`caption.botones.${index}.url`}
                                                    onChange={handleChange}
                                                    value={
                                                      values.caption.botones[
                                                        index
                                                      ]?.url
                                                    }
                                                  />
                                                  {errors &&
                                                    errors.caption &&
                                                    errors.caption.botones &&
                                                    errors.caption.botones[
                                                      index
                                                    ] &&
                                                    errors.caption.botones[
                                                      index
                                                    ].url && (
                                                      <div className="text-danger">
                                                        {
                                                          errors.caption
                                                            .botones[index].url
                                                        }
                                                      </div>
                                                    )}
                                                </div>
                                              </Col>
                                              <Col>
                                                <div className="form-group">
                                                  <label>Estilo</label>
                                                  <button
                                                    type="button"
                                                    onClick={() =>
                                                      setShowModal(true)
                                                    }
                                                    className="btn btn-info btn-xs me-2 btn-rounded"
                                                  >
                                                    ?
                                                  </button>
                                                  <select
                                                    className="form-control"
                                                    name={`caption.botones.${index}.estilo`}
                                                    onChange={handleChange}
                                                    value={
                                                      values.caption.botones[
                                                        index
                                                      ]?.estilo
                                                    }
                                                  >
                                                    <option>Seleccionar</option>
                                                    <option value={"color1"}>
                                                      Color 1
                                                    </option>
                                                    <option value={"color2"}>
                                                      Color 2
                                                    </option>
                                                    <option value={"color3"}>
                                                      Color 3
                                                    </option>
                                                    <option value={"color4"}>
                                                      Color 4
                                                    </option>
                                                    <option value={"color5"}>
                                                      Color 5
                                                    </option>
                                                    <option value={"color6"}>
                                                      Color 6
                                                    </option>
                                                    <option value={"color7"}>
                                                      Color 7
                                                    </option>
                                                  </select>
                                                  {errors &&
                                                    errors.caption &&
                                                    errors.caption.botones &&
                                                    errors.caption.botones[
                                                      index
                                                    ] &&
                                                    errors.caption.botones[
                                                      index
                                                    ].estilo && (
                                                      <div className="text-danger">
                                                        {
                                                          errors.caption
                                                            .botones[index]
                                                            .estilo
                                                        }
                                                      </div>
                                                    )}
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                      )}
                                    <button
                                      type="button"
                                      className="btn btn-secondary"
                                      onClick={() =>
                                        push({
                                          nombre: "",
                                          url: "#",
                                          estilo: "",
                                        })
                                      }
                                    >
                                      Añadir Item
                                    </button>
                                  </>
                                );
                              }}
                            </FieldArray>
                          </Col>
                        </Row>
                      </>
                    )}
                    <Button
                      type="button"
                      onClick={() => handleSubmit()}
                      disabled={isLoading}
                      className="btn btn-success btn-icon mt-2"
                    >
                      <i className="fa fa-floppy-o " />
                      Guardar
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
};
export default VerHomeSlide;
