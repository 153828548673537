import React from "react";

const LayoutJodit = ({ setLayoutJodit, layoutJodit }) => {
  return (
    <>
      <div
        class="btn-group d-block mb-3"
        role="group"
        aria-label="Basic example"
      >
        <button
          type="button"
          class={`btn btn-secondary ${layoutJodit == "PC" && "active"}`}
          onClick={() => setLayoutJodit("PC")}
        >
          PC
        </button>
        <button
          type="button"
          class={`btn btn-secondary ${layoutJodit == "MOBILE" && "active"}`}
          onClick={() => setLayoutJodit("MOBILE")}
        >
          Mobile
        </button>
      </div>
    </>
  );
};

export default LayoutJodit;
