import { AlertConfirm } from "../components/alert";
import { Fetch } from "./fetch";

export const removeSpecial=(string)=>{
  return string;
}


export const removeSpecialJodit=(string)=>{
  var regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|[\u2600-\u26FF\u2700-\u27BF\uD83C][\uDC00-\uDFFF]|\u24C2[\uFE0F]?|[\uD83C][\uDDE6-\uDDFF]{1,2}|[\uD83D][\uDC00-\uDE4F\uDE80-\uDEFF\uDEC0-\uDEF8\uDF00-\uDF9F\uDFA0-\uDFDF\uDFE0-\uDFEF\uDFF0-\uDFFD\uDFFF]/g;
    var cleanedString = string.replace(/[^\x00-\x7F\u00F1\u00D1áéíóúÁÉÍÓÚ¡?¿!()]/g, '').replace(/"/g, '\\"');
    return cleanedString;
  }

  export const eliminarImagen=async (id)=>{    
    return new Promise((resolve,reject)=>{
      AlertConfirm("¿Estás seguro?","La imagen se eliminará permanentemente y no será posible recuperarla.", 'question', (response) => {        
        if (response.isConfirmed) {
          Fetch(`upload/files/${id}`, "delete").then((data)=>{resolve(data)});          
        }else{
          reject(1)
        }
    });      
    });
    
  }
