import * as Yup from 'yup';

export const validate = Yup.object().shape({
    titulo: Yup.string().required('campo obligatorio'),
    descripcion: Yup.string().required('campo obligatorio'),
    degradado: Yup.string().required('campo obligatorio'),
    orientacion_foto: Yup.string().required('campo obligatorio'),
    foto: Yup.object({}).required('campo obligatorio').nullable(),
    boton: Yup.object().shape({
      nombre: Yup.string().required('campo obligatorio'),
      url: Yup.string().required('campo obligatorio'),
      estilo: Yup.string().required('campo obligatorio'),
    })
});