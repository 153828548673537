import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    menu: Yup.object().shape({
        nombre: Yup.string().required('campo obligatorio'),
        url: Yup.string().required('campo obligatorio'),
        esBoton: Yup.boolean().required('campo obligatorio'),
        onClick: Yup.string().when("esBoton", {
            is: true,
            then: Yup.string().required("Campo obligatorio")
        })
    }),
    submenu: Yup.array().of(
        Yup.object().shape({
            nombre: Yup.string().required('campo obligatorio'),
            url: Yup.string().required('campo obligatorio'),
            esBoton: Yup.boolean().required('campo obligatorio'),
            onClick: Yup.string().when("esBoton", {
                is: true,
                then: Yup.string().required("Campo obligatorio")
            })
        })
    ).when("menu.url", {
        is: '#',
        then: (schema) => schema.required('Se requiere un item de submenú')
    })
});