import types from '../types';
const initialState = {
    token: null,
    data: null,
    isAuthenticated: false,    
}

const initialStateLoading = false;
const initialStatePermissions = {};

export const userDataReducer = (state = initialState, action) => {

    switch(action.type){
        case types.login : {
            return {
                token: action.payload.token,
                data: action.payload.user,
                isAuthenticated: true
            };
        }
        case types.logout : {
            return initialState;
        }
        default:
            return state;
    }

}

export const loadingReducer = (state = initialStateLoading, action) => {

    switch(action.type){
        case types.isLoading : {
            return action.payload;
        }
        default:
            return state;
    }
}

export const permissionsReducer = (state = initialStatePermissions, action) => {

    switch(action.type){
        case types.permissions : {
            return action.payload;
        }
        default:
            return state;
    }
}