/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Row, Col, Card, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Fetch, catchError } from '../../../../utils/fetch'
import { AlertConfirm } from '../../../../components/alert'
import { connect } from 'react-redux'
import { loadingAction } from '../../../../redux/actions';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { formatDate } from '../../../../utils/formatDate';
import DataGridForm from '../../../../components/common/DataGridForm';
function ListaInformacionUtil({ isLoadingAction, isLoading }) {
    const [contenido, setContenido] = React.useState(null)

    React.useEffect(() => {
        getContenido()
    }, [])

    const getContenido = async () => {
        isLoadingAction(true)
        try {
            const { data } = await Fetch('informacion-utils?_eliminado=false&_sort=orden:ASC', 'get')
            setContenido(data)
            isLoadingAction(false)
        } catch (error) {
            console.error('mostrando error', error)
            isLoadingAction(false)
            catchError(error);
        }
    }

    function FActualizacion(data) {
        return formatDate(data?.updated_at)
    }

    function Accion(data) {
        return (
            <>
                <Link to={"/configuracion/informacion-util/ver/" + data.id} className="btn btn-sm btn btn-success"><i className="fa fa-pencil" /></Link>{' '}
            </>
        )
    }




    const creacion=(data)=>formatDate(data?.created_at);


    return (
        <React.Fragment>
            <Row>
                <Col md="12">
                    <Card>
                        <Card.Header>
                            Listado de información útil publicados
                        </Card.Header>
                        <Card.Body>

                            <div className='table-responsive'>
                                <DataGridForm dataSource={contenido}
                                    keyExpr='id'
                                    columns={[
                                        { caption: 'Id', dataField: 'id' },
                                        { caption: 'Nombre', dataField: 'nombre' },
                                        { caption: 'Creado por', dataField: 'creado_por.username' },
                                        { caption: 'Creación',render:"creacion", cellRender:{creacion}},
                                        { caption: 'Actualizado por', dataField: 'actualizado_por.username' },
                                        { caption: 'Ult. Actualización', render: 'FActualizacion', cellRender: { FActualizacion } },
                                        { caption: 'Acción', alignment: 'center', render: 'Accion', cellRender: { Accion }, hidingPriority: 0 }
                                    ]} />
                            </div>

                            
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {

    return {
        isLoading: state.loading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        isLoadingAction: (data) => { dispatch(loadingAction(data)) }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ListaInformacionUtil);