import React from "react";
import { Row, Col, Card, Button, Accordion } from "react-bootstrap";
import { Fetch, catchError } from "../../../utils/fetch";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Formik, FieldArray } from "formik";
import { loadingAction } from '../../../redux/actions';
import { AlertSuccess, AlertInfo } from '../../../components/alert'
import { validationSchema } from '../validationSchema'
import { EstadoContenido } from "../../../utils/constants";
function NuevoMenu({ user, token, isLoadingAction, permissions, isLoading }) {

  const history = useHistory();
  const [formValues, setFormValues] = React.useState({})
  //const canPublish = permissions.menus.publicar.enabled;



  const initialValues = {
    locale: "es",
    menu: {
      nombre: "",
      url: "#",
      esBoton: false,
      onClick: '',
      enlaceInterno: true
    },
    submenu: [
      {
        nombre: "nombremenu",
        url: "#",
        esBoton: false,
        onClick: '',
        enlaceInterno: true
      },
    ]
  };
  // React.useEffect(() => {
  //   permissions.menus.create.enabled && AlertInfo('No tiene permisos suficientes')    
  // }, [])

  const submit = async (values, type) => {

    isLoadingAction(true);

    values.published_at = null;
    values.estado = EstadoContenido.EnRevision

    try {
      const { data } = await Fetch("menus", "post", values);
      isLoadingAction(false);

      AlertInfo('Menú creado con éxito.<br/>Recuerde que falta su confirmación para aprobar.<br/> Nota: Por favor, notificar al responsable de publicar')
      history.push("/menus/lista-borradores");

    } catch (error) {
      console.log('ERROR=>', error.response.data)
      isLoadingAction(false);
      catchError(error);
    }
  };




  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          // setTimeout(() => {
          //   alert(JSON.stringify(values, null, 2));
          //   setSubmitting(false);
          // }, 400);
          submit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>

            <Row>
              <Col>
                <Card>
                  <Card.Header>Nuevo Menú</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <div className="form-group">
                          <label>Nombre</label>
                          <input
                            type="text"
                            name="menu.nombre"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.menu?.nombre || ''}
                          />
                          {errors &&
                            errors.menu &&
                            errors.menu.nombre &&
                            (<div className="text-danger">{errors.menu.nombre}</div>)}
                        </div>
                      </Col>
                      <Col>
                        <div className="form-group">
                          <label>URL</label>
                          <input
                            type="text"
                            name="menu.url"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.menu?.url}
                          />
                          {errors &&
                            errors.menu &&
                            errors.menu.url &&
                            (<div className="text-danger">{errors.menu.url}</div>)}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="form-group">
                          <label>Es Botón</label>
                          <select className="form-control" name="menu.esBoton" onChange={handleChange} onBlur={handleBlur} value={values?.menu?.esBoton}>
                            <option value={false}>No</option>
                            <option value={true}>Si</option>
                          </select>
                        </div>
                      </Col>
                      <Col>
                        <div className="form-group">
                          <label>Al dar click</label>
                          <input
                            type="text"
                            name="menu.onClick"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.menu?.onClick}
                          />
                          {errors &&
                            errors.menu &&
                            errors.menu.onClick &&
                            (<div className="text-danger">{errors.menu.onClick}</div>)}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-12 col-lg-6">
                        <div className="form-group">
                          <label>El enlace es interno?</label>
                          <select className="form-control"
                            name={`menu.enlaceInterno`}
                            value={values.menu.enlaceInterno} onChange={handleChange}>
                            <option value={true}>Si</option>
                            <option value={false}>No</option>
                          </select>
                          <div className="text-danger">{errors?.menu?.enlaceInterno}</div>
                        </div>
                      </Col>
                    </Row>
                    {/* Columna1 */}
                    <Row>
                      <Col>
                        <h2>Submenu</h2>
                        <FieldArray name="submenu">
                          {({ move, swap, push, remove, insert, unshift, pop, form }) => {
                            return (
                              <>
                                {values?.submenu && values?.submenu.length > 0 &&
                                  values?.submenu.map((data, index) => {
                                    return (
                                      <Row key={index}>
                                        <Col>
                                          <Accordion>
                                            <Card className="border mb-2">
                                              <Card.Header className="d-flex justify-content-between">

                                                <Accordion.Toggle
                                                  as={Button}
                                                  variant="link"
                                                  eventKey="0"
                                                >
                                                  {values.submenu[index]?.nombre}

                                                </Accordion.Toggle>
                                                <button type="button" className="btn btn-default" onClick={() => remove(index)}>X</button>
                                              </Card.Header>
                                              <Accordion.Collapse eventKey="0">
                                                <Card.Body>
                                                  <Row>
                                                    <Col>
                                                      <div className="form-group">
                                                        <label>Nombre</label>
                                                        <input
                                                          type="text"
                                                          name={`submenu.${index}.nombre`}
                                                          className="form-control"
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          value={values.submenu[index]?.nombre}
                                                        />
                                                        {errors &&
                                                          errors.submenu &&
                                                          errors.submenu[index] &&
                                                          errors.submenu[index].nombre &&
                                                          (<div className="text-danger">{errors.submenu[index].nombre}</div>)}
                                                      </div>
                                                    </Col>
                                                    <Col>
                                                      <div className="form-group">
                                                        <label>URL</label>
                                                        <input
                                                          type="text"
                                                          name={`submenu.${index}.url`}
                                                          className="form-control"
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          value={values.submenu[index].url}
                                                        />
                                                        {errors &&
                                                          errors.submenu &&
                                                          errors.submenu[index] &&
                                                          errors.submenu[index].url &&
                                                          (<div className="text-danger">{errors.submenu[index].url}</div>)}
                                                      </div>
                                                    </Col>
                                                  </Row>
                                                  <Row>
                                                    <Col>
                                                      <div className="form-group">
                                                        <label>Es Botón</label>
                                                        <select className="form-control" name={`submenu.${index}.esBoton`} onChange={handleChange} onBlur={handleBlur} value={values.submenu[index].esBoton} >
                                                          <option value={true}>Si</option>
                                                          <option value={false}>No</option>
                                                        </select>
                                                        {errors.esBoton && touched.esBoton && errors.esBoton}
                                                      </div>
                                                    </Col>
                                                    <Col>
                                                      <div className="form-group">
                                                        <label>Al dar click</label>
                                                        <input
                                                          type="text"
                                                          name={`submenu.${index}.onClick`}
                                                          className="form-control"
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          value={values.submenu[index].onClick}
                                                        />
                                                        {errors &&
                                                          errors.submenu &&
                                                          errors.submenu[index] &&
                                                          errors.submenu[index].onClick &&
                                                          (<div className="text-danger">{errors.submenu[index].onClick}</div>)}
                                                      </div>
                                                    </Col>
                                                  </Row>

                                                  <Row>
                                                    <Col className="col-12 col-lg-6">
                                                      <div className="form-group">
                                                        <label>El enlace es interno?</label>
                                                        <select className="form-control"
                                                          name={`submenu.${index}.enlaceInterno`}
                                                          value={values.submenu[index].enlaceInterno}
                                                          onChange={handleChange}>
                                                          <option value={true}>Si</option>
                                                          <option value={false}>No</option>
                                                        </select>
                                                        {errors &&
                                                          errors.submenu &&
                                                          errors.submenu[index] &&
                                                          errors.submenu[index].enlaceInterno &&
                                                          (<div className="text-danger">{errors.submenu[index].enlaceInterno}</div>)}
                                                      </div>
                                                    </Col>
                                                  </Row>

                                                </Card.Body>
                                              </Accordion.Collapse>
                                            </Card>
                                          </Accordion>
                                        </Col>
                                      </Row>
                                    )
                                  }

                                  )}
                                <button type="button" className="btn btn-secondary" onClick={() => push({ nombre: "nombreMenu", url: "#", esBoton: false, onClick: '', enlaceInterno: true })}>Añadir Item</button>
                              </>
                            )
                          }
                          }
                        </FieldArray>
                      </Col>
                    </Row>
                    <Button
                      type="button"
                      onClick={() => handleSubmit()}
                      disabled={isLoading}
                      className="btn btn-success btn-icon mt-2"
                    >
                      <i className="fa fa-floppy-o " />
                      Guardar
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
    token: state.user.token,
    permissions: state.permissions,
    isLoading: state.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => { dispatch(loadingAction(data)) }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(NuevoMenu);
