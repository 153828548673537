import 'devextreme/dist/css/dx.light.css';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';

import { Provider } from "react-redux";
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import store from './redux/store';

// Import as a module in your JS
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Layout from './layouts';

// Components
import PrivateRoute from './routes/PrivateRoute';

// Auth
import Login from './pages/auth/login';
import Register from './pages/auth/register';
import ResetPassword from './pages/auth/reset-password';

// Dashboard
import Dashboard from './pages/dashboard';

// Cartilla
import Cartilla from './pages/cartilla';


//Menus
import ListaMenus from './pages/menus/lista';
import ListaBorradorMenus from './pages/menus/lista-borradores';
import ListaPorEliminarMenus from './pages/menus/lista-por-eliminar';
import ListaMenusX from './pages/menus/listax';
import NuevoMenu from './pages/menus/nuevo';
import VerMenu from './pages/menus/ver';
import VerBorradorMenu from './pages/menus/ver-borrador';
import VerEliminarMenu from './pages/menus/ver-para-eliminar';
import VerMenuX from './pages/menus/verx';


//Paginas
import ListarPaginas from './pages/paginas/lista';
import ListarBorradorPaginas from './pages/paginas/lista-borradores';
import ListaPorEliminarPaginas from './pages/paginas/lista-por-eliminar';
import ListarPaginasX from './pages/paginas/listax';
import NuevaPagina from './pages/paginas/nuevo-step1';
import NuevaPaginaTemplate from './pages/paginas/nuevo-step2';
import VerPaginaTemplate from './pages/paginas/ver';
import VerBorradorPaginaTemplate from './pages/paginas/ver-borrador';
import VerPaginaParaEliminarTemplate from './pages/paginas/ver-para-eliminar';
import VerPaginaTemplateX from './pages/paginas/verx';


//Novedades
import ListaNovedades from './pages/novedades/lista';
import ListaBorradorNovedades from './pages/novedades/lista-borradores';
import listaPorEliminar from './pages/novedades/lista-por-eliminar';
import ListaNovedadesX from './pages/novedades/listax';
import NuevaNovedad from './pages/novedades/nuevo';
import VerNovedad from './pages/novedades/ver';
import VerBorradorNovedad from './pages/novedades/ver-borrador';
import VerEliminarNovedad from './pages/novedades/ver-para-eliminar';
import VerNovedadX from './pages/novedades/verx';

//Prestadores
import ListaNovedadesPrestadores from './pages/prestadores/lista';
import ListaBorradorNovedadesPrestadores from './pages/prestadores/lista-borradores';
import listaPorEliminarPrestadores from './pages/prestadores/lista-por-eliminar';
import ListaNovedadesXPrestadores from './pages/prestadores/listax';
import NuevaNovedadPrestadores from './pages/prestadores/nuevo';
import VerNovedadPrestadores from './pages/prestadores/ver';
import VerBorradorNovedadPrestadores from './pages/prestadores/ver-borrador';
import VerEliminarNovedadPrestadores from './pages/prestadores/ver-para-eliminar';
import VerNovedadXPrestadores from './pages/prestadores/verx';

//Promociones
import ListaPromociones from './pages/promociones/lista';
import ListaBorradorPromociones from './pages/promociones/lista-borradores';
import listaPorEliminarPromocion from './pages/promociones/lista-por-eliminar';
import ListaPromocionesX from './pages/promociones/listax';
import NuevaPromocion from './pages/promociones/nuevo';
import VerPromocion from './pages/promociones/ver';
import VerBorradorPromocion from './pages/promociones/ver-borrador';
import VerEliminarPromocion from './pages/promociones/ver-para-eliminar';
import VerPromocionX from './pages/promociones/verx';

//Planes
import ListaPlanes from './pages/planes/lista';
import ListaBorradorPlanes from './pages/planes/lista-borradores';
import ListaPorEliminarPlanes from './pages/planes/lista-por-eliminar';
import ListaPlanesX from './pages/planes/listax';
import NuevoPlan from './pages/planes/nuevo';
import VerPlan from './pages/planes/ver';
import VerBorradorPlan from './pages/planes/ver-borrador';
import VerEliminarPlan from './pages/planes/ver-para-eliminar';
import VerPlanX from './pages/planes/verx';

//Pop up
import ListaPopup from "./pages/popup/lista";
import ListaBorradoresPopup from "./pages/popup/lista-borradores";
import ListaPorEliminarPopup from "./pages/popup/lista-por-eliminar";
import ListaPopupX from "./pages/popup/listax";
import NuevoPopup from "./pages/popup/nuevo";
import VerPopup from "./pages/popup/ver";
import VerBorradorPopup from "./pages/popup/ver-borrador";
import VerParaEliminarPopup from "./pages/popup/ver-para-eliminar";
import VerPopupX from "./pages/popup/verx";


//Configuracion - Home Slide
import ListarHomeSlides from './pages/configuracion/home-slides/lista';
import ListarBorradorHomeSlides from './pages/configuracion/home-slides/lista-borradores';
import ListarHomeSlidesX from './pages/configuracion/home-slides/listax';
import ListarPorEliminarHomeSlides from './pages/configuracion/home-slides/lista-por-eliminar';
import NuevoHomeSlide from './pages/configuracion/home-slides/nuevo';
import VerHomeSlide from './pages/configuracion/home-slides/ver';
import VerBorradorHomeSlide from './pages/configuracion/home-slides/ver-borrador';
import VerHomeSlideX from './pages/configuracion/home-slides/verx';
import VerEliminarHomeSlide from './pages/configuracion/home-slides/ver-para-eliminar';

//Configuracion Home Dinamica
import ListaHomeEstudia from './pages/configuracion/home-dinamica/lista';
import ListaBorradorHomeEstudia from './pages/configuracion/home-dinamica/lista-borradores';
import ListaPorEliminarHomeDinamica from './pages/configuracion/home-dinamica/lista-por-eliminar';
import ListaHomeEstudiaX from './pages/configuracion/home-dinamica/listax';
import NuevaHomeDinamica from './pages/configuracion/home-dinamica/nuevo';
import VerHomeDinamica from './pages/configuracion/home-dinamica/ver';
import VerBorradorHomeDinamica from './pages/configuracion/home-dinamica/ver-borrador';
import VerEliminarHomeDinamica from './pages/configuracion/home-dinamica/ver-para-eliminar';
import VerHomeDinamicaX from './pages/configuracion/home-dinamica/verx';

//Configuracion Home Botones
import ListarHomeBotones from './pages/configuracion/home-botones/lista';
import ListarBorradorHomeBotones from './pages/configuracion/home-botones/lista-borradores';
import ListarHomeBotonesX from './pages/configuracion/home-botones/listax';
import VerHomeBotones from './pages/configuracion/home-botones/ver';
import VerBorradorHomeBotones from './pages/configuracion/home-botones/ver-borrador';
import VerHomeBotonesX from './pages/configuracion/home-botones/verx';

//Configuracion Informacion util
import ListaInformacionUtil from './pages/configuracion/informacion-util/lista';
import ListaBorradorInformacionUtil from './pages/configuracion/informacion-util/lista-borradores';
import ListaInformacionUtilX from './pages/configuracion/informacion-util/listax';
import VerInformacionUtil from './pages/configuracion/informacion-util/ver';
import VerBorradorInformacionUtil from './pages/configuracion/informacion-util/ver-borrador';
import VerInformacionUtilX from './pages/configuracion/informacion-util/verx';

// Configuracion Datos de contacto
import DatosContacto from './pages/datos-contacto';

import * as serviceWorker from './serviceWorker';
import { Jodit } from 'jodit-react';
import { removeSpecial, removeSpecialJodit } from './utils/tools';


let persistor = persistStore(store);

function preparePaste(jodit) {
  jodit.e.on(
      'paste',
      e => {

          jodit.e.stopPropagation('paste');
          let plainText = Jodit.modules.Helpers.getDataTransfer(e)
              .getData(Jodit.constants.TEXT_PLAIN);
          // plainText = plainText.replace(/(^|;)\s*font-[^;]+/g, "");
          // let regex = /<(?!(\/\s*)?(div|b|i|em|strong|u|p)[>,\s])([^>])*>/g;
          // plainText = plainText.replace(regex, '');          

          //plainText = plainText.normalize('NFKC').replaceAll("\n","<br/>").replaceAll("\r","");                

          plainText = plainText.normalize('NFKC');
          plainText = plainText.replace(/\r\n/g, '\n');
          plainText=removeSpecialJodit(plainText);

          // Dividir el texto en líneas separadas
          const lines = plainText.split('\n');
          
          // Insertar cada línea como un nuevo nodo
          lines.forEach(line => {
           if(line.startsWith("https://") || line.startsWith("http://"))
           {
              jodit.s.insertHTML(`<a href="${line}">${line}</a>`);
           }else{
              jodit.s.insertHTML(`<p>${line}</p>`);
           }
            
          });
        
          return false;

      },
      { top: true }
  );
}
Jodit.plugins.add('preparePaste', preparePaste);



ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
  <React.StrictMode>
    <BrowserRouter basename={'/'} >
      
      <Switch>
        
        <Route exact path="/auth" render={() => (<Redirect to="/auth/login" />)} /> 
        <Route path={`/auth/login`} component={Login} />
        <Route path={`/auth/register`} component={Register} />
        <Route path={`/auth/forget-password`} component={ResetPassword} />
        
          <Layout name="backend">
            <Route exact path="/" render={() => (<Redirect to="/dashboard" />)} />   
            <PrivateRoute path={`/dashboard`} component={Dashboard} />
            <PrivateRoute path={`/cartilla`} component={Cartilla} />

            {/* Menus */}
            <PrivateRoute path={`/menus/lista`} component={ListaMenus} />
            <PrivateRoute path={`/menus/lista-borradores`} component={ListaBorradorMenus} />
            <PrivateRoute path={`/menus/listax`} component={ListaMenusX} />
            <PrivateRoute path={`/menus/lista-por-eliminar`} component={ListaPorEliminarMenus} />
            <PrivateRoute path={`/menus/nuevo`} component={NuevoMenu} />
            <PrivateRoute path={`/menus/ver/:id`} exact component={VerMenu} />
            <PrivateRoute path={`/menus/ver-borrador/:id`} exact component={VerBorradorMenu} />
            <PrivateRoute path={`/menus/ver-para-eliminar/:id`} exact component={VerEliminarMenu} />
            <PrivateRoute path={`/menus/aprobar/:id`} component={VerMenuX} />

            {/* Paginas */}
            <PrivateRoute path={`/pages/lista`} component={ListarPaginas} />
            <PrivateRoute path={`/pages/lista-borradores`} component={ListarBorradorPaginas} />
            <PrivateRoute path={`/pages/listax`} component={ListarPaginasX} />
            <PrivateRoute path={`/pages/lista-por-eliminar`} component={ListaPorEliminarPaginas} />
            <PrivateRoute path={`/pages/nuevo`} component={NuevaPagina} />
            <PrivateRoute path={`/pages/nueva-pagina/:template`} component={NuevaPaginaTemplate} />
            <PrivateRoute path={`/pages/ver/:template/:id`} exact component={VerPaginaTemplate} />
            <PrivateRoute path={`/pages/ver-borrador/:template/:id`} exact component={VerBorradorPaginaTemplate} />
            <PrivateRoute path={`/pages/ver/:template/:id/:eliminar`} exact component={VerPaginaTemplate} />
            <PrivateRoute path={`/pages/ver-para-eliminar/:template/:id`} exact component={VerPaginaParaEliminarTemplate} />
            <PrivateRoute path={`/pages/aprobar/:template/:id`} component={VerPaginaTemplateX} />

            {/* Novedades */}
            <PrivateRoute path={`/novedades/lista`} component={ListaNovedades} />
            <PrivateRoute path={`/novedades/lista-borradores`} component={ListaBorradorNovedades} />
            <PrivateRoute path={`/novedades/listax`} component={ListaNovedadesX} />
            <PrivateRoute path={`/novedades/lista-por-eliminar`} component={listaPorEliminar} />
            <PrivateRoute path={`/novedades/nuevo`} component={NuevaNovedad} />
            <PrivateRoute path={`/novedades/ver/:id`} exact component={VerNovedad} />
            <PrivateRoute path={`/novedades/ver-borrador/:id`} exact component={VerBorradorNovedad} />
            <PrivateRoute path={`/novedades/ver-para-eliminar/:id`} exact component={VerEliminarNovedad} />
            <PrivateRoute path={`/novedades/aprobar/:id/`} component={VerNovedadX} />

            {/* Novedades Prestadores*/}
            <PrivateRoute path={`/prestadores/lista`} component={ListaNovedadesPrestadores} />
            <PrivateRoute path={`/prestadores/lista-borradores`} component={ListaBorradorNovedadesPrestadores} />
            <PrivateRoute path={`/prestadores/listax`} component={ListaNovedadesXPrestadores} />
            <PrivateRoute path={`/prestadores/lista-por-eliminar`} component={listaPorEliminarPrestadores} />
            <PrivateRoute path={`/prestadores/nuevo`} component={NuevaNovedadPrestadores} />
            <PrivateRoute path={`/prestadores/ver/:id`} exact component={VerNovedadPrestadores} />
            <PrivateRoute path={`/prestadores/ver-borrador/:id`} exact component={VerBorradorNovedadPrestadores} />
            <PrivateRoute path={`/prestadores/ver-para-eliminar/:id`} exact component={VerEliminarNovedadPrestadores} />
            <PrivateRoute path={`/prestadores/aprobar/:id/`} component={VerNovedadXPrestadores} />

             {/* Promociones */}
             <PrivateRoute path={`/promociones/lista`} component={ListaPromociones} />
            <PrivateRoute path={`/promociones/lista-borradores`} component={ListaBorradorPromociones} />
            <PrivateRoute path={`/promociones/listax`} component={ListaPromocionesX} />
            <PrivateRoute path={`/promociones/lista-por-eliminar`} component={listaPorEliminarPromocion} />
            <PrivateRoute path={`/promociones/nuevo`} component={NuevaPromocion} />
            <PrivateRoute path={`/promociones/ver/:id`} exact component={VerPromocion} />
            <PrivateRoute path={`/promociones/ver-borrador/:id`} exact component={VerBorradorPromocion} />
            <PrivateRoute path={`/promociones/ver-para-eliminar/:id`} exact component={VerEliminarPromocion} />
            <PrivateRoute path={`/promociones/aprobar/:id/`} component={VerPromocionX} />

            {/* Planes */}
            <PrivateRoute path={`/planes/lista`} component={ListaPlanes} />
            <PrivateRoute path={`/planes/lista-borradores`} component={ListaBorradorPlanes} />
            <PrivateRoute path={`/planes/listax`} component={ListaPlanesX} />
            <PrivateRoute path={`/planes/lista-por-eliminar`} component={ListaPorEliminarPlanes} />
            <PrivateRoute path={`/planes/nuevo`} component={NuevoPlan} />
            <PrivateRoute path={`/planes/ver/:id`} exact component={VerPlan} />
            <PrivateRoute path={`/planes/ver-borrador/:id`} exact component={VerBorradorPlan} />
            <PrivateRoute path={`/planes/ver-para-eliminar/:id`} exact component={VerEliminarPlan} />
            <PrivateRoute path={`/planes/aprobar/:id/`} component={VerPlanX} />

               {/* Popup */}
               <PrivateRoute path={`/popup/lista`} component={ListaPopup} />
              <PrivateRoute
                path={`/popup/lista-borradores`}
                component={ListaBorradoresPopup}
              />
              <PrivateRoute path={`/popup/nuevo`} component={NuevoPopup} />
              <PrivateRoute path={`/popup/listax`} component={ListaPopupX} />
              <PrivateRoute path={`/popup/ver/:id`} component={VerPopup} />
              <PrivateRoute
                path={`/popup/ver-borrador/:id`}
                component={VerBorradorPopup}
              />
              <PrivateRoute path={`/popup/aprobar/:id`} component={VerPopupX} />
              <PrivateRoute
                path={`/popup/lista-por-eliminar`}
                component={ListaPorEliminarPopup}
              />
              <PrivateRoute
                path={`/popup/ver-para-eliminar/:id`}
                component={VerParaEliminarPopup}
              />
            

            {/* Configuracion Home Slide */}
            <PrivateRoute path={`/configuracion/home-slide/lista`} component={ListarHomeSlides} />            
            <PrivateRoute path={`/configuracion/home-slide/lista-borradores`} component={ListarBorradorHomeSlides} />  
            <PrivateRoute path={`/configuracion/home-slide/listax`} component={ListarHomeSlidesX} />
            <PrivateRoute path={`/configuracion/home-slide/lista-por-eliminar`} component={ListarPorEliminarHomeSlides} />
            <PrivateRoute path={`/configuracion/home-slide/nuevo`} component={NuevoHomeSlide} />
            <PrivateRoute path={`/configuracion/home-slide/ver/:id`} component={VerHomeSlide} />
            <PrivateRoute path={`/configuracion/home-slide/ver-borrador/:id`} component={VerBorradorHomeSlide} />
            <PrivateRoute path={`/configuracion/home-slide/ver-para-eliminar/:id`} component={VerEliminarHomeSlide} />
            <PrivateRoute path={`/configuracion/home-slide/aprobar/:id`} component={VerHomeSlideX} />

            {/* Configuracion Home Dinamica */}
            <PrivateRoute path={`/configuracion/home-dinamica/lista/`} component={ListaHomeEstudia} />
            <PrivateRoute path={`/configuracion/home-dinamica/lista-borradores/`} component={ListaBorradorHomeEstudia} />
            <PrivateRoute path={`/configuracion/home-dinamica/listax/`} component={ListaHomeEstudiaX} />
            <PrivateRoute path={`/configuracion/home-dinamica/lista-por-eliminar`} component={ListaPorEliminarHomeDinamica} />
            <PrivateRoute path={`/configuracion/home-dinamica/nuevo`} component={NuevaHomeDinamica} />
            <PrivateRoute path={`/configuracion/home-dinamica/ver/:id`} component={VerHomeDinamica} />
            <PrivateRoute path={`/configuracion/home-dinamica/ver-borrador/:id`} component={VerBorradorHomeDinamica} />
            <PrivateRoute path={`/configuracion/home-dinamica/ver/:id/:eliminar`} component={VerHomeDinamica} />
            <PrivateRoute path={`/configuracion/home-dinamica/aprobar/:id`} component={VerHomeDinamicaX} />
            <PrivateRoute path={`/configuracion/home-dinamica/ver-para-eliminar/:id`} component={VerEliminarHomeDinamica} />

            {/* Configuracion Botones Home */}
            <PrivateRoute path={`/configuracion/botones-home/lista/`} component={ListarHomeBotones} />
            <PrivateRoute path={`/configuracion/botones-home/lista-borradores`} component={ListarBorradorHomeBotones} />
            <PrivateRoute path={`/configuracion/botones-home/listax/`} component={ListarHomeBotonesX} />
            <PrivateRoute path={`/configuracion/botones-home/ver/:id`} component={VerHomeBotones} />
            <PrivateRoute path={`/configuracion/botones-home/ver-borrador/:id`} component={VerBorradorHomeBotones} />
            <PrivateRoute path={`/configuracion/botones-home/aprobar/:id`} component={VerHomeBotonesX} />

            {/* Configuracion Informacion Util */}
            <PrivateRoute path={`/configuracion/informacion-util/lista/`} component={ListaInformacionUtil} />
            <PrivateRoute path={`/configuracion/informacion-util/lista-borradores`} component={ListaBorradorInformacionUtil} />
            <PrivateRoute path={`/configuracion/informacion-util/listax/`} component={ListaInformacionUtilX} />
            <PrivateRoute path={`/configuracion/informacion-util/ver/:id`} component={VerInformacionUtil} />
            <PrivateRoute path={`/configuracion/informacion-util/ver-borrador/:id`} component={VerBorradorInformacionUtil} />
            <PrivateRoute path={`/configuracion/informacion-util/aprobar/:id`} component={VerInformacionUtilX} />

            {/* Configuracion datos de contacto */}
            <PrivateRoute path={`/configuracion/datos-contacto/en-revision`} component={DatosContacto} />
            <PrivateRoute path={`/configuracion/datos-contacto/por-aprobar`} component={DatosContacto} />
            <PrivateRoute path={`/configuracion/datos-contacto/publicado`} component={DatosContacto} />                
            
          </Layout>
        {/* <Route path='*' component={ Page404 } /> */}
      </Switch>
    </BrowserRouter>
    <ToastContainer />
  </React.StrictMode>
  </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

