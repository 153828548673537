/* eslint-disable eqeqeq */
import React from "react";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import { Formik, FieldArray } from "formik";
import { Fetch, catchError } from "../../../../utils/fetch";
import { useDispatch, useSelector } from "react-redux";
import MediaUploader from "../../../../utils/media-uploader";
import { AlertInfo } from "../../../../components/alert";
import { loadingAction } from "../../../../redux/actions";
import { validate } from "../validationSchema";
import { EstadoContenido } from "../../../../utils/constants";
import { useHistory } from "react-router-dom";
import { eliminarImagen } from "../../../../utils/tools";

const NuevoHomeSlide = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.loading);
  const permissions = useSelector((state) => state.permissions);
  const [contenido, setContenido] = React.useState(null);

  React.useEffect(() => {
    getContenido();
  }, []);

  const getContenido = async () => {
    try {
      const { data } = await Fetch("home-slides", "get");
      setContenido(data);
    } catch (error) {
      catchError(error);
    }
  };

  const submit = async (values) => {
    if (values.media === "") {
      AlertInfo("Es necesario cargar un archivo.");
      return;
    }

    try {
      values.published_at = null;
      values.estado = EstadoContenido.EnRevision;

      dispatch(loadingAction(true));
      const { data } = await Fetch("home-slides", "post", values);
      dispatch(loadingAction(false));

      AlertInfo(
        "Slide creado con éxito.<br/>Recuerde que falta su confirmación para aprobar.<br/> Nota: Por favor, notificar al responsable de publicar"
      );
      history.push("/configuracion/home-slide/lista-borradores");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const initialValues = {
    tipo: "IMAGEN", //IMAGEN O VIDEO
    media: "",
    enlace: "#",
    destacado: false,
    caption: {
      h1: "",
      h1_2: "",
      h3: "",
      telefono: "",
      botones: [
        {
          nombre: "",
          url: "",
          estilo: "color1",
        },
      ],
    },
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={validate}
        onSubmit={(values, { setSubmitting }) => {
          submit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          setValues,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Card>
                  <Card.Header>Nuevo slide</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Tipo</label>
                          <select
                            name="tipo"
                            onChange={handleChange}
                            className="form-control"
                            value={values.tipo}
                          >
                            <option value="IMAGEN">IMAGEN</option>
                            <option value="VIDEO">VIDEO</option>
                          </select>
                          {errors && errors.tipo && (
                            <div className="text-danger">{errors.tipo}</div>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    {values?.media && Object.keys(values.media).length > 0 && (
                      <>
                        <Row>
                          <Col>
                            <Form.Group>
                              {!values.media.mime.startsWith("video") ? (
                                <img
                                  src={values.media.url}
                                  key={values.media.url}
                                  alt="vista-previa"
                                  className="img-fluid w-50"
                                />
                              ) : (
                                <>
                                  <video
                                    width="50%"
                                    height="auto"
                                    controls
                                    key={values.media.url}
                                  >
                                    Tu navegador no admite el elemento{" "}
                                    <code>video</code>.
                                    <source src={values.media.url} />
                                  </video>
                                </>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Button
                              type="button"
                              style={{ marginBottom: "10px" }}
                              onClick={() => {
                                eliminarImagen(values.media.id)
                                  .then((response) => {
                                    setFieldValue("media", "", false);
                                  })
                                  .catch((error) => {});
                              }}
                              className="btn btn-danger btn-icon mt-2"
                            >
                              <i className="fa fa-trash-o " />
                              Eliminar
                            </Button>
                          </Col>
                        </Row>
                      </>
                    )}
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Seleccionar archivo</label>
                          {values.tipo === "IMAGEN" ? (
                            <>
                              <MediaUploader
                                setFieldValue={setFieldValue}
                                field={"media"}
                                tipoMedia="imagen"
                                ancho={1200}
                                alto={600}
                              />
                              <span className="text-muted">
                                Se recomienda una imagen de 2600px de ancho x
                                450px de alto. En formato JPG o PNG.
                              </span>
                              <div className="text-danger">{errors?.media}</div>
                            </>
                          ) : (
                            <>
                              <MediaUploader
                                value={values?.media ? 100 : 0}
                                key={values?.media}
                                setFieldValue={(name, value) => {
                                  setFieldValue(name, value, false);
                                  setValues({ ...values, [name]: value });
                                }}
                                field={"media"}
                                tipoMedia="video"
                                ancho={1920}
                                alto={1080}
                              />
                              <span className="text-muted">
                                Se recomienda un video de 1920px de ancho x
                                1080px de alto. En formato MP4 ó WEBM.
                              </span>
                              <div className="text-danger">{errors?.media}</div>
                            </>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Enlace</label>
                          <input
                            type="text"
                            name="enlace"
                            className="form-control"
                            onChange={handleChange}
                            value={values?.enlace}
                          />
                          {errors && errors.enlace && (
                            <div className="text-danger">{errors.enlace}</div>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Destacado</label>
                          <select
                            name="destacado"
                            onChange={handleChange}
                            className="form-control"
                            value={values.destacado}
                          >
                            <option value={false} selected>
                              NO
                            </option>
                            {!values?.media?.mime?.startsWith("video") ? (
                              <option value={true}>SI</option>
                            ) : (
                              <></>
                            )}
                          </select>
                          {errors && errors.destacado && (
                            <div className="text-danger">
                              {errors.destacado}
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>

                    {values.destacado === "true" && (
                      <>
                        <Row>
                          <Col>
                            <Form.Group>
                              <label>H1 Texto1</label>
                              <input
                                type="text"
                                name="caption.h1"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control"
                                value={values.caption.h1}
                              />
                              {errors &&
                                errors.caption &&
                                errors.caption.h1 && (
                                  <div className="text-danger">
                                    {errors.caption.h1}
                                  </div>
                                )}
                            </Form.Group>
                          </Col>
                          <Col>
                            <div className="form-group">
                              <label>Estilo</label>
                              <select
                                className="form-control"
                                name={`caption.h1_estilo`}
                                onChange={handleChange}
                                value={
                                  values.caption.h1_estilo
                                }
                              >
                                <option>Seleccionar</option>
                                <option value={"color_0"}>
                                  Color 0
                                </option>
                                <option value={"color_1"}>
                                  Color 1
                                </option>
                                <option value={"color_2"}>
                                  Color 2
                                </option>
                                <option value={"color_3"}>
                                  Color 3
                                </option>
                                <option value={"color_4"}>
                                  Color 4
                                </option>
                                <option value={"color_5"}>
                                  Color 5
                                </option>
                                <option value={"color_6"}>
                                  Color 6
                                </option>
                                <option value={"color_7"}>
                                  Color 7
                                </option>
                              </select>
                              {errors &&
                                errors.caption &&
                                errors.caption.h1_estilo && (
                                  <div className="text-danger">
                                    {
                                      errors.caption
                                        .h1_estilo
                                    }
                                  </div>
                                )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Group>
                              <label>H1 Texto2</label>
                              <input
                                type="text"
                                name="caption.h1_2"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control"
                                value={values?.caption?.h1_2}
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <div className="form-group">
                              <label>Estilo</label>
                              <select
                                className="form-control"
                                name={`caption.h1_2_estilo`}
                                onChange={handleChange}
                                value={
                                  values.caption.h1_2_estilo
                                }
                              >
                                <option>Seleccionar</option>
                                <option value={"color_0"}>
                                  Color 0
                                </option>
                                <option value={"color_1"}>
                                  Color 1
                                </option>
                                <option value={"color_2"}>
                                  Color 2
                                </option>
                                <option value={"color_3"}>
                                  Color 3
                                </option>
                                <option value={"color_4"}>
                                  Color 4
                                </option>
                                <option value={"color_5"}>
                                  Color 5
                                </option>
                                <option value={"color_6"}>
                                  Color 6
                                </option>
                                <option value={"color_7"}>
                                  Color 7
                                </option>
                              </select>
                              {errors &&
                                errors.caption &&
                                errors.caption.h1_2_estilo && (
                                  <div className="text-danger">
                                    {
                                      errors.caption
                                        .h1_2_estilo
                                    }
                                  </div>
                                )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Group>
                              <label>H3 Texto</label>
                              <input
                                type="text"
                                name="caption.h3"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control"
                                value={values.caption.h3}
                              />
                              {errors &&
                                errors.caption &&
                                errors.caption.h3 && (
                                  <div className="text-danger">
                                    {errors.caption.h3}
                                  </div>
                                )}
                            </Form.Group>
                          </Col>
                          <Col>
                            <div className="form-group">
                              <label>Estilo</label>
                              <select
                                className="form-control"
                                name={`caption.h3_estilo`}
                                onChange={handleChange}
                                value={
                                  values.caption.h3_estilo
                                }
                              >
                                <option>Seleccionar</option>
                                <option value={"color_0"}>
                                  Color 0
                                </option>
                                <option value={"color_1"}>
                                  Color 1
                                </option>
                                <option value={"color_2"}>
                                  Color 2
                                </option>
                                <option value={"color_3"}>
                                  Color 3
                                </option>
                                <option value={"color_4"}>
                                  Color 4
                                </option>
                                <option value={"color_5"}>
                                  Color 5
                                </option>
                                <option value={"color_6"}>
                                  Color 6
                                </option>
                                <option value={"color_7"}>
                                  Color 7
                                </option>
                              </select>
                              {errors &&
                                errors.caption &&
                                errors.caption.h3_estilo && (
                                  <div className="text-danger">
                                    {
                                      errors.caption
                                        .h3_estilo
                                    }
                                  </div>
                                )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="form-group">
                              <label>Estilo Barra Separadora</label>
                              <select
                                className="form-control"
                                name={`caption.h1_b_estilo`}
                                onChange={handleChange}
                                value={
                                  values.caption.h1_b_estilo
                                }
                              >
                                <option>Seleccionar</option>
                                <option value={"color_0"}>
                                  Color 0
                                </option>
                                <option value={"color_1"}>
                                  Color 1
                                </option>
                                <option value={"color_2"}>
                                  Color 2
                                </option>
                                <option value={"color_3"}>
                                  Color 3
                                </option>
                                <option value={"color_4"}>
                                  Color 4
                                </option>
                                <option value={"color_5"}>
                                  Color 5
                                </option>
                                <option value={"color_6"}>
                                  Color 6
                                </option>
                                <option value={"color_7"}>
                                  Color 7
                                </option>
                              </select>
                              {errors &&
                                errors.caption &&
                                errors.caption.h1_b_estilo && (
                                  <div className="text-danger">
                                    {
                                      errors.caption
                                        .h1_b_estilo
                                    }
                                  </div>
                                )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Group>
                              <label>Teléfono</label>
                              <input
                                type="text"
                                name="caption.telefono"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control"
                                value={values.caption.telefono}
                              />
                              {errors &&
                                errors.caption &&
                                errors.caption.telefono && (
                                  <div className="text-danger">
                                    {errors.caption.telefono}
                                  </div>
                                )}
                            </Form.Group>
                          </Col>
                          <Col>
                            <div className="form-group">
                              <label>Estilo</label>
                              <select
                                className="form-control"
                                name={`caption.telefono_estilo`}
                                onChange={handleChange}
                                value={
                                  values.caption.telefono_estilo
                                }
                              >
                                <option>Seleccionar</option>
                                <option value={"color_0"}>
                                  Color 0
                                </option>
                                <option value={"color_1"}>
                                  Color 1
                                </option>
                                <option value={"color_2"}>
                                  Color 2
                                </option>
                                <option value={"color_3"}>
                                  Color 3
                                </option>
                                <option value={"color_4"}>
                                  Color 4
                                </option>
                                <option value={"color_5"}>
                                  Color 5
                                </option>
                                <option value={"color_6"}>
                                  Color 6
                                </option>
                                <option value={"color_7"}>
                                  Color 7
                                </option>
                              </select>
                              {errors &&
                                errors.caption &&
                                errors.caption.telefono_estilo && (
                                  <div className="text-danger">
                                    {
                                      errors.caption
                                        .telefono_estilo
                                    }
                                  </div>
                                )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <h2>Botones</h2>

                            <FieldArray name="caption.botones">
                              {({
                                move,
                                swap,
                                push,
                                remove,
                                insert,
                                unshift,
                                pop,
                                form,
                              }) => {
                                return (
                                  <>
                                    {values?.caption?.botones &&
                                      values?.caption?.botones.length > 0 &&
                                      values?.caption?.botones.map(
                                        (data, index) => {
                                          return (
                                            <Row className="border m-3">
                                              <Col
                                                lg={12}
                                                className="p-0 d-flex justify-content-end"
                                              >
                                                <button
                                                  type="button"
                                                  className="btn btn-danger"
                                                  onClick={() => remove(index)}
                                                >
                                                  x
                                                </button>
                                              </Col>

                                              <Col>
                                                <div className="form-group">
                                                  <label>Nombre</label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    name={`caption.botones.${index}.nombre`}
                                                    onChange={handleChange}
                                                    value={
                                                      values.caption.botones[
                                                        index
                                                      ]?.nombre
                                                    }
                                                  />
                                                  {errors &&
                                                    errors.caption &&
                                                    errors.caption.botones &&
                                                    errors.caption.botones[
                                                      index
                                                    ] &&
                                                    errors.caption.botones[
                                                      index
                                                    ].nombre && (
                                                      <div className="text-danger">
                                                        {
                                                          errors.caption
                                                            .botones[index]
                                                            .nombre
                                                        }
                                                      </div>
                                                    )}
                                                </div>
                                              </Col>
                                              <Col>
                                                <div className="form-group">
                                                  <label>Url</label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    name={`caption.botones.${index}.url`}
                                                    onChange={handleChange}
                                                    value={
                                                      values.caption.botones[
                                                        index
                                                      ]?.url
                                                    }
                                                  />
                                                  {errors &&
                                                    errors.caption &&
                                                    errors.caption.botones &&
                                                    errors.caption.botones[
                                                      index
                                                    ] &&
                                                    errors.caption.botones[
                                                      index
                                                    ].url && (
                                                      <div className="text-danger">
                                                        {
                                                          errors.caption
                                                            .botones[index].url
                                                        }
                                                      </div>
                                                    )}
                                                </div>
                                              </Col>
                                              <Col>
                                                <div className="form-group">
                                                  <label>Estilo</label>
                                                  <select
                                                    className="form-control"
                                                    name={`caption.botones.${index}.estilo`}
                                                    onChange={handleChange}
                                                    value={
                                                      values.caption.botones[
                                                        index
                                                      ]?.estilo
                                                    }
                                                  >
                                                    <option>Seleccionar</option>
                                                    <option value={"color1"}>
                                                      Color 1
                                                    </option>
                                                    <option value={"color2"}>
                                                      Color 2
                                                    </option>
                                                    <option value={"color3"}>
                                                      Color 3
                                                    </option>
                                                    <option value={"color4"}>
                                                      Color 4
                                                    </option>
                                                    <option value={"color5"}>
                                                      Color 5
                                                    </option>
                                                    <option value={"color6"}>
                                                      Color 6
                                                    </option>
                                                    <option value={"color7"}>
                                                      Color 7
                                                    </option>
                                                  </select>
                                                  {errors &&
                                                    errors.caption &&
                                                    errors.caption.botones &&
                                                    errors.caption.botones[
                                                      index
                                                    ] &&
                                                    errors.caption.botones[
                                                      index
                                                    ].estilo && (
                                                      <div className="text-danger">
                                                        {
                                                          errors.caption
                                                            .botones[index]
                                                            .estilo
                                                        }
                                                      </div>
                                                    )}
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                      )}
                                    <button
                                      type="button"
                                      className="btn btn-secondary"
                                      onClick={() =>
                                        push({
                                          nombre: "",
                                          url: "#",
                                          estilo: "",
                                        })
                                      }
                                    >
                                      Añadir Item
                                    </button>
                                  </>
                                );
                              }}
                            </FieldArray>
                          </Col>
                        </Row>
                      </>
                    )}

                    <Button
                      type="button"
                      onClick={() => handleSubmit()}
                      disabled={isLoading}
                      className="btn btn-success btn-icon mt-2"
                    >
                      <i className="fa fa-floppy-o " />
                      Guardar
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
};
export default NuevoHomeSlide;
