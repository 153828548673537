import * as Yup from 'yup';

export const validate = Yup.object().shape({
    nombre: Yup.string().required('Campo Obligatorio'),
    template1: Yup.object().shape({
        titulo: Yup.string().required('Campo Obligatorio'),
        contenido: Yup.string().required('Campo Obligatorio'),
        imagen: Yup.object({}).required('Campo Obligatorio').nullable(),
    })
});
    