/* eslint-disable eqeqeq */
import React, { useRef, useState } from "react";
import { AlertError, AlertInfo, AlertSuccess } from "../components/alert";
import { BASE_URL } from "./constants";
import { useDispatch } from "react-redux";
import { loadingAction } from "../redux/actions";
function MediaUploader({
  defaultImage,
  setTmpPhoto,
  setFieldValue,
  field,
  tipoMedia,
  ancho,
  alto,
  value,
}) {
  const dispatch = useDispatch();
  const dropbox = useRef(null);
  const fileSelect = useRef(null);
  const [image] = useState(defaultImage);
  const [progress, setProgress] = useState(value);

  function handleFiles(files) {
    let puedeSubirMedia = true;
    let reader = new FileReader();

    //VERIFICAR EL TIPO DE CONTENIDO
    for (let i = 0; i < files.length; i++) {
      let archivo = files[i];

      if (tipoMedia === "imagen") {
        if (archivo.type !== "image/jpeg" && archivo.type !== "image/png") {
          AlertInfo(
            "Tipo de imagen no permitida<br/>Favor verificar que sea JPG o PNG"
          );
          puedeSubirMedia = false;
        }
      } else {
        if (archivo.type !== "video/mp4" && archivo.type !== "video/webm") {
          AlertInfo(
            "Tipo de video no permitido<br/>Favor verificar que sea MP4 o WEBM"
          );
          puedeSubirMedia = false;
        }
      }

      reader.readAsDataURL(archivo);

      reader.onload = function () {
        if (tipoMedia === "imagen") {
          let imagen = document.createElement("img");

          imagen.src = reader.result;

          //VERIFICAR DIMENSIONES
          imagen.onload = function () {
            var w = this.width;
            var h = this.height;

            if (puedeSubirMedia) {
              for (let i = 0; i < files.length; i++) {
                uploadFile(files[i]);
              }
            }
          };
        } else {
          let video = document.createElement("video");
          video.src = reader.result;

          video.addEventListener(
            "loadedmetadata",
            function (e) {
              var w = this.videoWidth;
              var h = this.videoHeight;
              
              if (puedeSubirMedia) {
                for (let i = 0; i < files.length; i++) {
                  uploadFile(files[i]);
                }
              }
            },
            false
          );
        }
      };
    }
  }

  function uploadFile(file) {
    dispatch(loadingAction(true));

    const token = localStorage.getItem("jwt_token");
    const url = `${BASE_URL}/upload`;
    const xhr = new XMLHttpRequest();
    const fd = new FormData();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Authorization", "Bearer " + token);

    // Update progress (can be used to show progress indicator)
    xhr.upload.addEventListener("progress", (e) => {
      setProgress(Math.round((e.loaded * 100.0) / e.total));
    });

    xhr.onreadystatechange = (e) => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        const response = JSON.parse(xhr.responseText);
        // setImage(response.secure_url);
        // setTmpPhoto(response.secure_url);
        setFieldValue(field, response[0], false);
        dispatch(loadingAction(false));
        AlertSuccess("Imagen cargada con éxito");
      } else {
        dispatch(loadingAction(false));
        AlertError("Se presentó un problema al subir la imagen");
      }
    };
    fd.append("files", file);
    xhr.send(fd);
  }

  return (
    <div ref={dropbox}>
      {image ? (
        <></>
      ) : (
        <div className="card">
          <form className="d-flex">
            <div className="justify-content-center">
              <input
                ref={fileSelect}
                type="file"
                accept={
                  tipoMedia === "imagen"
                    ? "image/jpeg,image/png"
                    : "video/mp4,video/webm"
                }
                onChange={(e) => handleFiles(e.target.files)}
              />
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default MediaUploader;
