import {Fetch} from './fetch'
export const generarSlug = async (controller, field, value) =>{

    const dataToApi = {
        contentTypeUID: "application::"+controller+"."+controller,
        field: "slug",
        data: {
          [field]: value,
          slug: ""
        }
      }
      try {
        const { data } = await Fetch("content-manager/uid/generate", "post", dataToApi);
        return data;
      } catch (error) {
        console.error('mostrando error', error)
      }
}

export const copyToClickBoard =(content)=>{
  navigator.clipboard.writeText(content)
  .then(() => {
      
  })
  .catch(err => {      
  })
}