/* eslint-disable react-hooks/exhaustive-deps */
import React,{useMemo} from 'react';
import { Form, Row, Col, Button, Card } from 'react-bootstrap';
import JoditEditor,{Jodit} from "jodit-react";
import { useFormik } from 'formik';
import { Fetch, catchError } from '../../../utils/fetch'
import { useDispatch, useSelector } from 'react-redux';
import MediaUploader from '../../../utils/media-uploader'
import { AlertSuccess, AlertInfo } from '../../../components/alert';
import { loadingAction } from '../../../redux/actions'
import { useHistory, useParams } from 'react-router-dom';
import { validate } from '../validationSchema'
import { EstadoContenido } from '../../../utils/constants';
import { eliminarImagen, removeSpecial } from '../../../utils/tools';
import LayoutJodit from '../../../components/customizer/layout-jodit';

const VerPromocionX = () => {

    const params = useParams()
    const history = useHistory();

    const id = params.id;
    const dispatch = useDispatch()
    const isLoading = useSelector(state => state.loading)
    const permissions = useSelector(state => state.permissions)
    const [layoutJodit, setLayoutJodit] = React.useState("PC");
    //const canPublish = permissions.novedades.publicar.enabled;

    const config = useMemo(
        () => ({
        readonly: false, // all options from https://xdsoft.net/jodit/doc/
        language: 'es',disablePlugins:['table'],
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        defaultActionOnPaste: "insert_clear_html",
        enableDragAndDropFileToEditor: true,
        uploader: {
            url: process.env.REACT_APP_BASE_URL + '/upload',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt_token')
            },
            data: null,
            filesVariableName: function (i) {
                return "files"
            },
            withCredentials: false,
            pathVariableName: "path",
            format: "json",
            method: "POST",
            prepareData: function (formData) {
                formData.append('id', 1);

            },
            isSuccess: function (resp) {
                return !resp.error;
            },
            getMessage: function (resp) {
                return resp.msgs.join('\n');
            },
            process: function (resp) {
                return resp;
            },
            defaultHandlerSuccess: function (resp) {
                var imagenesPermitidas=["image/jpeg","image/png"];
                
                for (var i = 0; i < resp.length; i++) {
                    if(imagenesPermitidas.indexOf(resp[i].mime)!==-1)          
                        {
                            var img1 = new Image();
                            img1.src = resp[i]['url'];
                            img1.alt = 'imagen';
                            img1.className = "img-fluid"
                            this.s.insertImage(img1);
                        }else if(resp[i].mime==="application/pdf"){
                            const enlace = document.createElement('a');
                            enlace.setAttribute('href', resp[i].url);
                            const contenido = document.createTextNode(resp[i].name);
                            // add the text node to the newly created div
                            enlace.appendChild(contenido);
                            this.s.insertNode(enlace);
                        }else{
                            AlertInfo('Tipo de archivo no permitido');
                        }
                }
            }
        }
    }),[]);

    React.useEffect(() => {
        if (id) {
            getNovedadesById()
        }
    }, [])

    const getNovedadesById = async (values) => {
        // ?_publicationState=preview&published_at_null=true
        dispatch(loadingAction(true));
        try {

            const { data } = await Fetch(`promociones/${id}?_publicationState=preview&published_at_null=true`, "get");

            setValues(data)
            dispatch(loadingAction(false));
        } catch (error) {
            catchError(error)
            dispatch(loadingAction(false));
        }
    }


    const submit = async (values) => {
        dispatch(loadingAction(true));

        values.published_at = new Date();
        values.estado = EstadoContenido.Publicado

        values.descripcion=removeSpecial(values.descripcion);

        //delete values.imagen.id;
        const idPadre = values.idPadre;
        const idToApi = idPadre ? idPadre : id;

        try {

            const { data } = await Fetch("promociones/aprobar/" + idToApi, "put", values);

            dispatch(loadingAction(false));

            AlertSuccess('Promoción actualizada con éxito')

            history.push("/promociones/lista");

        } catch (error) {
            catchError(error)
            dispatch(loadingAction(false));
        }
    }

    const onCancelar = async () => {

        dispatch(loadingAction(true));


        try {

            const params = {
                published_at: null,
                estado:EstadoContenido.EnRevision
            }

            const { data } = await Fetch("promociones/" + id, "put", params);

            dispatch(loadingAction(false));
            AlertSuccess('Se ha cancelado la publicación de la promoción con éxito')

            history.push("/promociones/lista-borradores");

        } catch (error) {
            dispatch(loadingAction(false));
            catchError(error)
        }
    };

    const { values, errors, handleSubmit, handleChange, setFieldValue, setValues } = useFormik({
        initialValues: {
            titulo: "",            
            descripcion: "",
            imagen: {},            
            slug: "",
            orden: 0,
            habilitado: true,
            locale: "es",
        },
        enableReinitialize: true,
        validationSchema: validate,
        onSubmit: (values) => submit(values)
    })

    const handleBlurTitulo = async (event) => {
        const value = event.target.value;
        const name = event.target.name;
        const dataToApi = {
            contentTypeUID: "application::promociones.promociones",
            field: "slug",
            data: {
                titulo: value,
                slug: ""
            }
        }
        setFieldValue(name, value, false)
        try {
            const { data } = await Fetch("content-manager/uid/generate", "post", dataToApi);
            setFieldValue('slug', data.data, false)
        } catch (error) {
            console.error('mostrando error', error)
        }
    }

    const handleChangeJoiEditor = (data, field) => {
        setFieldValue(field, data, false);
    }

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <Card>
                            <Card.Header>
                            Información de la promoción
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <label>Título de la promoción</label>
                                            <input type="text" name="titulo" onChange={handleChange} onBlur={(e) => handleBlurTitulo(e)} value={values.titulo} className="form-control " />
                                            {errors && errors?.titulo && <div className="text-danger">{errors?.titulo} </div>}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <label>Url</label>
                                            <input type="text" name="slug" className="form-control" value={values.slug} readOnly />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {values?.imagen && Object.keys(values.imagen).length > 0 &&
                                   <>
                                   <Row>
                                     <Col>
                                       <Form.Group>
                                         <img
                                           src={values.imagen.url}
                                           alt="vista-previa"
                                           className="img-fluid w-50"
                                         />
                                       </Form.Group>
                                     </Col>
                                   </Row>
                                   <Row>
                                     <Col>
                                       <Button
                                         type="button"
                                         style={{ marginBottom: "10px" }}
                                         onClick={() => {
                                           eliminarImagen(values.imagen.id).then(
                                             (response) => {
                                               setFieldValue("imagen", "", false);
                                             }
                                           ).catch(error=>{}); 
                                         }}
                                         className="btn btn-danger btn-icon mt-2"
                                       >
                                         <i className="fa fa-trash-o " />
                                         Eliminar
                                       </Button>
                                     </Col>
                                   </Row>
                                 </>
                                }

                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <label>Imagen</label>
                                            <MediaUploader value={values?.imagen?100:0} key={values?.imagen} setFieldValue={(name,value)=>{setFieldValue(name,value,false);setValues({...values,[name]:value})}} field={'imagen'} tipoMedia='imagen' ancho={600} alto={250} />
                                            {errors && errors?.imagen && <div className="text-danger">{errors?.imagen} </div>}
                                            <span className="text-muted">Se recomienda una imagen de 600px de ancho x 250px de alto. Imagen solo visible en la miniatura de la promoción.</span>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col>
                                        <h3>Información del contenido</h3>
                                    </Col>
                                </Row>                               
                                <Row>
                                    <Col className={`${layoutJodit == "MOBILE" && "col-6"}`}>
                                        <Form.Group>
                                            <label>Descripción</label>
                                            <LayoutJodit
                                                setLayoutJodit={setLayoutJodit}
                                                layoutJodit={layoutJodit}
                                            />   
                                            <JoditEditor
                                                config={config}
                                                value={values.descripcion}
                                                tabIndex={1} // tabIndex of textarea
                                                onBlur={newContent => handleChangeJoiEditor(newContent, 'descripcion')}
                                            />
                                            {errors && errors?.descripcion && <div className="text-danger">{errors?.descripcion} </div>}
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className="mt-5">
                                    <Col className="col col-lg-2">
                                        <Button type="button" onClick={() => handleSubmit()} disabled={isLoading} className="btn btn-success btn-icon" ><i className="fa fa-floppy-o" />Publicar</Button>
                                    </Col>
                                    <Col className="col col-lg-3">
                                        <Button type="button" onClick={() => onCancelar()} disabled={isLoading} className="btn btn-primary btn-icon" ><i className="fa fa-undo " />Por revisar</Button>
                                    </Col>
                                </Row>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>


            </form>
        </React.Fragment>
    );
}
export default VerPromocionX;