/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import { Form, Row, Col, Button, Card } from 'react-bootstrap';
import JoditEditor, { Jodit } from "jodit-react";
import { useFormik } from 'formik';
import { Fetch, catchError } from '../../../../utils/fetch'
import { useDispatch, useSelector } from 'react-redux';
import MediaUploader from '../../../../utils/media-uploader'
import { AlertConfirm, AlertInfo, AlertSuccess } from '../../../../components/alert';
import { loadingAction } from '../../../../redux/actions'
import { useHistory, useParams } from 'react-router-dom';
import ModalDegradadosComponent from '../../../../components/modal-degradados';
import ModalComponent from '../../../../components/modal';
import { validate } from '../validationSchema'
import { EstadoContenido } from '../../../../utils/constants';
import { removeSpecial } from '../../../../utils/tools';
import LayoutJodit from '../../../../components/customizer/layout-jodit';

const VerEliminarHomeDinamica = () => {

    const { id, eliminar } = useParams();
    const dispatch = useDispatch()
    const history = useHistory()
    const isLoading = useSelector(state => state.loading)
    const [layoutJodit, setLayoutJodit] = React.useState("PC");
    // function preparePaste(jodit) {
    //     jodit.e.on(
    //         'paste',
    //         e => {

    //             jodit.e.stopPropagation('paste');
    //             let plainText = Jodit.modules.Helpers.getDataTransfer(e).getData(Jodit.constants.TEXT_PLAIN);plainText=removeSpecial(plainText);
    //             // plainText = plainText.replace(/(^|;)\s*font-[^;]+/g, "");
    //             // let regex = /<(?!(\/\s*)?(div|b|i|em|strong|u|p)[>,\s])([^>])*>/g;
    //             // plainText = plainText.replace(regex, '');
    //             plainText = plainText.normalize('NFKC').replaceAll("\n", "<br/>");

    //             jodit.s.insertHTML(
    //                 plainText
    //             );
    //             return false;

    //         },
    //         { top: true }
    //     );
    // }
    // Jodit.plugins.add('preparePaste', preparePaste);

    const config = useMemo(
        () => ({
            readonly: false, // all options from https://xdsoft.net/jodit/doc/
            language: 'es', disablePlugins: ['table'],
            askBeforePasteHTML: false,
            askBeforePasteFromWord: false,
            defaultActionOnPaste: "insert_clear_html",
            enableDragAndDropFileToEditor: true,
            uploader: {
                url: process.env.REACT_APP_BASE_URL + '/upload',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt_token')
                },
                data: null,
                filesVariableName: function (i) {
                    return "files"
                },
                withCredentials: false,
                pathVariableName: "path",
                format: "json",
                method: "POST",
                prepareData: function (formData) {
                    formData.append('id', 1);

                },
                isSuccess: function (resp) {
                    return !resp.error;
                },
                getMessage: function (resp) {
                    return resp.msgs.join('\n');
                },
                process: function (resp) {
                    return resp;
                },
                defaultHandlerSuccess: function (resp) {
                    var imagenesPermitidas = ["image/jpeg", "image/png"];
                    for (var i = 0; i < resp.length; i++) {
                        if (imagenesPermitidas.indexOf(resp[i].mime) !== -1) {
                            var img1 = new Image();
                            img1.src = resp[i]['url'];
                            img1.alt = 'imagen';
                            img1.className = "img-fluid"
                            this.s.insertImage(img1);
                        } else if (resp[i].mime === "application/pdf") {
                            const enlace = document.createElement('a');
                            enlace.setAttribute('href', resp[i].url);
                            const contenido = document.createTextNode(resp[i].name);
                            // add the text node to the newly created div
                            enlace.appendChild(contenido);
                            this.s.insertNode(enlace);
                        } else {
                            AlertInfo('Tipo de archivo no permitido');
                        }
                    }
                }
            }
        }), [])

    const [showModal, setShowModal] = React.useState(false)
    const [showModalColores, setShowModalColores] = React.useState(false)
    const permissions = useSelector(state => state.permissions)
    //const canPublish = permissions['seccion-home-dinamica'].publicar.enabled;

    React.useEffect(() => {
        if (id) {
            getHomeEstudia()
        }
    }, [id])

    const getHomeEstudia = async (values) => {
        dispatch(loadingAction(true));

        try {
            const { data } = await Fetch("seccion-home-dinamicas/" + id, "get");
            setValues(data)
            dispatch(loadingAction(false));
        } catch (error) {
            catchError(error)
            dispatch(loadingAction(false));
        }
    }

    const submit = async (values) => {
        dispatch(loadingAction(true));

        try {

            const { data } = await Fetch("seccion-home-dinamicas/" + id, "delete", values);

            dispatch(loadingAction(false));
            AlertSuccess('Sección eliminado con éxito')


            history.push("/configuracion/home-dinamica/lista");
        } catch (error) {
            catchError(error)
            dispatch(loadingAction(false));
        }
    }

    const onCancelar = async (values2, type) => {

        dispatch(loadingAction(true));


        try {
            const params = {
                eliminado: false
            }

            if (values.estado == EstadoContenido.EnRevision) {
                params.published_at = null;
            }

            const { data } = await Fetch("seccion-home-dinamicas/" + id, "put", params);

            dispatch(loadingAction(false));
            AlertSuccess('Se ha cancelado la eliminación de la sección con éxito')

            //history.push("/configuracion/home-dinamica/lista");

            if (values.estado == EstadoContenido.EnRevision) {
                history.push("/configuracion/home-dinamica/lista-borradores");
            } else {
                history.push("/configuracion/home-dinamica/lista");
            }

        } catch (error) {
            dispatch(loadingAction(false));
            catchError(error)
        }
    };

    const { values, errors, handleSubmit, handleChange, handleBlur, setFieldValue, setValues } = useFormik({
        initialValues: {
            titulo: "",
            descripcion: "",
            degradado: "degradado1",
            orientacion_foto: "izquierda",
            foto: {},
            boton: {
                nombre: "",
                url: "",
                estilo: "color1"
            },
            locale: 'es'
        },
        enableReinitialize: true,
        validationSchema: validate,
        onSubmit: (values) => submit(values)
    })


    const handleChangeJoiEditor = (data, field) => {
        setFieldValue(field, data, false);
    }

    const handleShowColors = () => {
        setShowModal(true);
    }



    return (
        <React.Fragment>
            <form onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <ModalDegradadosComponent isShow={showModal} setShowModal={setShowModal} />
                        <ModalComponent isShow={showModalColores} setShowModal={setShowModalColores} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <Card.Header>
                                Información de home dinámica                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <label>Título</label>
                                            <input type="text" name="titulo" onChange={handleChange} onBlur={handleBlur} className="form-control" value={values.titulo} />
                                            {errors &&
                                                errors.titulo &&
                                                (<div className="text-danger">{errors.titulo}</div>)}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={`${layoutJodit == "MOBILE" && "col-6"}`}>
                                        <Form.Group>
                                            <label>Descripción</label>
                                            <LayoutJodit
                                                    setLayoutJodit={setLayoutJodit}
                                                    layoutJodit={layoutJodit}
                                                />   
                                            <JoditEditor
                                                config={config}
                                                value={values.descripcion}
                                                tabIndex={1} // tabIndex of textarea
                                                onBlur={newContent => handleChangeJoiEditor(newContent, 'descripcion')}
                                            />
                                            {errors &&
                                                errors.descripcion &&
                                                (<div className="text-danger">{errors.descripcion}</div>)}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {values.foto && Object.keys(values.foto).length > 0 &&
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <img src={values.foto.url} alt="vista-previa" className="img-fluid w-50" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                }
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <label>Foto</label>
                                            <MediaUploader setFieldValue={(name,value)=>{setFieldValue(name,value,false);setValues({...values,[name]:value})}} field={'foto'} tipoMedia='imagen' ancho={300} alto={300} />
                                            {errors &&
                                                errors.foto &&
                                                (<div className="text-danger">{errors.foto}</div>)}
                                            <span className="text-muted">Se recomienda una imagen de 300px de ancho x 300px de alto</span>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <label>Degradado</label>
                                            <button type="button" onClick={() => handleShowColors()} className="btn btn-info btn-xs me-2 btn-rounded">?</button>
                                            <select name="degradado" onChange={handleChange} className="form-control" value={values.degradado}>
                                                <option>Seleccionar</option>
                                                <option value="degradado1">Degradado 1</option>
                                                <option value="degradado2">Degradado 2</option>
                                                <option value="degradado3">Degradado 3</option>
                                                <option value="degradado4">Degradado 4</option>
                                            </select>
                                            {errors &&
                                                errors.degradado &&
                                                (<div className="text-danger">{errors.degradado}</div>)}
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <label>Orientación de la foto</label>
                                            <select name="orientacion_foto" onChange={handleChange} className="form-control" value={values.orientacion_foto}>
                                                <option>Seleccionar</option>
                                                <option value="izquierda">Izquierda</option>
                                                <option value="derecha">Derecha</option>
                                            </select>
                                            {errors &&
                                                errors.orientacion_foto &&
                                                (<div className="text-danger">{errors.orientacion_foto}</div>)}
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <label>Botón</label>
                                            <input type="text" name="boton.nombre" onChange={handleChange} onBlur={handleBlur} className="form-control" value={values.boton.nombre} />
                                            {errors &&
                                                errors.boton &&
                                                errors.boton.nombre &&
                                                (<div className="text-danger">{errors.boton.nombre}</div>)}
                                            <label>Url</label>
                                            <input type="text" name="boton.url" onChange={handleChange} onBlur={handleBlur} className="form-control" value={values.boton.url} />
                                            {errors &&
                                                errors.boton &&
                                                errors.boton.url &&
                                                (<div className="text-danger">{errors.boton.url}</div>)}
                                            <label>Estilo</label>
                                            <button type="button" onClick={() => setShowModalColores(true)} className="btn btn-info btn-xs me-2 btn-rounded">?</button>
                                            <select name="boton.estilo" onChange={handleChange} className="form-control" value={values.boton.estilo}>
                                                <option>Seleccionar</option>
                                                <option value="color1">Color 1</option>
                                                <option value="color2">Color 2</option>
                                                <option value="color3">Color 3</option>
                                                <option value="color4">Color 4</option>
                                                <option value="color5">Color 5</option>
                                                <option value="color6">Color 6</option>
                                                <option value="color7">Color 7</option>
                                            </select>
                                            {errors &&
                                                errors.boton &&
                                                errors.boton.estilo &&
                                                (<div className="text-danger">{errors.boton.estilo}</div>)}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {/* <Button type="button" onClick={() => handleSubmit()} disabled={isLoading} className="btn btn-success btn-icon mt-2" ><i className="fa fa-floppy-o " />Guardar</Button> */}

                                <Row className="mt-5">
                                    <Col className="col col-lg-2">
                                        <Button
                                            type="button"
                                            onClick={() => handleSubmit()}
                                            disabled={isLoading}
                                            className="btn btn-danger btn-icon"
                                        >
                                            <i className="fas fa-trash" />
                                            Eliminar
                                        </Button>
                                    </Col>
                                    <Col className="col col-lg-3">
                                        <Button
                                            type="button"
                                            onClick={() => onCancelar()}
                                            disabled={isLoading}
                                            className="btn btn-primary btn-icon"
                                        >
                                            <i className="fas fa-undo" />
                                            Cancelar
                                        </Button>
                                    </Col>
                                </Row>


                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </form>
        </React.Fragment>
    );
}
export default VerEliminarHomeDinamica;