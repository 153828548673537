import React from 'react';
import { Row, Col, Card, Modal, Button } from 'react-bootstrap';
import { Fetch, catchError } from '../../utils/fetch'
import { connect } from 'react-redux'
import { loadingAction } from '../../redux/actions';
import DataGridForm from '../../components/common/DataGridForm';
import { AlertInfo } from '../../components/alert';

function Cartilla({ isLoadingAction }) {

    const [contenido, setContenido] = React.useState(null)
    const [state, setState] = React.useState({
        visibleModal: false,
        file: null
    })

    React.useEffect(() => {
        getContenido()
    }, [])

    const formatDate=(date)=>{        
        return date.getDate().toString().padStart(2,"0")+"/"+(date.getMonth()+1).toString().padStart(2,"0")+"/"+date.getFullYear()+" "+date.getHours().toString().padStart(2,"0")+":"+date.getMinutes().toString().padStart(2,"0")+":"+date.getSeconds().toString().padStart(2,"0");
    }

    const getContenido = async () => {
        isLoadingAction(true)
        try {
            //const { data } = await Fetch('external-cartilla/getFilesUpload', 'post')     
            //console.log(data?.data?.files)      
            

            const { data } = await Fetch('logs?_sort=updated_at:DESC', 'get')                          
            setContenido(data.map((item)=>{
                var result=item.user;
                result.actualizado=formatDate(new Date(item.updated_at))//.substring(0,20).replace("T"," ");
                return result;
            }))
            isLoadingAction(false)
        } catch (error) {
            console.error('mostrando error', error)
            isLoadingAction(false)
            catchError(error);
        }
    }

    const VerificarArchivo = (f) => {
        let archivo = f.target;

        const tipoArchivos = ['.csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']

        if (!tipoArchivos.includes(archivo.files[0].type)) {
            archivo.value = null;
            setState({ ...state, file: null })
            AlertInfo('No es un archivo permitido')
        } else {            
            convertBase64(archivo.files[0])
            //setState({ ...state, file:archivo.files[0] })
        }
        

    }

    const convertBase64 = (file) => {       
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            const data= reader.result.split(',')            
            setState({ ...state, file:data[1] })
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    };

    const CargarArchivo = async () => {

        try {

            if (state.file === null) {
                AlertInfo('Debe de seleccionar un archivo a cargar')
                return false
            }

            isLoadingAction(true)
            const formData = new FormData();
            formData.append("archivo", state.file);     
            //formData.append("id_Customer",sessionStorage.getItem("id_Customer"));      
            const { data } = await Fetch('external-cartilla/upload', 'post', formData,{ContentType:"multipart/form-data",'Access-Control-Allow-Origin': '*'})
            //isLoadingAction(false)

            setState({ ...state, visibleModal: false })                        
            getContenido();
            if(!data.error)
            {
                AlertInfo('Archivo cargado correctamente')
            }
            // else{
            //     AlertInfo('No se ha podido cargar el archivo, favor revise el formato')
            // }
        } catch (error) {
            isLoadingAction(false)            
            //catchError(error);
            AlertInfo(error.response.data.message)
        }
    }

    return (
        <React.Fragment>
            <Row>
                <Col md="12">
                    <Card>
                        <Card.Header>
                            Cartilla
                        </Card.Header>
                        <Card.Body>
                            <div className='d-flex justify-content-end align-item-center'>
                                <span role="button" onClick={() => setState({ ...state, visibleModal: true, file:null })}><i className='fa fa-plus'></i> Añadir archivo</span>
                            </div>
                            <DataGridForm dataSource={contenido}
                                keyExpr='id'
                                columnHidingEnabled={true}
                                columns={[                                    
                                    { caption: 'Usuario', dataField: 'username' },
                                    { caption: 'Correo', dataField: 'email' },
                                    { caption: 'Fecha de Modificación', dataField: 'actualizado' },                                    
                                ]} />

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Modal show={state.visibleModal} onHide={() => setState({ ...state, visibleModal: false })}>
                <Modal.Header closeButton>
                    <Modal.Title>Carga de archivo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col><b>Archivo</b></Col>
                    </Row>
                    <Row>
                        <Col><input type={"file"} onChange={VerificarArchivo} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" /></Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setState({ ...state, visibleModal: false })}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={CargarArchivo}>
                        Cargar
                    </Button>
                </Modal.Footer>
            </Modal>

        </React.Fragment>
    )
}

const mapStateToProps = (state) => {

    return {
        isLoading: state.loading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        isLoadingAction: (data) => { dispatch(loadingAction(data)) }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Cartilla);