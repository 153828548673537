/* eslint-disable eqeqeq */
import React, { useRef, useState } from "react";
import { AlertError, AlertInfo, AlertSuccess } from "../components/alert";
import { BASE_URL } from "./constants";
import { useDispatch } from 'react-redux';
import { loadingAction } from '../redux/actions'
function ImageUploader({ defaultImage, setTmpPhoto, setFieldValue, field }) {
  const dispatch = useDispatch()
  const dropbox = useRef(null);
  const fileSelect = useRef(null);
  const [image] = useState(defaultImage);
  const [progress, setProgress] = useState(0);

  function handleFiles(files) {
    
    let reader = new FileReader();

    // for (let i = 0; i < files.length; i++) {
    //   let archivo = files[i];

    //   if (archivo.type !== 'image/jpeg' && archivo.type !== 'image/png') {
    //     AlertInfo('Tipo de archivo no permitido, favor verificar')
    //     return;
    //   }

    //   reader.readAsDataURL(archivo);

    //   reader.onload = function () {
    //     let imagen = document.createElement('img');

    //     imagen.src = reader.result;

    //     imagen.onload = function () {
    //       var w = this.width;
    //       var h = this.height;

    //       if (w !== 300 && h !== 370) {
    //         AlertInfo('Dimensiones de la imagen no permitido, favor verificar')
    //         return;
    //       }
    //     }


    //   };

    // }
    
    for (let i = 0; i < files.length; i++) {
      uploadFile(files[i]);
    }
  }
  //CLOUDINARY_URL=cloudinary://738935444425391:2zIbRpuh9NXURcL44EYgOsuLnNg@de8ncxim7
  function uploadFile(file) {
    dispatch(loadingAction(true));
    const token = localStorage.getItem('jwt_token')
    const url = `${BASE_URL}/upload`;
    const xhr = new XMLHttpRequest();
    const fd = new FormData();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Authorization", "Bearer " + token);

    // Update progress (can be used to show progress indicator)
    xhr.upload.addEventListener("progress", (e) => {
      setProgress(Math.round((e.loaded * 100.0) / e.total));
    });

    xhr.onreadystatechange = (e) => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        const response = JSON.parse(xhr.responseText);
        // setImage(response.secure_url);
        // setTmpPhoto(response.secure_url);
        setFieldValue(field, response[0], false);
        dispatch(loadingAction(false));
        AlertSuccess('Imagen cargada con éxito')
      } else {
        dispatch(loadingAction(false));
        AlertError('Se presentó un problema al subir la imagen')
      }
    };
    fd.append("files", file);
    xhr.send(fd);
  }


  return (
    <div ref={dropbox}>
      {image ? (
        <>

        </>
      ) : (
        <div
          className="card"

        >
          <form className="d-flex">
            {progress === 0 ? (
              <div className="justify-content-center">
                {/* <button
                  className="btn btn-info rounded block m-auto"
                  onClick={handleImageUpload}
                  type="button"
                >
                  Browse Image
                </button> */}
                <input
                  ref={fileSelect}
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFiles(e.target.files)}
                />
              </div>
            ) : (
              <span className="text-gray-700">{progress}%</span>
            )}


          </form>
        </div>
      )}
    </div>
  );
}

export default ImageUploader;