import React from 'react';
import { Row, Col, Card, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Fetch, catchError } from '../../../utils/fetch'
import { AlertConfirm } from '../../../components/alert'
import { connect } from 'react-redux'
import { loadingAction } from '../../../redux/actions';
import { formatDate } from '../../../utils/formatDate';
import DataGridForm from '../../../components/common/DataGridForm';

function PromocionesPlanes({ isLoadingAction, isLoading,promosPlan,setPromosPlan }) {
    const [contenidoPromociones, setContenidoPromociones] = React.useState(null)
    //const [promos,setPromos]=React.useState([...promosplan]);
    React.useEffect(() => {
        getContenido()
    }, [])

    const getContenido = async () => {
        isLoadingAction(true)
        try {
            const { data } = await Fetch('promociones?_eliminado=false&_onOff=true&_sort=orden:ASC', 'get')
            let temporalData=data.map((item)=>{
                item.onOff=promosPlan.some(pr=>pr?.id===item.id);
                return item;
            });

            // temporalData=temporalData.sort((a,b)=>{
            //     let indexA=promosPlan.findIndex(item=>item.id===a.id);
            //     let indexB=promosPlan.findIndex(item=>item.id===b.id);
            //     return indexA-indexB;
            // })
            setContenidoPromociones(temporalData)
            isLoadingAction(false)
        } catch (error) {
            console.error('mostrando error', error)
            isLoadingAction(false)
            catchError(error);
        }
    }



    const ordenContenido = async (e) => {

        try {
            // isLoadingAction(true)

            let request = []
            e.forEach(async (item, indice) => {
                // const params = {
                //     orden: indice
                // }
                //request.push(Fetch('promociones/' + item.id, 'put', params))
            })

            // Promise.all(request).then(response => { isLoadingAction(false) })

        } catch (error) {
            // isLoadingAction(false)
            catchError(error);
        }
    }


    const handlerOnOff = async (prmId, value) => {
        //isLoadingAction(true)
        try {
            let indicePromociones=contenidoPromociones.findIndex(item=>item.id==prmId);
            contenidoPromociones[indicePromociones].onOff=value;
            setContenidoPromociones([...contenidoPromociones]);

            let indice=promosPlan.findIndex(item=>item.id==prmId);
            if(indice===-1)
            {
                let encontrado=contenidoPromociones.find(item=>item.id===prmId);
                encontrado.onOff=value;                
                setPromosPlan([...promosPlan,encontrado]);
            }else{
                if(!value)
                {
                  let nuevasPromos=promosPlan.filter(item=>item.id!=prmId);
                  setPromosPlan([...nuevasPromos]);
                }
            }
            
           
        } catch (error) {
            //isLoadingAction(false)
            catchError(error);
        }
    }

    const onReorder = async (e) => {
        const visibleRows = e.component.getVisibleRows();
        const newData = [...contenidoPromociones];
        const toIndex = newData.indexOf(visibleRows[e.toIndex].data);
        const fromIndex = newData.indexOf(e.itemData);

        newData.splice(fromIndex, 1);
        newData.splice(toIndex, 0, e.itemData);

        let seleccionados=newData.filter(item=>item.onOff);

        //console.log(seleccionados,promosPlan);
        setPromosPlan(seleccionados);    
        setContenidoPromociones(newData);
        //await ordenContenido(newData)
    }

    function FActualizacion(data) {
        return formatDate(data?.updated_at)
    }

    function OnOff(data) {
        return (
            <div class="custom-control custom-switch">
                <input type="checkbox"
                    checked={data.onOff}
                    class="custom-control-input"
                    onChange={(v) => handlerOnOff(data.id, v.target.checked)}
                    id={"csPublicado" + data.id} />
                <label role="button" class="custom-control-label" for={"csPublicado" + data.id}></label>
            </div>
        )
    }

    function Imagen(data) {
        return (
            data?.imagen?.formats?.thumbnail?.url ? <><img src={data.imagen.formats.thumbnail.url} style={{ width: '64px', height: '64px', borderRadius: '32px', objectFit: 'cover' }} className="" alt="images" /></> : null
        )
    }
           

    return (
        <React.Fragment>
            <Row className="mt-3 p-3">
                <Col md="12">
                    <Card>
                        <Card.Header>                            
                            Listado de promociones
                            {/* <div style={{width:"800px"}}>{JSON.stringify(promosPlan)}</div> */}
                        </Card.Header>
                        <Card.Body>

                            <div className='table-responsive'>
                                <DataGridForm dataSource={contenidoPromociones}
                                    keyExpr='id'
                                    allowReordering={true}
                                    onReorder={onReorder}
                                    columns={[
                                        { caption: 'Id', dataField: 'id' },
                                        { caption: 'Imagen', alignment: 'center', render: 'Imagen', cellRender: { Imagen } },
                                        { caption: 'Título', dataField: 'titulo' },                                                                                                                                                                
                                        { caption: 'Actualizado por', dataField: 'actualizado_por.username' },
                                        { caption: 'Ult. Actualización', render: 'FActualizacion', cellRender: { FActualizacion } },
                                        { caption: 'Inactivo/Activo', alignment: 'center', render: 'OnOff', cellRender: { OnOff } },                                        
                                    ]} />
                            </div>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {

    return {
        isLoading: state.loading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        isLoadingAction: (data) => { dispatch(loadingAction(data)) }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PromocionesPlanes);